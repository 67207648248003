import modalTmpl from "./modal/template.html";
import modalCtrl from "./modal/controller";
import i18n from "../i18n";

const ctrl = [
  "$scope",
  "fzSvcAttribute",
  "fzModal",
  function ($scope, fzSvcAttribute, fzModal) {
    const my = this;
    $scope.i18n = i18n;
    my.onButtonAddClickHandler = function () {
      fzModal
        .open({
          windowClass: "fz-c-addresses__modal",
          template: modalTmpl,
          controller: modalCtrl,
          controllerAs: "my",
          params: {
            item: angular.copy(($scope.model || {}).itemTemplate || {}),
            config: ($scope.model || {}).config || {},
            readonly: false,
          },
        })
        .closed.then((result) => {
          if (!result) return;
          if (!$scope.model) $scope.model = {};
          if (!$scope.model.data) $scope.model.data = [];
          $scope.model.data.push(result);
        });
    };

    my.onButtonEditClickHandler = function (item) {
      fzModal
        .open({
          windowClass: "fz-c-addresses__modal",
          template: modalTmpl,
          controller: modalCtrl,
          controllerAs: "my",
          params: {
            item: item,
            config: ($scope.model || {}).config || {},
          },
        })
        .closed.then((result) => {
          if (!result) return;

          const index = $scope.model.data.indexOf(item);
          if (index > -1) $scope.model.data[index] = result;
        });
    };

    my.onButtonDeleteClickHandler = function (item) {
      const index = $scope.model.data.indexOf(item);
      $scope.model.data.splice(index, 1);
    };

    my.getAddressTypeName = function (addressTypeRefId) {
      return i18n(addressTypeRefId) || "-";
    };

    my.showAddressOwnType = function (addressTypeRefId) {
      const config = (($scope.model || {}).config || {})[
        `${addressTypeRefId}OwnType`
      ];
      return !config || !!config.show;
    };

    my.getInvalidAddresses = () => {
      const config = ($scope.model || {}).config || {};
      const requiredAddresses = {
        legalAddress: true,
        actualAddress:
          !config.actualAddress ||
          (config.actualAddress.show && (config.actualAddress || {}).required), // required by default
        postalAddress:
          (config.postalAddress || {}).show &&
          (config.postalAddress || {}).required,
      };
      const addressTypes = ["legalAddress", "actualAddress", "postalAddress"];
      const invalidAddresses = [];

      const addrMap = {};
      (($scope.model || {}).data || []).forEach((x) => {
        const type = (x.address || {}).addressTypeRefId || "-unknown-";
        if (addrMap[type]) invalidAddresses.push(type);
        addrMap[type] = x;
      });

      if (invalidAddresses.length > 0) return invalidAddresses;

      addressTypes.forEach((type) => {
        const item = addrMap[type];
        if (!item) {
          if (requiredAddresses[type]) invalidAddresses.push(type);
        } else {
          const goodAddress =
            (!!(config["equalsLegalAddress"] || {}).show &&
              !!item.equalsLegalAddress) ||
            (!!item.address.result &&
              (!my.showAddressOwnType(type) ||
                (!!item.addressOwnTypeRefId &&
                  fzSvcAttribute.validateGroup(item))));
          if (!goodAddress) invalidAddresses.push(type);
        }
      });

      return invalidAddresses;
    };
  },
];

export default ctrl;
