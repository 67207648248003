import angular from 'angular';
import fzlCCompanyBuh from './fzlCCompanyBuh';
import fzlCCompanyBuhEdit from './fzlCCompanyBuhEditTab';
import './styles.css';

const moduleName = 'com.farzoom.lime.cCompanyBuh';

angular.module(moduleName, [
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzlCCompanyBuh', fzlCCompanyBuh)
    .directive('fzlCCompanyBuhEdit', fzlCCompanyBuhEdit)

export default moduleName;