import modalTmpl from './modal/template.html'
import modalCtrl from './modal/controller'
import { getCode, getValue, validate } from './config'

const ctrl = ['$scope', 'fzModal', 'fzSvcAttribute', function ($scope, fzModal, fzSvcAttribute) {
  const my = this

  my.state = {
    validItems: []
  }

  my.onButtonAddClickHandler = () => {
    fzModal.open({
      windowClass: 'fz-c-employee__modal',
      template: modalTmpl,
      controller: modalCtrl,
      controllerAs: 'my',
      params: {
        model: angular.copy($scope.model),
        readonly: false,
        ref: $scope?.model?.ref || {},
        personsRef: $scope.ref,
      }
    }).closed.then(result => {
      if (!result) {
        return
      }
      if (!$scope?.model?.data) {
        $scope.model.data = []
      }
      $scope.model.data.push(result)
    })
  }

  my.onButtonEditClickHandler = (item, index) => {
    const code = getCode(item)
    fzModal.open({
      windowClass: 'fz-c-employee__modal',
      template: modalTmpl,
      controller: modalCtrl,
      controllerAs: 'my',
      params: {
        header: $scope.model.data[index][code].header,
        model: angular.copy($scope.model),
        code,
        ref: $scope?.model?.ref || {},
        personsRef: $scope.ref,
        readonly: !my.isAvailableProduct(item),
        editable: my.isAvailableProduct(item),
      }
    }).closed.then(result => {
      if (!result) {
        return
      }
      $scope.model.data[index][code] = result
    })
  }

  my.onButtonDeleteClickHandler = (item) => {
    const index = $scope.model.data.indexOf(item)
    $scope.model.data.splice(index, 1)
  }

  my.getValue = (object, path = '') => getValue(object, path)

  my.getCode = (item) => getCode(item)

  my.isClientBank = (item) => my.getCode(item) === 'clientBank'
  my.isMtsBusiness = (item) => my.getCode(item) === 'mtsBusiness'
  my.isCorpCard = (item) => my.getCode(item) === 'corpCard'
  my.isPercentAccrual = (item) => my.getCode(item) === 'percentAccrual'
  my.isCardToAccount = (item) => my.getCode(item) === 'cardToAccount'
  my.isSpecialAccountProcurementParticipant = (item) => my.getCode(item) === 'specialAccountProcurementParticipant'
  my.isTaxPiggyBank = (item) => my.getCode(item) === 'taxPiggyBank'

  my.showPepText = (item) => my.isClientBank(item) && !my.getValue(item, 'useSimpleESign')

  my.showFioText = (item) =>
    !my.isPercentAccrual(item)  &&
    !my.isTaxPiggyBank(item)  &&
    !my.isSpecialAccountProcurementParticipant(item) &&
    !my.getValue(item, 'person.firstName') &&
    !my.getValue(item, 'person.lastName')

  my.showActivatedText = (item) => my.isPercentAccrual(item) && my.getValue(item, 'percentAccrual')
  my.showNotActivatedText = (item) => my.isPercentAccrual(item) && !my.getValue(item, 'percentAccrual')

  my.isAvailableProduct = (item) => $scope.model.config.find(({ type }) => type === my.getCode(item))?.available
  my.getProductConfig = (item) => $scope.model.config.find(({ type }) => type === my.getCode(item))
  my.isInvalid = (index) => !my.state.validItems[index]

  my.validate = () => {
    if ($scope.model?.data?.length === 0) {
      return false
    }
    const validateProduct = (item) => {
      const key = Object.keys(item)[0]
      const {
        email,
        phone,
        provideLogin,
        sendToEmail,
        useSimpleESign,
        person,
        personAttributes,
        codeword,
        barcode,
        percentAccrual,
        percent,
        taxPiggyBank,
        taxPiggyBankEnabled,
        specialAccountProcurementParticipant,
      } = Object.values(item)[0] || {}
      const {
        firstName,
        lastName,
        contacts = [],
      } = person || {}
      const isClientBank = key === 'clientBank'
      const isCorpCard = key === 'corpCard'
      const isPercentAccrual = key === 'percentAccrual'
      const isCardToAccount = key === 'cardToAccount'
      const isSpecialAccountProcurementParticipant = key === 'specialAccountProcurementParticipant'
      const isTaxPiggyBank = key === 'taxPiggyBank'
      const getValue = (
        items,
        attributeId,
        key = 'keyValue'
      ) => items?.find(item => item.attributeId === attributeId)?.value[key] || null

      let isCodewordCorrect = false;
      if (isCorpCard || isCardToAccount) {
        if (!codeword) {
          isCodewordCorrect = my.getProductConfig(item)?.isNotRequiredCodeword
        } else {
          isCodewordCorrect = validate.codeword(codeword)
        }
      } else {
        isCodewordCorrect = true;
      }

      let isBarcodeCorrect = false;
      if (isCorpCard || isCardToAccount) {
        if (!barcode) {
          isBarcodeCorrect = my.getProductConfig(item)?.isNotRequiredBarcode
        } else {
          isBarcodeCorrect = validate.barcode(barcode)
        }
      } else {
        isBarcodeCorrect = true;
      }

      const valids = {
        provideLogin: isClientBank
          ? provideLogin || sendToEmail
          : true,
        sendToEmail: isClientBank
          ? provideLogin || sendToEmail
          : true,
        phone: isClientBank || isCorpCard || isCardToAccount
          ? !!phone
          : true,
        email: sendToEmail
          ? !!(sendToEmail && email)
          : true,
        person: useSimpleESign || isCorpCard || isCardToAccount
          ? !!(firstName && lastName)
          : true,
        codeword: isCodewordCorrect,
        barcode: isBarcodeCorrect,
        percent: isTaxPiggyBank
          ? !taxPiggyBank || !!percent
          : true,
        taxPiggyBankEnabled: isTaxPiggyBank
          ? !!taxPiggyBankEnabled
          : true,
        specialAccountProcurementParticipant: isSpecialAccountProcurementParticipant
          ? !! specialAccountProcurementParticipant
          : true,
      }
      return !Object.values(valids)?.some(item => !item)
    }
    my.state.validItems = $scope.model?.data?.map(item => validateProduct(item))
    return !my.state.validItems?.some(item => !item)
  }
}]

export default ctrl