import angular from 'angular';
import fzlCEmployeeEditTab from './fzlCEmployeeEditTab';
import fzlCEmployeeViewTab from './fzlCEmployeeViewTab';
import fzlCFoundersEditTab from './fzlCFoundersEditTab';
import fzlCFoundersViewTab from './fzlCFoundersViewTab';
import fzlCCompanyBeneficiariesEditTab from './fzlCCompanyBeneficiariesEditTab';
import fzlCCompanyBeneficiariesViewTab from './fzlCCompanyBeneficiariesViewTab';
import fzlCKopManagersEditTab from './fzlCKopManagersEditTab';
import fzlCKopManagersViewTab from './fzlCKopManagersViewTab';
import fzlCRkoCompanyBeneficiariesEditTab from './fzlCRkoCompanyBeneficiariesEditTab';
import fzlCRkoCompanyBeneficiariesViewTab from './fzlCRkoCompanyBeneficiariesViewTab';

const moduleName = 'com.farzoom.lime.cPerson';

angular.module(moduleName, [
        'ui.mask',
        'com.farzoom.common.cAttributes',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzSelectAddress',
        'com.farzoom.common.fzTaskFormParts',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzlCEmployeeEditTab', fzlCEmployeeEditTab)
    .directive('fzlCEmployeeViewTab', fzlCEmployeeViewTab)
    .directive('fzlCFoundersEditTab', fzlCFoundersEditTab)
    .directive('fzlCFoundersViewTab', fzlCFoundersViewTab)
    .directive('fzlCKopManagersEditTab', fzlCKopManagersEditTab)
    .directive('fzlCKopManagersViewTab', fzlCKopManagersViewTab)
    .directive('fzlCCompanyBeneficiariesEditTab', fzlCCompanyBeneficiariesEditTab)
    .directive('fzlCCompanyBeneficiariesViewTab', fzlCCompanyBeneficiariesViewTab)
    .directive('fzlCRkoCompanyBeneficiariesEditTab', fzlCRkoCompanyBeneficiariesEditTab)
    .directive('fzlCRkoCompanyBeneficiariesViewTab', fzlCRkoCompanyBeneficiariesViewTab)

export default moduleName;
