
const ctrl = ['$scope',  function ($scope ) {
  const my = this

  my.state = {
    newCity: '',
    oldFilial: '',
    resetCount: 0,
    prefilledCount: 0,
  }

  my.validate = () => {
    if (!$scope.model) {
      return false
    }
    const d = $scope.model.data
    const cfg = $scope.model.config
    const result = 
      (!cfg.filial.show || !cfg.filial.required || !!d.filial) &&
      (!cfg.absCompany.show || !cfg.absCompany.required || !!d.absCompany) &&
      (!cfg.meetDate.show || !cfg.meetDate.required || !!d.meetDate) &&
      (!cfg.meetTime.show || !cfg.meetTime.required || !!d.meetTime) &&
      (!cfg.meetIntervalTime.show || !cfg.meetIntervalTime.required || !!d.meetIntervalTime) &&
      (!cfg.meetPlace.show || !cfg.meetPlace.required || !!d.meetPlace) &&
      (!cfg.accCurrency.show || !cfg.accCurrency.required || !!d.accCurrency) &&
      (!cfg.accNumber.show || !cfg.accNumber.required || !!d.accNumber) &&
      // (!cfg.accNumber.show || !cfg.accNumber.required || !my.checkAcc(d.accNumber, d.accCurrency)) &&
      (!cfg.tariff.show || !cfg.tariff.required || !!d.tariff)
      
    return result
  }

  my.checkAcc = (acc, cur) => {
    // длина 20 символов проверяется маской. Проверим валюту
    if (!acc || !cur) {
      return true
    }
    let val = acc.substring(5,8)
    const refItem = $scope.model.ref.currency.find(x => x.code === cur)
    return refItem.num === val
  }

  my.accCode = (cur) => {
    if (!cur) {
      return ' '
    }
    const refItem = $scope.model.ref.currency.find(x => x.code === cur)
    return refItem.num
  }

  my.checkTime = (sTime) => {
    if (!sTime) {
      return true
    }
    let hr = parseInt(sTime.substring(0,2))
    let mi = parseInt(sTime.substring(3,5))
    return hr >= 0 && hr < 24 && mi >= 0 && mi < 60
  }

//  my.checkDate = function(sDate) {
//    if (!$scope.ngRequiredAdditional || !sDate) {
//      return true
//    } else {
//      const now = new Date()
//      let y = now.getFullYear()
//      let m = now.getMonth() + 1
//      let d = now.getDate()
//      let today = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}`
//      return sDate >= today
//    }
//  }

  my.checkDate = function(sDate) {
    if (!$scope.ngRequiredAdditional || !sDate) {
      return true
    } else {
      let readonly = $scope.model.config.meetDate.readonly
      const now = new Date()
      let y = now.getFullYear()
      let m = now.getMonth() + 1
      let d = now.getDate()
      let today = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}`
      return (sDate >= today) || readonly
    }
  }

  my.isFilialByCourier = () => {
    const filial = my.state.oldFilial || $scope?.model?.data?.filial
    return filial?.startsWith('7')
  }

  my.isFilialByOffice = () => {
    const filial = my.state.oldFilial || $scope?.model?.data?.filial
    return filial?.startsWith('0')
  }

  my.getFilialsByCity = () => {
    const getFilials = (city) => $scope?.model?.filialRef?.find(item => item?.city === city)?.officeFilials
    const filialsByDefaultCity = getFilials($scope?.model?.data?.city)
    const filialsByPrefilledCity = getFilials(my.state.newCity)
    if (filialsByDefaultCity?.length > 0) {
      return filialsByDefaultCity
    }
    if (filialsByPrefilledCity?.length > 0) {
      return filialsByPrefilledCity
    }
    return []
  }

  my.onChangeMeetingBank = () => {
    $scope.model.data.expressDelivery = ''
  }

  my.onPrefilled = () => {
    const { data, filialTypes, filialRef, ref } = $scope?.model || {}
    if (my.isFilialByCourier() || my.isFilialByOffice()) {
      if (my.state.prefilledCount === 0) {
        const oldData = angular.copy(data)
        if (!my.state.oldFilial) {
          my.state.oldFilial = oldData?.filial
        }
        const { ibsoCode, filialType } = filialTypes?.find(item => item?.id === data?.filial) || {}
        const { city, officeFilials } = filialRef?.find(item => item?.officeFilials?.find(f => f?.ibsoCode === ibsoCode)) || {}
        const filial = officeFilials?.find(item => item?.ibsoCode === ibsoCode)
        const { code } = ref?.expressDelivery?.find(item => item?.code === 'e_fin') || {}
        // console.log('code:', code)
        if (city) {
          my.state.newCity = city
        }
        if ($scope?.model?.data && filial?.ibsoCode) {
          // console.log('my.state.oldFilial:', my.state.oldFilial)
          // console.log('filial?.ibsoCode:', filial?.ibsoCode)
          $scope.model.data.serviceFilial = filial.ibsoCode
        }
        if (my.isFilialByCourier()) {
          if ($scope?.model?.data && filialType) {
            // console.log('filialTypes:', filialTypes)
            // console.log('data?.filial:', data?.filial)
            // console.log('filialType:', filialType)
            // console.log('$scope.model.data.meetingBank:', $scope.model.data.meetingBank)
            if (!$scope.model.data.meetingBank) {
              $scope.model.data.meetingBank = filialType
            }
          }
          if (
            $scope?.model?.data &&
            code &&
            $scope.model.data.meetingBank === 'courier' &&
            !$scope.model.data.expressDelivery
          ) {
            $scope.model.data.expressDelivery = code
          }
        }
        if (my.isFilialByOffice()) {
          if ($scope?.model?.data && filialType) {
            // console.log('filialTypes:', filialTypes)
            // console.log('data?.filial:', data?.filial)
            // console.log('filialType:', filialType)
            // console.log('$scope.model.data.meetingBank:', $scope.model.data.meetingBank)
            if (!$scope.model.data.meetingBank) {
              $scope.model.data.meetingBank = filialType
            }
          }
        }
      }
      my.state.prefilledCount = my.state.prefilledCount + 1
    }
  }

  my.onResetFilial = (newValue, oldValue) => {
    if (!!$scope?.model?.data && !!oldValue && !!newValue && newValue !== oldValue) {
      $scope.model.data.filial = ''
      $scope.model.data.serviceFilial = ''
      if (my.state.resetCount === 0) {
        $scope.model.data.meetingBank = ''
        $scope.model.data.expressDelivery = ''
      }
      my.state.resetCount = my.state.resetCount + 1
    }
  }

  my.onSetFilialByServiceFilial = () => {
    const { data, filialRef } = $scope?.model || {}
    const { courierFilial } = filialRef?.find(item => item?.city === data?.city) || {}
    $scope.model.data.filial = courierFilial?.ibsoCode || $scope?.model?.data?.serviceFilial
  }

  $scope.$watch('model.data.city', my.onResetFilial)
  $scope.$watch('model.data.filial', my.onPrefilled)
}]

export default ctrl
