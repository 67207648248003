import modalTmpl from '../fzlCEmployeeEditTab/modal/template.html';
import modalCtrl from '../fzlCEmployeeEditTab/modal/controller';

const ctrl = ['$scope', 'fzModal', function ($scope, fzModal) {
    const my = this;

    my.onButtonClickHandler = function () {
        fzModal.open({
            windowClass: 'fz-c-employee__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                model: $scope.model || {},
                ref: $scope.model.ref,
                personsRef: $scope.ref,
                readonly: true
            }
        });
    };
}];

export default ctrl;