import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

const ctrl = ['$scope', 'fzModal', '$element', function ($scope, fzModal, $element) {
    const my = this;
    my.model = null;
    my.latestPeriod = null;
    my.ps = [];
    my.periodsConfig = {};
    my.ref = {
        periods: [],
        taxationType: [
            { value: 'osn', name: 'ОСН' },
            { value: 'usn', name: 'УСН' }
        ],
        taxationAccountingType: [
            { value: 'f1f2', name: 'Баланс' },
            { value: 'kudir', name: 'КУДиР' }
        ],
        taxationUSNType: [
            { value: 'usn6', name: 'доходы (6%)' },
            { value: 'usn15', name: 'доходы - расходы (15%)' }
        ]
    };
    my.onButtonClickHandler = function () {
        fzModal.open({
            windowClass: 'fz-c-company-buh__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                item: $scope.model || {},
                taskId: getTaskId()
            }
        }).closed.then(result => {
            if (!result) return;
            $scope.model = result;
        });
    };

    my.isCompanyBuhFilled = function (companyBuh) {
        var d = new Date();
        var finReportNecessityDate = new Date(d.getFullYear() + "-04-01");
        if (d < finReportNecessityDate) {
            return true;
        }
        if (!companyBuh || !companyBuh.periods || !companyBuh.latestPeriod) return false;

        const p = companyBuh.periods[companyBuh.latestPeriod];
        if (!p) return false;

        if (companyBuh.taxationTypeRefId === 'usn' && companyBuh.taxationAccountingTypeRefId === 'kudir') { // УСН - КУДиР
            return true;
        } else { // ОСН, УСН - баланс
            const b1700 = p['b1700'];
            if (!b1700 || b1700.endValue <= 0) return false;

            return true;
        }
    };

    function getTaskId() {
        let s1 = $element.closest('form') ;
        let s2 = s1.scope();
        let s3 = s2.camForm;
        let s4=s3.taskId;

        return s4;
        //    return $element.closest('form').scope().camForm.taskId; // HACK to get parent Form`s scope
    };
}];

export default ctrl;
