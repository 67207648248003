import  { isEquals } from '../../../../utils'
import './styles.css'

export default ['$scope', '$modalInstanse', '$modalParams', function ($scope, $modalInstanse, $modalParams) {
  const my = this;

  my.closeOk = function () {
    $modalInstanse.close(my.data);
  };

  my.closeCancel = function () {
    $modalInstanse.close();
  };

  my.removeContact = function (item) {
    const index = my.data.contacts.indexOf(item);
    if (index > -1) my.data.contacts.splice(index, 1);
  };

  my.addContact = function () {
    my.data.contacts.push({});
  };

  my.removeOtherDocument = function (item) {
    const index = my.data.otherDocuments.indexOf(item);
    if (index > -1) my.data.otherDocuments.splice(index, 1);
  };

  my.addOtherDocument = function () {
    my.data.otherDocuments = my.data.otherDocuments || [];
    my.data.otherDocuments.push({});
  };

  my.isOtherIdentityDocumentType = function (item) {
    return item?.docType === 'PersDoc_Non-residentIdentityCard_2';
  }

  my.onSelect = function (item) {
    if (item) {
      const refData = JSON.parse(JSON.stringify(item.data));
      for (let key of Object.keys(refData)) {
        if (key !== 'personAttributes' && key !== 'relationAttributes' && key !== 'relationTopAttributes') {
          my.data[key] = refData[key];
        }
      }

      fillAttributes(my.data.personAttributes, refData.personAttributes);
      fillAttributes(my.data.relationAttributes, refData.relationAttributes);
      fillAttributes(my.data.relationTopAttributes, refData.relationTopAttributes);

      if (my?.data?.otherDocuments) {
        my.data.otherDocuments = (my.data.otherDocuments || []).map(item => {
          if (item.docType === 'PersDoc_Non-residentIdentityCard_2') {
            item.docName = item.docName || getOtherDocumentName(refData.personAttributes.items)
          }
          return item
        })
      }
      
    }
  };

  function fillAttributes(destAttrs, srcAttrs) {
    if (destAttrs && destAttrs.items && destAttrs.items.length && srcAttrs && srcAttrs.items && srcAttrs.items.length) {
      const destMap = new Map();
      destAttrs.items.forEach(attr => destMap.set(attr.attributeId, attr));
      srcAttrs.items.forEach(attr => {
        if (destMap.has(attr.attributeId)) {
          const destAttr = destMap.get(attr.attributeId);
          destAttr.value = attr.value;
          destAttr.values = attr.values;
        }
      });
    }
  };

  my.checkINN12 = () =>
    my?.readonly ||
    !my?.config?.inn?.show ||
    !my?.data?.inn ||
    my?.data?.inn?.length == 12

  my.checkCurrDate = function(checkDate) {
    if (!checkDate) return true; // не проверяем пустую дату
    const now = new Date();
    let y = now.getFullYear();
    let m = now.getMonth() + 1;
    let d = now.getDate();
    let today = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}` ;
    return checkDate <= today;
  }

  my.checkMinAge = function () {
    const minAge = ((my.config || {}).age || {}).minimalAge || 0;
    if (my.isRF()) return true;
    if (minAge <= 0) return true;
    if (!my.data.birthDate) return true; // не проверяем пустую дату
    const now = new Date();
    const today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
    const bDate = new Date(my.data.birthDate + 'T00:00:00Z');
    const age = new Date((today - bDate)).getFullYear() - 1970;

    return age < 0 || age >= minAge; //   age<0 проверит checkCurrDate
  }

  my.data = angular.copy($modalParams.data);
  my.config = $modalParams.config;
  my.readonly = $modalParams.readonly;
  my.personsRef = $modalParams.personsRef;
  my.ref = angular.copy($modalParams.ref);
  my.header = $modalParams.header;
  my.group = $modalParams.group;
  my.needShare = $modalParams.needShare;


  if (my.config && ((my.config.phone && my.config.phone.show) || (my.config.email && my.config.email.show))) {
    if (!my.data) my.data = {};
    if (!my.data.contacts) my.data.contacts = [];
    if (!my.data.contacts.length) my.data.contacts.push({});
  }

  my.equalsLegalAddress = isEquals(my.data.registrationAddress, my.data.actualAddress)

  $scope.$watch('my.data.registrationAddress', registrationAddress =>
    my.equalsLegalAddress = isEquals(registrationAddress, my.data.actualAddress)
  )

  $scope.$watch('my.data.actualAddress', actualAddress =>
    my.equalsLegalAddress = isEquals(actualAddress, my.data.registrationAddress)
  )

  my.onEqualsLegalAddressChange = () => {
    if (my.equalsLegalAddress) {
      my.data.actualAddress = angular.copy(my.data.registrationAddress);
    }
  }

  my.validateSharedPercent = function () {
    return my.readonly ||
      !((my.config || {}).sharePercent || {}).show ||
      !my.data.sharePercent ||
      (my.data.sharePercent >= 0 && my.data.sharePercent <= 100);
  }

  my.checkLen = (string, length) => string
    ? string?.length <= length
    : true

  $scope.$watch('my.data.secondName', secondName => my.noSecondName = secondName === '-');

  const getAge = (date) => {
    const today = new Date()
    const birthDate = new Date(date)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }

  my.validIssuedDate = (thrownDate) => {
    if (!thrownDate && my.config.citizenship.show) {
      return false
    }
    if (!my.isRFAndPassportRF()) {
      return true
    }

    const getTimeStamp = (date) => new Date(date).getTime()
    const resetTime = (date) => {
      let result = new Date(date)
      result.setHours(0)
      result.setMinutes(0)
      result.setSeconds(0)
      result.setMilliseconds(0)
      return result
    }
    const getDiffDays = (date1, date2) => date1 && date2
      ? (new Date(date1).getTime() - new Date(date2).getTime()) / (1000 * 3600 * 24)
      : 0
    const birthDate = resetTime(new Date(my?.data?.birthDate || my?.data?.person?.birthDate))
    const issuedDate = resetTime(new Date(my?.data?.issuedDate || my?.data?.person?.issuedDate))
    const birthFullYear = new Date(birthDate).getFullYear()
    const age = getAge(birthDate)

    const getBirthDateByGivenAge = (givenAge) => new Date(new Date(birthDate).setFullYear(birthFullYear + givenAge))
    const birthDate14 = getBirthDateByGivenAge(14)
    const birthDate20 = getBirthDateByGivenAge(20)
    const birthDate45 = getBirthDateByGivenAge(45)

    const getDaysAfterGivenAge = (givenAge) => getDiffDays(new Date(), getBirthDateByGivenAge(givenAge))
    const daysAfter20 = getDaysAfterGivenAge(20)
    const daysAfter45 = getDaysAfterGivenAge(45)

    const issuedDateTS = getTimeStamp(issuedDate)
    const birthDateTS14 = getTimeStamp(birthDate14)
    const birthDateTS20 = getTimeStamp(birthDate20)
    const birthDateTS45 = getTimeStamp(birthDate45)

    if (age >= 14 && age < 20) { return issuedDateTS >= birthDateTS14 }
    if (age === 20 && daysAfter20 <= 90) { return issuedDateTS >= birthDateTS14 }
    if (age === 20 && daysAfter20 > 90) { return issuedDateTS >= birthDateTS20 }
    if (age > 20 && age < 45) { return issuedDateTS >= birthDateTS20 }
    if (age === 45 && daysAfter45 <= 90) { return issuedDateTS >= birthDateTS20 }
    if (age === 45 && daysAfter45 > 90) { return issuedDateTS >= birthDateTS45 }
    if (age > 45) { return issuedDateTS >= birthDateTS45 }

    return false
  }

  my.childAge = 14

  my.isChild = () => my.childAge > getAge(my?.data?.birthDate || my?.data?.person?.birthDate)

  const getValue = (items, attributeId, key = 'keyValue') => {
    return items.find(item => item.attributeId === attributeId)?.value[key] || null
  }

  const setValue = (attributeId, key = 'keyValue', value) => {
    const items = my?.data?.personAttributes?.items || my?.data?.person?.personAttributes?.items || []
    const item = items.find(item => item.attributeId === attributeId)
    item.value[key] = value
  }
  const resetValue = (attributeId, keyValue) => setValue(attributeId, keyValue, '')

  const getOtherDocumentName = (items) => {
    const _items = items || my?.data?.personAttributes?.items || my?.data?.person?.personAttributes?.items || []
    return getValue(_items, 'person.otherDocName', 'stringValue')
  }

  if (my?.data?.otherDocuments) {
    my.data.otherDocuments = (my.data.otherDocuments || []).map(item => {
      if (item.docType === 'PersDoc_Non-residentIdentityCard_2') {
        item.docName = item.docName || getOtherDocumentName()
      }
      return item
    })
  }

  if (my?.data?.person?.otherDocuments) {
    my.data.person.otherDocuments = (my.data.person.otherDocuments || []).map(item => {
      if (item.docType === 'PersDoc_Non-residentIdentityCard_2') {
        item.docName = item.docName || getOtherDocumentName()
      }
      return item
    })
  }

  // гражданство
  my.getPersonCitizenship = () => { 
    const items = my?.data?.personAttributes?.items || my?.data?.person?.personAttributes?.items || []
    if (getValue(items, 'person.citizenship') === null) {
      setValue('person.citizenship', 'keyValue', 'rf');
    }
    return getValue(items, 'person.citizenship')
  }

  // ДУЛ - Документ, удостоверяющий личность

  // ДУЛ, если выбрано "гражданство РФ"
  my.getPersonIdentityDocRf = () => {
    const items = my?.data?.personAttributes?.items || my?.data?.person?.personAttributes?.items || []
    if (getValue(items, 'person.identityDocRf') === null && getValue(items, 'person.citizenship') === 'rf') {
      setValue('person.identityDocRf', 'keyValue', 'PersDoc_Passport');
    }
    return getValue(items, 'person.identityDocRf')
  }

  // ДУЛ, если выбрано "иное гражданство"
  my.getPersonIdentityDocOther = () => {
    const items = my?.data?.personAttributes?.items || my?.data?.person?.personAttributes?.items || []
    return getValue(items, 'person.identityDocOther')
  }

  // ДУЛ, если выбрано "без гражданства"
  my.getPersonIdentityDocNo = () => {
    const items = my?.data?.personAttributes?.items || my?.data?.person?.personAttributes?.items || []
    return getValue(items, 'person.identityDocNo')
  }

  my.isRF = () => my.getPersonCitizenship() === 'rf' // гражданство РФ
  my.isOther = () => my.getPersonCitizenship() === 'other' // иное гражданство
  my.isNoCitizenship = () => my.getPersonCitizenship() === 'no-citizenship' // без гражданства

  my.isRFAndPassportRF = () =>
    my.isRF() &&
    my.getPersonIdentityDocRf() === 'PersDoc_Passport'

  my.replaceAllWhiteSpaces = (string) => string?.trim()?.replaceAll(' ', '')

  my.onChangeSeries = (e) => {
    if (!my.isRFAndPassportRF()) {
      const newValue = my.replaceAllWhiteSpaces(e?.value)
      e.value = newValue
      my.data.series = newValue
    }
  }

  my.onChangeNumber = (e) => {
    if (!my.isRFAndPassportRF()) {
      const newValue = my.replaceAllWhiteSpaces(e?.value)
      e.value = newValue
      my.data.number = newValue
    }
  }

  const onChangeAttributes = () => {
    if (my?.data?.personAttributes) {
      my.data.personAttributes.items = my.data.personAttributes.items.reduce((result, item) => {
        
        // гражданство РФ
        if (my.isRF() && item.attributeId === 'person.identityDocRf') {
          item.ref = item.ref.map(item => {
            if (item.value === 'PersDoc_BirthCertificate') {
              item.isInactive = !my.isChild()
            }
            if (item.value !== 'PersDoc_BirthCertificate') {
              item.isInactive = my.isChild()
            }
            if (item.value === my.getPersonIdentityDocRf() && item.isInactive) {
              resetValue('person.identityDocRf')
            }
            return item
          })
        }
        result.push(item)
        return result
      }, [])
    }

    if (!my?.data?.otherDocuments) {
      if (!my.data) {
        my.data = {}
      }
      my.data.otherDocuments = []
    }

    const otherDocumentWithDocType = (my?.data?.otherDocuments?.length > 0
      ? my?.data?.otherDocuments
      : my?.data?.person?.otherDocuments?.length > 0
        ? my?.data?.person?.otherDocuments
        : []
    ).find(({ docType }) => docType === 'PersDoc_Non-residentIdentityCard_2')

    const otherDocumentAttr = (my?.data?.personAttributes?.items?.length > 0
      ? my?.data?.personAttributes?.items
      : my?.data?.person?.personAttributes?.items?.length > 0
        ? my?.data?.person?.personAttributes?.items
        : []
    ).find(({ attributeId }) => attributeId === 'person.otherDocName')

    if (otherDocumentWithDocType && !otherDocumentAttr) {
      if (my?.data?.personAttributes?.items?.length > 0) {
        my.data.personAttributes.items.push({
          attributeId: 'person.otherDocName',
          name: 'Название документа',
          type: 'string',
          requiredExpr: "false",
          showExpr: "false",
          value: { stringValue: otherDocumentWithDocType.docName },
        })
      }
      if (my?.data?.person?.personAttributes?.items?.length > 0) {
        my.data.person.personAttributes.items.push({
          attributeId: 'person.otherDocName',
          name: 'Название документа',
          type: 'string',
          requiredExpr: "false",
          showExpr: "false",
          value: { stringValue: otherDocumentWithDocType.docName },
        })
      }
    }

    if (otherDocumentWithDocType && otherDocumentAttr) {
      if (my?.data?.personAttributes?.items?.length > 0) {
        my.data.personAttributes.items = my.data.personAttributes.items.map(item => {
          if (item.attributeId === 'person.otherDocName') {
            item.value.stringValue = otherDocumentWithDocType.docName
          }
          return item
        })
      }
      if (my?.data?.person?.personAttributes?.items?.length > 0) {
        my.data.person.personAttributes.items = my.data.person.personAttributes.items.map(item => {
          if (item.attributeId === 'person.otherDocName') {
            item.value.stringValue = otherDocumentWithDocType.docName
          }
          return item
        })
      }
    }

    if (!otherDocumentWithDocType && otherDocumentAttr) {
      if (my?.data?.personAttributes?.items?.length > 0) {
        my.data.personAttributes.items = my.data.personAttributes.items.filter(
          ({ attributeId }) => attributeId !== 'person.otherDocName'
        )
      }
      if (my?.data?.person?.personAttributes?.items?.length > 0) {
        my.data.person.personAttributes.items = my.data.person.personAttributes.items.filter(
          ({ attributeId }) => attributeId !== 'person.otherDocName'
        )
      }
    }

    if (my?.ref?.docType) {
      my.ref.docType = my.ref.docType.map(item => ({
        ...item,
        isInactive:
          (item.code === 'PersDoc_Non-residentIdentityCard_2' && !!otherDocumentWithDocType) ||
          (item.code === 'PersDoc_RussiaResidencePermit' && my.getPersonIdentityDocNo() === 'PersDoc_StatelessPersonResidencePermit_2') ||
          (item.code === 'PersDoc_StatelessPersonTemporaryResidencePermi' && my.getPersonIdentityDocNo() === 'PersDoc_StatelessPersonTemporaryResidencePermi'),
      }))
    }


    // console.log('my.data', my.data)
    // console.log('my.data.personAttributes.items', my.data.personAttributes.items)
  }

  // иное гражданство
  const otherArray = [
    'PersDoc_Non-residentIdentityCard_2', // Иной документ, подтверждающего личность ИГ и ЛБГ и право на проживание
    'PersDoc_StatelessPersonTemporaryResidencePermi', // Разреш. на врем. прожив. лица без гр. на т. РФ
    'PersDoc_RussiaResidencePermit', // вид на жительство в РФ
  ]

  // без гражданства
  const isNoArray = [
    'PersDoc_Non-residentIdentityCard_2', // Иной документ, подтверждающего личность ИГ и ЛБГ и право на проживание
    'PersDoc_StatelessPersonTemporaryResidencePermi', // Разреш. на врем. прожив. лица без гр. на т. РФ
  ]

  my.requiredOtherDocsForOther = (my?.ref?.docType || []).filter(({ code }) => otherArray.indexOf(code) > -1)
  my.requiredOtherDocsForIsNo = (my?.ref?.docType || []).filter(({ code }) => isNoArray.indexOf(code) > -1)

  my.validOtherDocuments = () => {
    if (!my.data.otherDocuments) {
      my.data.otherDocuments = []
    }

    const otherDocuments = my?.data?.otherDocuments?.length > 0
      ? my.data.otherDocuments
      : my?.data?.person?.otherDocuments?.length > 0
        ? my.data.person.otherDocuments
        : []

    const personIdentityDocOther = my.getPersonIdentityDocOther()
    const personIdentityDocNo = my.getPersonIdentityDocNo()

    const mainIdentityDocuments = {
      // иное гражданство
      isOther: [
        'PersDoc_ForeignCitizenPassport', // Паспорт иностранного гражданина
      ],

      // без гражданства
      isNoCitizenship: [
        'PersDoc_StatelessIdentityCard', // Удостоверение лица без гражданства
        'PersDoc_RefugeeConsiderationPetitionCertificate', // свидетельство о рассмотрении ходатайства о признании беженцем на территории РФ по существу
        'PersDoc_RefugeeIdentityCard', // удостоверение беженца РФ
        'PersDoc_Pre-CitizenshipTempDoc', // Временный документ до признания лица гражданином РФ или о приеме в гражданство РФ
      ],
    }
    
    // иное гражданство + паспорт иностранного гражданина
    if (my.isOther() && personIdentityDocOther && mainIdentityDocuments.isOther.indexOf(personIdentityDocOther) > -1) {
      return !!otherDocuments.find(({ docType }) =>
        my.requiredOtherDocsForOther.filter(({ code }) => docType && docType === code).length > 0
      )
    }
    
    // без гражданства + (
    //   Удостоверение лица без гражданства ИЛИ
    //   свидетельство о рассмотрении ходатайства о признании беженцем на территории РФ по существу ИЛИ
    //   удостоверение беженца РФ ИЛИ
    //   Временный документ до признания лица гражданином РФ или о приеме в гражданство РФ
    // )
    if (my.isNoCitizenship() && personIdentityDocNo && mainIdentityDocuments.isNoCitizenship.indexOf(personIdentityDocNo) > -1) {
      return !!otherDocuments.find(({ docType }) =>
        my.requiredOtherDocsForIsNo.filter(({ code }) => docType && docType === code).length > 0
      )
    }

    return true
  }

  $scope.$watch('my.data.birthDate', onChangeAttributes);
  $scope.$watch('my.data.person.birthDate', onChangeAttributes);

  $scope.$watch('my.data.personAttributes.items[0].value.keyValue', onChangeAttributes);
  $scope.$watch('my.data.personAttributes.items[1].value.keyValue', onChangeAttributes);
  $scope.$watch('my.data.personAttributes.items[2].value.keyValue', onChangeAttributes);
  $scope.$watch('my.data.personAttributes.items[3].value.keyValue', onChangeAttributes);
  $scope.$watch('my.data.personAttributes.items[4].value.keyValue', onChangeAttributes);
  $scope.$watch('my.data.personAttributes.items[5].value.keyValue', onChangeAttributes);

  $scope.$watch('my.data.person.personAttributes.items[0].value.keyValue', onChangeAttributes);
  $scope.$watch('my.data.person.personAttributes.items[1].value.keyValue', onChangeAttributes);
  $scope.$watch('my.data.person.personAttributes.items[2].value.keyValue', onChangeAttributes);
  $scope.$watch('my.data.person.personAttributes.items[3].value.keyValue', onChangeAttributes);
  $scope.$watch('my.data.person.personAttributes.items[4].value.keyValue', onChangeAttributes);
  $scope.$watch('my.data.person.personAttributes.items[5].value.keyValue', onChangeAttributes);

  $scope.$watch('my.data.otherDocuments[0].docName', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[0].docType', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[1].docName', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[1].docType', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[2].docName', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[2].docType', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[3].docName', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[3].docType', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[4].docName', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[4].docType', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[5].docName', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[5].docType', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[6].docName', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[6].docType', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[7].docName', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[7].docType', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[8].docName', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[8].docType', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[9].docName', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[9].docType', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[10].docName', onChangeAttributes);
  $scope.$watch('my.data.otherDocuments[10].docType', onChangeAttributes);

  $scope.$watch('my.data.person.otherDocuments[0].docName', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[0].docType', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[1].docName', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[1].docType', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[2].docName', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[2].docType', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[3].docName', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[3].docType', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[4].docName', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[4].docType', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[5].docName', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[5].docType', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[6].docName', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[6].docType', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[7].docName', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[7].docType', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[8].docName', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[8].docType', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[9].docName', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[9].docType', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[10].docName', onChangeAttributes);
  $scope.$watch('my.data.person.otherDocuments[10].docType', onChangeAttributes);
}];