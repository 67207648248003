import angular from 'angular';
import component from './fzlCProductBgEdit';

const moduleName = 'com.farzoom.lime.fzlCProductBgEdit';

angular.module(moduleName, [
        'com.farzoom.common.fzAddress',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzlCProductBgEdit', component)

export default moduleName;
