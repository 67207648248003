import './styles.css';

export default ['$scope', '$modalInstanse', '$modalParams', function ($scope, $modalInstanse, $modalParams) {
    const my = this;
    my.data = angular.copy($modalParams.item) || {};

    my.closeOk = function () {
        my.saveData();
        $modalInstanse.close(my.data);
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    my.saveData = function() {
        if (!my.data.isRequiredSecurityForGuaranteePeriod) {
            my.data.securityForGuaranteePeriodDays = null;
        };

        if (!my.data.prepaymentExists) {
            my.data.prepaymentAmount = null;
        };
    };
}];