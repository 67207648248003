import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

const ctrl = ['$scope', 'fzSvcAttribute', 'fzModal', function ($scope, fzSvcAttribute, fzModal) {
    const my = this;

    my.attrHelper = null;

    my.onButtonClickHandler = function () {
        fzModal.open({
            windowClass: 'fz-c-attributes__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            scope: {
                modalTitle: $scope.model.attributes ? $scope.model.attributes.header : $scope.model.header || $scope.header
            },
            params: {
                model: $scope.model
            }
        }).closed.then(result => {
            if (!result) {
                validateModel(); // Validate even if cancelled
                return;
            }
            $scope.model = result;
        });
    };

    let isValid = true;

    function validateModel() {
        isValid = validateGroup($scope.model);
    }

    $scope.$watch('model', validateModel);

    my.validate = function () {
        return isValid;
    };

    function validateGroup(model) {
        if (!model) return true;

        const group = model.attributes || model;
        if (!group || !group.items || !group.items.length) return true;
        return group.items.map(attr => validateAttribute(attr)).every(x => x);
    }

    function validateAttribute(attr) {
        if (!attr.multiple) {
            return validateAttrValue(attr.type, attr.value);
        } else {
            if (!attr.values || !attr.values.length) return false;
            const isValid = attr.values.map(v => validateAttrValue(attr.type, v)).every(x => x);
            return isValid;
        }
    }

    function validateAttrValue(attrType, attrValue) {
        if (!attrValue) return false;
        var expression=/[0-9]{20}/;
        return expression.test(attrValue.stringValue)
    }
}];

export default ctrl;