import angular from 'angular';
import cShareholdersEditTab from './cShareholdersEditTab';
import cShareholdersViewTab from './cShareholdersViewTab';
import fzSvcShareholder from './fzSvcShareholder';

const moduleName = 'com.farzoom.lime.cShareholder';

angular.module(moduleName, [
        'ui.mask',
        'com.farzoom.common.cAttributes',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzSelectAddress',
        'com.farzoom.common.fzTaskFormParts',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzlCShareholdersEditTab', cShareholdersEditTab)
    .directive('fzlCShareholdersViewTab', cShareholdersViewTab)
    .service('fzSvcShareholder', fzSvcShareholder)

export default moduleName;