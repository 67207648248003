import './styles.css';

export default ['$scope', '$http', '$q', 'fzFilesConfig', '$element', '$modalInstanse', '$modalParams', function ($scope, $http, $q, fzFilesConfig, $element, $modalInstanse, $modalParams) {
    const my = this;

    my.closeOk = function () {
        const model = getModel();
        $modalInstanse.close(model);
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    function getModel() {
        const model = angular.copy(my.model);
        if (model) {
            model.latestPeriod = my.model.latestPeriod;

            if (model.periods) {
                // Fill totals
                let totals = [];
                if (my.isOsn() || my.isUsnBalance()) totals = ['b1100', 'b1200', 'b1600', 'b1300', 'b1400', 'b1500', 'b1700', 'b2100', 'b2200', 'b2300', 'b2400'];
                else if (my.isUsn6Kudir() || my.isUsn15Kudir()) totals = ['b2400'];

                for (let dateMonth of Object.keys(model.periods)) {
                    // Remove all unnecessary rows
                    if (my.isUsn6Kudir()) {
                        model.periods[dateMonth] = {
                            'b2110': model.periods[dateMonth].b2110
                        };
                    } else if (my.isUsn15Kudir()) {
                        model.periods[dateMonth] = {
                            'b2110': model.periods[dateMonth].b2110,
                            'b2210': model.periods[dateMonth].b2210
                        };
                    }

                    const period = model.periods[dateMonth];
                    for (let code of totals) {
                        period[code] = my.total[code](period) || undefined;
                    }
                }
            }
        }
        return model;
    };

    my.ref = {
        periods: [],
        taxationType: [{
            value: 'osn',
            name: 'ОСН'
        },
            {
                value: 'usn',
                name: 'УСН'
            }
        ],
        taxationAccountingType: [{
            value: 'f1f2',
            name: 'Баланс'
        },
            {
                value: 'kudir',
                name: 'КУДиР'
            }
        ],
        taxationUSNType: [{
            value: 'usn6',
            name: 'доходы (6%)'
        },
            {
                value: 'usn15',
                name: 'доходы - расходы (15%)'
            }
        ]
    };
    my.ps = [];
    my.periodsConfig = {};

    function getPeriodName(dateMonth) {
        const ps = dateMonth.split('-');
        const year = ps[0];
        const month = ps[1];

        switch (month) {
            case '03':
                return `I кв ${year}`;
            case '06':
                return `II кв ${year}`;
            case '09':
                return `III кв ${year}`;
            case '12':
                return `IV кв ${year}`;
        }

        throw new Error(`Unexpected period: ${dateMonth}`);
    }

    function initPeriods() {
        if (!my.model || !my.model.config || !my.model.config.periods) return;

        my.ps = [];
        let annualPeriods = getAnnualPeriods();
        for (let period of annualPeriods) {
            if (!period) {
                my.ps.push(null);
            } else {
                my.ps.push({
                    dateMonth: period,
                    name: getPeriodName(period)
                });
            }
        }

        my.model.latestPeriod = my.ps[0].dateMonth;
    }

    function getAnnualPeriods() {
        const latestReportMonth = 5;
        const latestReportDay = 15;

        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth() + 1;
        const currentDay = now.getDate;

        let yearsNumber = 0;
        if (currentMonth < latestReportMonth || currentMonth === latestReportMonth && currentDay <= latestReportDay) {
            yearsNumber = 3;
        } else {
            yearsNumber = 2;
        }

        const result = [];
        for (let i = 1; i <= yearsNumber; i++) {
            result.push(`${currentYear - i}-12`);
        }
        return result.sort().reverse();
    }

    function initSummaries() {
        var ts = {};
        // БУХГАЛТЕРСКИЙ БАЛАНС
        // АКТИВ
        // I. ВНЕОБОРОТНЫЕ АКТИВЫ
        ts.b1100 = function (q) {
            q = q || {};
            return (q.b1110 || 0) + (q.b1120 || 0) + (q.b1130 || 0) + (q.b1140 || 0) + (q.b1150 || 0) + (q.b1160 || 0) + (q.b1170 || 0) + (q.b1180 || 0) + (q.b1190 || 0);
        }
        // II. ОБОРОТНЫЕ АКТИВЫ
        ts.b1200 = function (q) {
            q = q || {};
            return (q.b1210 || 0) + (q.b1220 || 0) + (q.b1230 || 0) + (q.b1240 || 0) + (q.b1250 || 0) + (q.b1260 || 0);
        }
        // БАЛАНС
        ts.b1600 = function (q) {
            q = q || {};
            return ts.b1100(q) + ts.b1200(q);
        }

        // ПАССИВ
        ts.b1300 = function (q) {
            q = q || {};
            const calc = (q.b1310 || 0) + (q.b1320 || 0) + (q.b1340 || 0) + (q.b1350 || 0) + (q.b1360 || 0) + (q.b1370 || 0);
            if (calc) {
                return calc;
            }
            return q.b1300;

        }

        // IV. ДОЛГОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА
        ts.b1400 = function (q) {
            q = q || {};
            return (q.b1410 || 0) + (q.b1420 || 0) + (q.b1430 || 0) + (q.b1450 || 0);
        }

        // V. КРАТКОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА
        ts.b1500 = function (q) {
            q = q || {};
            return (q.b1510 || 0) + (q.b1520 || 0) + (q.b1530 || 0) + (q.b1540 || 0) + (q.b1550 || 0);
        }

        // БАЛАНС
        ts.b1700 = function (q) {
            q = q || {};
            return ts.b1300(q) + ts.b1400(q) + ts.b1500(q);
        }

        // Расхождение
        ts.bDiff = function (q) {
            q = q || {};
            return ts.b1700(q) - ts.b1600(q);
        }

        ts.bDiffShow = function (ps) {
            return ps.map(p => ts.bDiff(q) !== 0).reduce((a, b) => a || b, false);
        }

        // ОТЧЕТ О ФИНАНСОВЫХ РЕЗУЛЬТАТАХ
        // Валовая прибыль
        ts.b2100 = function (q) {
            q = q || {};
            return (q.b2110 || 0) - (q.b2120 || 0);
        }

        // Прибыль (убыток) от продаж
        ts.b2200 = function (q) {
            q = q || {};
            return ts.b2100(q) - (q.b2210 || 0) - (q.b2220 || 0);
        }

        // Прибыль (убыток) до налогообложения
        ts.b2300 = function (q) {
            q = q || {};
            return ts.b2200(q) + (q.b2320 || 0) - (q.b2330 || 0) + (q.b2310 || 0) + (q.b2340 || 0) - (q.b2350 || 0);
        }

        // Чистая прибыль (убыток) отчетного периода
        ts.b2400 = function (q) {
            q = q || {};
            if (my.isOsn() || my.isUsnBalance()) return ts.b2300(q) + (q.b2450 || 0) + (q.b2430 || 0) - Math.abs(q.b2410 || 0) + (q.b2460 || 0);
            if (my.isUsn6Kudir()) return (q.b2110 || 0) * 0.94;
            if (my.isUsn15Kudir()) return ((q.b2110 || 0) - (q.b2210 || 0)) * 0.85;
        }

        ts.notEqual = function (f1, f2, q) {
            q = q || 0;
            return ts[f1](q) !== ts[f2](q);
        }

        my.total = ts;
    };

    my.isOsn = function () {
        return my.model && my.model.taxationTypeRefId === 'osn';
    };

    my.isUsnBalance = function () {
        return my.model && my.model.taxationTypeRefId === 'usn' && my.model.taxationAccountingTypeRefId === 'f1f2';
    };

    my.isUsnKudir = function () {
        return my.model && my.model.taxationTypeRefId === 'usn' && my.model.taxationAccountingTypeRefId === 'kudir';
    };

    my.isUsn6Kudir = function () {
        return my.model && my.isUsnKudir() && my.model.taxationUSNTypeRefId === 'usn6';
    };

    my.isUsn15Kudir = function () {
        return my.model && my.isUsnKudir() && my.model.taxationUSNTypeRefId === 'usn15';
    };

    function updateModelCalc() {
        const model = my.model;
        if (!model || !model.periods) return;

        const isOsn = model.taxationTypeRefId === 'osn';
        const isUsnBalance = model.taxationTypeRefId === 'usn' && model.taxationAccountingTypeRefId === 'f1f2';
        const isUsnKudir = model.taxationTypeRefId === 'usn' && model.taxationAccountingTypeRefId === 'kudir';
        const isUsn6Kudir = isUsnKudir && model.taxationUSNTypeRefId === 'usn6';
        const isUsn15Kudir = isUsnKudir && model.taxationUSNTypeRefId === 'usn15';

        // Fill totals
        let totals = [];
        if (isOsn || isUsnBalance) totals = ['b1100', 'b1200', 'b1300', 'b1600', 'b1400', 'b1500', 'b1700', 'b2100', 'b2200', 'b2300', 'b2400'];
        else if (isUsn6Kudir || isUsn15Kudir) totals = ['b2400'];

        for (let dateMonth of Object.keys(model.periods)) {
            // Remove all unnecessary rows
            if (isUsn6Kudir) {
                model.periods[dateMonth] = {
                    'b2110': model.periods[dateMonth].b2110
                };
            } else if (isUsn15Kudir) {
                model.periods[dateMonth] = {
                    'b2110': model.periods[dateMonth].b2110,
                    'b2210': model.periods[dateMonth].b2210
                };
            }

            const period = model.periods[dateMonth];
            for (let code of totals) {
                const value = my.total[code](period);
                if (value) {
                    period[code] = my.total[code](period);
                } else {
                    delete period[code];
                }
            }

            for (let code of Object.keys(period)) {
                if (period[code] === null) {
                    delete period[code];
                }
            }
            if (!Object.keys(period).length) {
                delete model.periods[dateMonth];
            }
        }
    }

    initSummaries();

    $scope.$watch('model', onModelChange);

    $scope.$watch('model.taxationTypeRefId', function (newType) {
        if (newType) updateModelCalc();
    });

    my.onValueChange = () => updateModelCalc();

    function onModelChange() {
        if (!my.model) return;

        my.ref = {
            periods: [],
            taxationType: [{
                value: 'osn',
                name: 'ОСН'
            },
                {
                    value: 'usn',
                    name: 'УСН'
                }
            ],
            taxationAccountingType: [{
                value: 'f1f2',
                name: 'Баланс'
            },
                {
                    value: 'kudir',
                    name: 'КУДиР'
                }
            ],
            taxationUSNType: [{
                value: 'usn6',
                name: 'доходы (6%)'
            },
                {
                    value: 'usn15',
                    name: 'доходы - расходы (15%)'
                }
            ]
        };

        initPeriods();

        if (my.model.config && my.model.config.taxationAccountingType) {
            my.ref.taxationAccountingType = [];
            if (my.model.config.taxationAccountingType.f1f2) {
                my.ref.taxationAccountingType.push({
                    value: 'f1f2',
                    name: 'Баланс'
                });
            }
            if (my.model.config.taxationAccountingType.kudir) {
                my.ref.taxationAccountingType.push({
                    value: 'kudir',
                    name: 'КУДиР'
                });
            }
        }

        setDefaultTaxation();
    }

    function clearFileInput(el) {
        const $el = $(el);
        $el.wrap('<form>').closest('form').get(0).reset();
        $el.unwrap();
    }

    my.onFileInputChange = function (input) {
        if (!input.files.length) return;
        const file = [...input.files][0];
        clearFileInput(input);
        processFile(file);
    };

    my.onFileDrop = function (files) {
        if (!files || !files.length) return;
        const file = [...files][0];
        processFile(file);
    };

    //---------------------------------------------------------------------------------------------
    const errorCodes = {
        'FILE_TOO_LARGE': 'Размер файла превышает максимально разрешённое значение',
        'PARSE_FAILED': 'Не удалось импортировать данные из контейнера ФНС',
        'NO_PERIODS': 'В этом контейнере ФНС нет ни одного из необходимых периодов отчётности. Укажите другой период в поле "Последний квартал" либо выберите другой файл',
        'WRONG_COMPANY': 'Отчётность в выбранном контейнере ФНС относится к другой компании',
        'READ_FILE_FAILED': 'Не удалось считать файл',
        'UPLOAD_FAILED': 'Не удалось сохранить файл',
        'UNEXPECTED': 'Произошла непредвиденная ошибка',
    };

    function processFile(file) {
        const taskId = getTaskId();
        const companyId = my.model.companyId;
        const companyInn = my.model.companyInn;
        const companyKpp = my.model.companyKpp;
        const maxSizeInBytes = fzFilesConfig.getMaxSizeInBytes();
        const actualPeriods = my.ps;
        const modelPeriods = my.model.periods;
        const drafts = $scope.drafts;

        my.isFinParsing = true;
        my.isFinParsingError = false;
        my.finParsingMessage = '';
        return checkFileSize(file, maxSizeInBytes) // Проверить размер файла
            .then(() => parseFile(file)) // Распарсить файл
            .then(periods => selectPeriods(periods, actualPeriods)) // Проверить наличие нужных периодов
            .then(periods => validateInn(periods, companyInn, companyKpp)) // Проверить ИНН и КПП
            .then(periods => fillData(periods, modelPeriods)).then(() => updateModelCalc()) // Заполнить отчётность
            .then(() => readFile(file)) // Считать файл
            .then(content => uploadToDrafts(taskId, companyId, file.name, content, drafts)) // Загрузить файл в черновики
            .finally(() => my.isFinParsing = false)
            .then(() => my.finParsingMessage = `Отчётность успешно импортирована из файла ${file.name}`)
            .catch(e => {
                my.isFinParsingError = true;
                const msg = errorCodes[e.errorCode || 'UNEXPECTED'];
                my.finParsingMessage = msg;
            });
    }

    function getTaskId() {
        return $element.closest('form').scope().camForm.taskId; // HACK to get parent Form`s scope
    };

    function checkFileSize(file, maxSizeInBytes) {
        if (file.size > maxSizeInBytes) {
            const err = new Error("File size too large");
            err.errorCode = 'FILE_TOO_LARGE';
            return $q.reject(err);
        }
        return $q.all([]);
    }

    function parseFile(file) {
        const formData = new FormData();
        formData.append('file', file);
        return $http.post('/api/fin-report-parse/parse/osn/', formData, {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        })
            .then(resp => (resp.data || {}).companyBuhs || [])
            .catch(e => {
                e.errorCode = 'PARSE_FAILED';
                return $q.reject(e);
            });
    }

    function selectPeriods(periods, actualPeriods) {
        const selectedPeriods = periods.filter(dataForPeriod => actualPeriods.some(x => x != null && x.dateMonth === dataForPeriod.dateMonth));
        if (!selectedPeriods.length) {
            const err = new Error('No suitable periods found');
            err.errorCode = 'NO_PERIODS';
            return $q.reject(err);
        }
        return $q.when(selectedPeriods);
    }

    function validateInn(periods, companyInn, companyKpp) {
        if (periods.some(p => p.inn !== companyInn || p.kpp !== companyKpp)) {
            const err = new Error('Wrong company');
            err.errorCode = 'WRONG_COMPANY';
            return $q.reject(err);
        }
        return $q.when(periods);
    }

    function fillData(periods, modelPeriods) {
        periods.forEach(dataForPeriod => {
            const period = {};
            for (let row of (dataForPeriod.buhRecords || [])) {
                const code = `b${row.code}`;
                const amount = row.value * 100000;
                period[code] = amount;
            }
            modelPeriods[dataForPeriod.dateMonth] = period;
        });
    }

    function readFile(file) {
        const d = $q.defer();

        const reader = new FileReader();
        reader.onerror = function (e) {
            e.errorCode = 'READ_FILE_FAILED';
            d.reject(e);
        };

        reader.onload = function (e) {
            const cnt = e.target.result.substr(5).split(';');
            const content = cnt[1].substr(7);
            d.resolve(content);
        };

        reader.readAsDataURL(file);

        return d.promise;
    }

    function uploadToDrafts(taskId, companyId, filename, content, drafts) {
        const docType = 'finReportYear';
        const camundaApiBaseUrl = '/camunda/api/engine/engine/default';

        const draftName = `${docType}@${companyId}`;
        const nextNum = getNextNum(draftName, drafts);
        const numSuffix = nextNum == 1 ? '' : `!${nextNum}`;
        const varName = `$locFileDraft_${draftName}${numSuffix}`;
        const url = `${camundaApiBaseUrl}/task/${taskId}/localVariables/${encodeURIComponent(varName)}`;

        const data = {
            value: content,
            type: 'File',
            valueInfo: {
                filename: filename
            }
        }

        return $http.put(url, data).catch(e => {
            e.errorCode = 'UPLOAD_FAILED';
            return $q.reject(e);
        }).then(() => {
            if (drafts) {
                const draftFiles = angular.copy(drafts[draftName] || []);
                const draft = {
                    name: filename,
                    varName: varName
                };
                draftFiles.push(draft);
                drafts[draftName] = draftFiles;
            }
        });
    }

    function getNextNum(draftName, drafts) {
        const draftFiles = (drafts || {})[draftName] || [];
        if (!draftFiles.length) return 1;
        const numbers = draftFiles.map(x => {
            const ps = x.varName.split('!');
            if (ps.length < 2) return 1;
            return Number(ps[1]);
        });
        return Math.max(...numbers) + 1;
    }

    function setDefaultTaxation() {
        if (!my.model.taxationTypeRefId) {
            my.model.taxationTypeRefId = my.ref.taxationType[0].value;
        }
        if (!my.model.taxationAccountingTypeRefId) {
            my.model.taxationAccountingTypeRefId = my.ref.taxationAccountingType[0].value;
        }
        if (!my.model.taxationUSNTypeRefId) {
            my.model.taxationUSNTypeRefId = my.ref.taxationUSNType[0].value;
        }
    }

    //---------------------------------------------------------------------------------------------

    if (my.model) onModelChange();

    my.model = angular.copy($modalParams.item);
    my.taskId = angular.copy($modalParams.taskId);
}];