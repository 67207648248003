import angular from 'angular';
import fzlCContracts from './fzlCContracts';
import fzlCContractsKik from './fzlCContractsKik';
import fzlCContractsKbg from './fzlCContractsKbg';
import fzlCContractsPortrait from './fzlCContractsPortrait'

const moduleName = 'com.farzoom.lime.cContracts';

angular.module(moduleName, [
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzlCContracts', fzlCContracts)
    .directive('fzlCContractsKik', fzlCContractsKik)
    .directive('fzlCContractsKbg', fzlCContractsKbg)
    .directive('fzlCContractsPortrait', fzlCContractsPortrait)

export default moduleName;
