import angular from 'angular';
import component from './fzlCDocuments';

const moduleName = 'com.farzoom.pear.cDocuments';
const componentName = 'fzlCDocuments';


angular.module(moduleName, [
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzFiles'
    ])
    .directive(componentName, component)

export default moduleName;
