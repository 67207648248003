export default ['fzSvcAttribute', function (fzSvcAttribute) {
  function validateItem(item, cfg) {
    if (item.person) return validatePerson(item, cfg);
    else return validateCompany(item, cfg);
  }

  function validateCompany(item, cfg) {
    const required = field => !!cfg[field] && !!cfg[field].required;

    return !!item && !!item.company && (!!item.company.id || (!!item.company.INN && !!item.company.OGRN)) &&
      (!required('sharePercent') || !!item.sharePercent) &&
      (!required('shareSum') || !!item.shareSum);
  }

  function validatePerson(item, cfg) {
    if (!item || !item.person) return false;
    const p = item.person;
    const required = field => !!cfg[field] && !!cfg[field].required;
    const show = field => !!cfg[field] && !!cfg[field].show;

    // TO DO: требует дебага, похоже на некорректную логику
    const isRf = !cfg.citizenship || !cfg.citizenship.show || p.citizenship === (cfg.citizenship.rfCode || 'rf');

    return !!p.lastName && !!p.firstName && !!p.secondName &&
      (!required('inn') || !!p.inn) &&
      (!required('birthDate') || !!p.birthDate) &&
      (!required('birthPlace') || !!p.birthPlace) &&
      (!required('registrationAddress') || !!p.registrationAddress) &&
      (!required('actualAddress') || !!p.actualAddress) &&
      (!required('phone') || (p.contacts || []).some(x => !!x.phone)) &&
      (!required('email') || (p.contacts || []).some(x => !!x.email)) &&
      (!required('position') || !!p.position) &&
      (!required('authorizingDoc') || !!p.authorizingDoc) &&
      (!required('citizenship') || !!p.citizenship) &&
      (!required('otherCitizenship') || p.citizenship !== 'other' || !!p.otherCitizenship) &&
      (!required('identityDocument') || (!!p.number && (!isRf || (!!p.series && !!p.issuedDate && !!p.issuingAuthorityCode && !!p.issuingAuthority)))) &&
      (!show('inn') || !p.inn || p.inn.length === 12) &&
      (!p.issuedDate || checkCurrDate(p.issuedDate)) &&
      (!p.birthDate || (checkCurrDate(p.birthDate) && checkMinAge(p.birthDate, cfg))) &&
      (!required('sharePercent') || !!item.sharePercent || item.sharePercent === 0) &&
      (!show('sharePercent') || !item.sharePercent || (item.sharePercent >= 0 && item.sharePercent <= 100)) &&
      (!required('shareSum') || (!!item.shareSum !== 0)) &&
      fzSvcAttribute.validateGroup(item.personAttributes)
      ;
  }

  function checkMinAge(birthDate, cfg) {
    let minAge = (cfg.age || {}).minimalAge || 0;
    if (minAge <= 0) return true;
    const now = new Date();
    const today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
    const bDate = new Date(birthDate + 'T00:00:00Z');
    const age = new Date((today - bDate)).getFullYear() - 1970;

    return age >= minAge;
  }

  function checkCurrDate(checkDate) {
    const now = new Date();
    let y = now.getFullYear();
    let m = now.getMonth() + 1;
    let d = now.getDate();
    let today = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}` ;
    return checkDate <= today;
  }

  function validateShareholder(model, idx) {
    if (!model || !model.data || !model.data.length) return true;
    const item = model.data[idx];
    const cfg = model.config || {};

    return validateItem(item, cfg)
  }

  function validateShares(model) {
    if (!model || !model.data || !model.data.length) return true;
    const totalShares = model.data
      .map(p => p.sharePercent)
      .reduce((sum, share) => (sum || 0) + (share || 0));
    return (totalShares >= 0 && totalShares <= 100.00);
  }

  function validateAll(model) {
    if (!model || !model.data || !model.data.length) return true;
    const cfg = model.config || {};

    return model.data.every(item => validateItem(item, cfg)) && validateShares(model)
  }

  this.validateShareholder = validateShareholder;
  this.validateShares = validateShares;
  this.validateAll = validateAll;
}];