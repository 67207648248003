export const validate = {
  length: (val, len) => val ? val.length <= len : true,
  codeword: (codeword) => /^[А-Яа-я]+$/.test(codeword),
  barcode: (barcode) => /^[A-Za-z0-9$]+$/.test(barcode) && barcode?.length === 16
}

export const getCode = (item) => Object.keys(item)[0]

export const getValue = (object, path = '') => path.split('.')
  .reduce((result, item) => result === undefined ? result : result[item], object[getCode(object)])

const PERSON = {
  "inn": "",
  "lastName": "",
  "firstName": "",
  "secondName": "",
  "birthDate": "",
  "birthPlace": "",
  "series": "",
  "number": "",
  "issuingAuthority": null,
  "issuingAuthorityCode": null,
  "issuedDate": null,
  "contacts": [{}],
  "position": null,
  "authorizingDoc": null,
  "citizenship": null,
  "otherCitizenship": null,
}

const PERSON_ATTRIBUTES = {
  "header": "",
  "items": [
    {
      "attributeId": "person.citizenship",
      "name": "Гражданство",
      "type": "key",
      "requiredExpr": "true",
      "ref": [
        {
          "pos": 1,
          "value": "rf",
          "name": "РФ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "no-citizenship",
          "name": "Без гражданства",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "other",
          "name": "Иное",
          "isInactive": null
        }
      ],
      "value": {}
    },
    {
      "attributeId": "person.otherCitizenship",
      "name": "Укажите гражданство (если иное)",
      "type": "key",
      "requiredExpr": "$('person.citizenship')=='other'",
      "showExpr": "$('person.citizenship')=='other'",
      "ref": [
        {
          "pos": 999999,
          "value": "895",
          "name": "АБХАЗИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "36",
          "name": "АВСТРАЛИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "40",
          "name": "АВСТРИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "31",
          "name": "АЗЕРБАЙДЖАН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "8",
          "name": "АЛБАНИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "12",
          "name": "АЛЖИР",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "16",
          "name": "АМЕРИКАНСКОЕ САМОА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "660",
          "name": "АНГИЛЬЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "24",
          "name": "АНГОЛА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "20",
          "name": "АНДОРРА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "10",
          "name": "АНТАРКТИДА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "28",
          "name": "АНТИГУА И БАРБУДА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "32",
          "name": "АРГЕНТИНА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "51",
          "name": "АРМЕНИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "533",
          "name": "АРУБА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "4",
          "name": "АФГАНИСТАН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "44",
          "name": "БАГАМЫ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "50",
          "name": "БАНГЛАДЕШ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "52",
          "name": "БАРБАДОС",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "48",
          "name": "БАХРЕЙН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "112",
          "name": "БЕЛАРУСЬ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "84",
          "name": "БЕЛИЗ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "56",
          "name": "БЕЛЬГИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "204",
          "name": "БЕНИН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "60",
          "name": "БЕРМУДЫ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "100",
          "name": "БОЛГАРИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "68",
          "name": "БОЛИВИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "535",
          "name": "БОНЭЙР, СИНТ-ЭСТАТИУС И САБА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "70",
          "name": "БОСНИЯ И ГЕРЦЕГОВИНА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "72",
          "name": "БОТСВАНА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "76",
          "name": "БРАЗИЛИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "86",
          "name": "БРИТАНСКАЯ ТЕРРИТОРИЯ В ИНДИЙСКОМ ОКЕАНЕ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "96",
          "name": "БРУНЕЙ-ДАРУССАЛАМ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "854",
          "name": "БУРКИНА-ФАСО",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "108",
          "name": "БУРУНДИ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "64",
          "name": "БУТАН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "548",
          "name": "ВАНУАТУ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "348",
          "name": "ВЕНГРИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "862",
          "name": "ВЕНЕСУЭЛА БОЛИВАРИАНСКАЯ РЕСПУБЛИКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "92",
          "name": "ВИРГИНСКИЕ ОСТРОВА, БРИТАНСКИЕ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "850",
          "name": "ВИРГИНСКИЕ ОСТРОВА, США",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "704",
          "name": "ВЬЕТНАМ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "266",
          "name": "ГАБОН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "332",
          "name": "ГАИТИ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "328",
          "name": "ГАЙАНА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "270",
          "name": "ГАМБИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "288",
          "name": "ГАНА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "312",
          "name": "ГВАДЕЛУПА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "320",
          "name": "ГВАТЕМАЛА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "324",
          "name": "ГВИНЕЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "624",
          "name": "ГВИНЕЯ-БИСАУ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "276",
          "name": "ГЕРМАНИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "831",
          "name": "ГЕРНСИ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "292",
          "name": "ГИБРАЛТАР",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "340",
          "name": "ГОНДУРАС",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "344",
          "name": "ГОНКОНГ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "308",
          "name": "ГРЕНАДА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "304",
          "name": "ГРЕНЛАНДИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "300",
          "name": "ГРЕЦИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "268",
          "name": "ГРУЗИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "316",
          "name": "ГУАМ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "208",
          "name": "ДАНИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "832",
          "name": "ДЖЕРСИ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "262",
          "name": "ДЖИБУТИ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "212",
          "name": "ДОМИНИКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "214",
          "name": "ДОМИНИКАНСКАЯ РЕСПУБЛИКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "818",
          "name": "ЕГИПЕТ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "894",
          "name": "ЗАМБИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "732",
          "name": "ЗАПАДНАЯ САХАРА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "716",
          "name": "ЗИМБАБВЕ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "376",
          "name": "ИЗРАИЛЬ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "356",
          "name": "ИНДИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "360",
          "name": "ИНДОНЕЗИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "400",
          "name": "ИОРДАНИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "368",
          "name": "ИРАК",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "364",
          "name": "ИРАН, ИСЛАМСКАЯ РЕСПУБЛИКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "372",
          "name": "ИРЛАНДИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "352",
          "name": "ИСЛАНДИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "724",
          "name": "ИСПАНИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "380",
          "name": "ИТАЛИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "999",
          "name": "Информация отсутствует",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "887",
          "name": "ЙЕМЕН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "132",
          "name": "КАБО-ВЕРДЕ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "398",
          "name": "КАЗАХСТАН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "116",
          "name": "КАМБОДЖА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "120",
          "name": "КАМЕРУН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "124",
          "name": "КАНАДА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "634",
          "name": "КАТАР",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "404",
          "name": "КЕНИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "196",
          "name": "КИПР",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "417",
          "name": "КИРГИЗИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "296",
          "name": "КИРИБАТИ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "156",
          "name": "КИТАЙ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "166",
          "name": "КОКОСОВЫЕ (КИЛИНГ) ОСТРОВА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "170",
          "name": "КОЛУМБИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "174",
          "name": "КОМОРЫ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "178",
          "name": "КОНГО",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "180",
          "name": "КОНГО, ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "408",
          "name": "КОРЕЯ, НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "410",
          "name": "КОРЕЯ, РЕСПУБЛИКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "188",
          "name": "КОСТА-РИКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "384",
          "name": "КОТ Д'ИВУАР",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "192",
          "name": "КУБА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "414",
          "name": "КУВЕЙТ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "531",
          "name": "КЮРАСАО",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "418",
          "name": "ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "428",
          "name": "ЛАТВИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "426",
          "name": "ЛЕСОТО",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "430",
          "name": "ЛИБЕРИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "422",
          "name": "ЛИВАН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "434",
          "name": "ЛИВИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "440",
          "name": "ЛИТВА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "438",
          "name": "ЛИХТЕНШТЕЙН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "442",
          "name": "ЛЮКСЕМБУРГ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "480",
          "name": "МАВРИКИЙ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "478",
          "name": "МАВРИТАНИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "450",
          "name": "МАДАГАСКАР",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "175",
          "name": "МАЙОТТА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "446",
          "name": "МАКАО",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "454",
          "name": "МАЛАВИ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "458",
          "name": "МАЛАЙЗИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "466",
          "name": "МАЛИ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "581",
          "name": "МАЛЫЕ ТИХООКЕАНСКИЕ ОТДАЛЕННЫЕ ОСТРОВА СОЕДИНЕННЫХ ШТАТОВ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "462",
          "name": "МАЛЬДИВЫ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "470",
          "name": "МАЛЬТА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "504",
          "name": "МАРОККО",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "474",
          "name": "МАРТИНИКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "584",
          "name": "МАРШАЛЛОВЫ ОСТРОВА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "484",
          "name": "МЕКСИКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "583",
          "name": "МИКРОНЕЗИЯ, ФЕДЕРАТИВНЫЕ ШТАТЫ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "508",
          "name": "МОЗАМБИК",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "498",
          "name": "МОЛДОВА, РЕСПУБЛИКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "492",
          "name": "МОНАКО",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "496",
          "name": "МОНГОЛИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "500",
          "name": "МОНТСЕРРАТ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "104",
          "name": "МЬЯНМА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "998",
          "name": "Международные организации",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "516",
          "name": "НАМИБИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "520",
          "name": "НАУРУ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "524",
          "name": "НЕПАЛ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "562",
          "name": "НИГЕР",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "566",
          "name": "НИГЕРИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "530",
          "name": "НИДЕРЛАНДСКИЕ АНТИЛЫ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "528",
          "name": "НИДЕРЛАНДЫ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "558",
          "name": "НИКАРАГУА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "570",
          "name": "НИУЭ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "554",
          "name": "НОВАЯ ЗЕЛАНДИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "540",
          "name": "НОВАЯ КАЛЕДОНИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "578",
          "name": "НОРВЕГИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "830",
          "name": "НОРМАНДСКИЕ ОСТРОВА, КРОМЕ ГЕРНСИ, ДЖЕРСИ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "784",
          "name": "ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "512",
          "name": "ОМАН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "74",
          "name": "ОСТРОВ БУВЕ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "833",
          "name": "ОСТРОВ МЭН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "574",
          "name": "ОСТРОВ НОРФОЛК",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "162",
          "name": "ОСТРОВ РОЖДЕСТВА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "334",
          "name": "ОСТРОВ ХЕРД И ОСТРОВА МАКДОНАЛЬД",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "136",
          "name": "ОСТРОВА КАЙМАН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "184",
          "name": "ОСТРОВА КУКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "796",
          "name": "ОСТРОВА ТЕРКС И КАЙКОС",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "586",
          "name": "ПАКИСТАН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "585",
          "name": "ПАЛАУ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "275",
          "name": "ПАЛЕСТИНСКАЯ ТЕРРИТОРИЯ, ОККУПИРОВАННАЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "591",
          "name": "ПАНАМА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "336",
          "name": "ПАПСКИЙ ПРЕСТОЛ (ГОСУДАРСТВО-ГОРОД ВАТИКАН)",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "598",
          "name": "ПАПУА-НОВАЯ ГВИНЕЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "600",
          "name": "ПАРАГВАЙ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "604",
          "name": "ПЕРУ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "612",
          "name": "ПИТКЕРН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "616",
          "name": "ПОЛЬША",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "620",
          "name": "ПОРТУГАЛИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "630",
          "name": "ПУЭРТО-РИКО",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "997",
          "name": "Представительства и филиалы",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "807",
          "name": "РЕСПУБЛИКА МАКЕДОНИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "638",
          "name": "РЕЮНЬОН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "646",
          "name": "РУАНДА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "642",
          "name": "РУМЫНИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "882",
          "name": "САМОА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "674",
          "name": "САН-МАРИНО",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "678",
          "name": "САН-ТОМЕ И ПРИНСИПИ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "682",
          "name": "САУДОВСКАЯ АРАВИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "748",
          "name": "СВАЗИЛЕНД",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "654",
          "name": "СВЯТАЯ ЕЛЕНА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "580",
          "name": "СЕВЕРНЫЕ МАРИАНСКИЕ ОСТРОВА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "690",
          "name": "СЕЙШЕЛЫ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "652",
          "name": "СЕН-БАРТЕЛЕМИ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "663",
          "name": "СЕН-МАРТЕН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "534",
          "name": "СЕН-МАРТЕН (нидерландская часть)",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "686",
          "name": "СЕНЕГАЛ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "670",
          "name": "СЕНТ-ВИНСЕНТ И ГРЕНАДИНЫ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "659",
          "name": "СЕНТ-КИТС И НЕВИС",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "662",
          "name": "СЕНТ-ЛЮСИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "666",
          "name": "СЕНТ-ПЬЕР И МИКЕЛОН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "688",
          "name": "СЕРБИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "702",
          "name": "СИНГАПУР",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "760",
          "name": "СИРИЙСКАЯ АРАБСКАЯ РЕСПУБЛИКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "703",
          "name": "СЛОВАКИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "705",
          "name": "СЛОВЕНИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "826",
          "name": "СОЕДИНЕННОЕ КОРОЛЕВСТВО",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "840",
          "name": "СОЕДИНЕННЫЕ ШТАТЫ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "90",
          "name": "СОЛОМОНОВЫ ОСТРОВА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "706",
          "name": "СОМАЛИ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "729",
          "name": "СУДАН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "740",
          "name": "СУРИНАМ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "694",
          "name": "СЬЕРРА-ЛЕОНЕ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "762",
          "name": "ТАДЖИКИСТАН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "764",
          "name": "ТАИЛАНД",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "158",
          "name": "ТАЙВАНЬ (КИТАЙ)",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "834",
          "name": "ТАНЗАНИЯ, ОБЪЕДИНЕННАЯ РЕСПУБЛИКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "626",
          "name": "ТИМОР-ЛЕСТЕ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "768",
          "name": "ТОГО",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "772",
          "name": "ТОКЕЛАУ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "776",
          "name": "ТОНГА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "780",
          "name": "ТРИНИДАД И ТОБАГО",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "798",
          "name": "ТУВАЛУ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "788",
          "name": "ТУНИС",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "795",
          "name": "ТУРКМЕНИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "792",
          "name": "ТУРЦИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "800",
          "name": "УГАНДА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "860",
          "name": "УЗБЕКИСТАН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "804",
          "name": "УКРАИНА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "876",
          "name": "УОЛЛИС И ФУТУНА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "858",
          "name": "УРУГВАЙ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "234",
          "name": "ФАРЕРСКИЕ ОСТРОВА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "242",
          "name": "ФИДЖИ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "608",
          "name": "ФИЛИППИНЫ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "246",
          "name": "ФИНЛЯНДИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "238",
          "name": "ФОЛКЛЕНДСКИЕ ОСТРОВА (МАЛЬВИНСКИЕ)",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "250",
          "name": "ФРАНЦИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "254",
          "name": "ФРАНЦУЗСКАЯ ГВИАНА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "259",
          "name": "ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "258",
          "name": "ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "260",
          "name": "ФРАНЦУЗСКИЕ ЮЖНЫЕ ТЕРРИТОРИИ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "191",
          "name": "ХОРВАТИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "140",
          "name": "ЦЕНТРАЛЬНО-АФРИКАНСКАЯ  РЕСПУБЛИКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "148",
          "name": "ЧАД",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "499",
          "name": "ЧЕРНОГОРИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "203",
          "name": "ЧЕШСКАЯ РЕСПУБЛИКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "152",
          "name": "ЧИЛИ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "756",
          "name": "ШВЕЙЦАРИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "752",
          "name": "ШВЕЦИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "744",
          "name": "ШПИЦБЕРГЕН И ЯН МАЙЕН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "144",
          "name": "ШРИ-ЛАНКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "218",
          "name": "ЭКВАДОР",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "226",
          "name": "ЭКВАТОРИАЛЬНАЯ ГВИНЕЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "248",
          "name": "ЭЛАНДСКИЕ ОСТРОВА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "222",
          "name": "ЭЛЬ-САЛЬВАДОР",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "232",
          "name": "ЭРИТРЕЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "233",
          "name": "ЭСТОНИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "231",
          "name": "ЭФИОПИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "710",
          "name": "ЮЖНАЯ АФРИКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "239",
          "name": "ЮЖНАЯ ДЖОРДЖИЯ И ЮЖНЫЕ САНДВИЧЕВЫ ОСТРОВА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "896",
          "name": "ЮЖНАЯ ОСЕТИЯ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "728",
          "name": "ЮЖНЫЙ СУДАН",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "388",
          "name": "ЯМАЙКА",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "392",
          "name": "ЯПОНИЯ",
          "isInactive": null
        }
      ],
      "value": {}
    },
    {
      "attributeId": "person.gender",
      "name": "Пол",
      "type": "key",
      "requiredExpr": "false",
      "showExpr": "false",
      "ref": [
        {
          "pos": 999999,
          "value": "female",
          "name": "Женский",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "male",
          "name": "Мужской",
          "isInactive": null
        }
      ],
      "value": {}
    },
    {
      "attributeId": "person.identityDocRf",
      "name": "Основной документ, удостоверяющий личность",
      "type": "key",
      "requiredExpr": "$('person.citizenship')=='rf'",
      "showExpr": "$('person.citizenship')=='rf'",
      "ref": [
        {
          "pos": 1,
          "value": "PersDoc_Passport",
          "name": "паспорт гражданина Российской Федерации",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "PersDoc_InternationalPassport",
          "name": "ДУЛ, гражданина РФ за пределами РФ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "PersDoc_OtherIdentityCard",
          "name": "Иные документы гражданина РФ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "PersDoc_BirthCertificate",
          "name": "Свидетельство о рождении ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "PersDoc_TemporaryIdentityCard",
          "name": "временное удостоверение личности гражданина РФ, выдаваемое на период оформления паспорта гражданина РФ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "PersDoc_DiplomaticPassport",
          "name": "дипломатический паспорт",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "PersDoc_ServicePassport",
          "name": "служебный паспорт",
          "isInactive": null
        }
      ],
      "value": {}
    },
    {
      "attributeId": "person.identityDocOther",
      "name": "Основной документ, удостоверяющий личность",
      "type": "key",
      "requiredExpr": "$('person.citizenship')=='other'",
      "showExpr": "$('person.citizenship')=='other'",
      "ref": [
        {
          "pos": 999999,
          "value": "PersDoc_ForeignCitizenPassport",
          "name": "паспорт иностранного гражданина",
          "isInactive": null
        }
      ],
      "value": {}
    },
    {
      "attributeId": "person.identityDocNo",
      "name": "Основной документ, удостоверяющий личность",
      "type": "key",
      "requiredExpr": "$('person.citizenship')=='no-citizenship'",
      "showExpr": "$('person.citizenship')=='no-citizenship'",
      "ref": [
        {
          "pos": 999999,
          "value": "PersDoc_Pre-CitizenshipTempDoc",
          "name": "Временный документ до признания лица гражданином РФ или о приеме в гражданство РФ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "PersDoc_Non-residentIdentityCard_1",
          "name": "Иной документ, подтверждающий личность ИГ и ЛБГ и право на проживание",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "PersDoc_StatelessPersonTemporaryResidencePermi",
          "name": "Разреш. на врем. прожив. лица без гр. на т. РФ",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "PersDoc_StatelessIdentityCard",
          "name": "Удостоверение лица без гражданства",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "PersDoc_StatelessPersonResidencePermit_2",
          "name": "вид на жительство",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "PersDoc_StatelessPersonResidencePermit_1",
          "name": "вид на жительство лица без гражданства",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "PersDoc_RefugeeConsiderationPetitionCertificate",
          "name": "свидетельство о рассмотрении ходатайства о признании беженцем на территории РФ по существу",
          "isInactive": null
        },
        {
          "pos": 999999,
          "value": "PersDoc_RefugeeIdentityCard",
          "name": "удостоверение беженца РФ",
          "isInactive": null
        }
      ],
      "value": {}
    },
    {
      "attributeId": "person.otherDocName",
      "name": "Название иного документа",
      "type": "string",
      "requiredExpr": "false",
      "showExpr": "false",
      "value": {}
    },
    {
      "attributeId": "person.isIp",
      "name": "Является ИП",
      "type": "boolean",
      "value": {}
    },
    {
      "attributeId": "person.ogrnip",
      "name": "Основной государственный регистрационный номер записи о государственной регистрации индивидуального предпринимателя согласно Свидетельству о государственной регистрации физического лица в качестве индивидуального предпринимателя (Свидетельству о внесении записи в Единый государственный реестр индивидуальных предпринимателей записи об индивидуальном предпринимателе, зарегистрированном до 1 января 2004 года)",
      "type": "key",
      "requiredExpr": "$('person.isIp')",
      "showExpr": "$('person.isIp')",
      "value": {}
    },
    {
      "attributeId": "person.ipRegPlace",
      "name": "Место государственной регистрации",
      "type": "string",
      "requiredExpr": "$('person.isIp')",
      "showExpr": "$('person.isIp')",
      "value": {}
    },
    {
      "attributeId": "person.snils",
      "name": "СНИЛС",
      "type": "key",
      "value": {}
    }
  ]
}

export const PRODUCTS = [
  { name: '«Клиент-Банк»', type: 'clientBank' },
  { name: '«МТС Бизнес»', type: 'mtsBusiness' },
  { name: 'Корпоративная карта с СКС', type: 'corpCard' },
  { name: 'Услуга по зачислению процентов на остаток на счете', type: 'percentAccrual' },
  { name: 'Карта к расчетному счету', type: 'cardToAccount' },
  { name: 'Специальный счет участника закупки', type: 'specialAccountProcurementParticipant' },
  { name: 'Налоговая копилка', type: 'taxPiggyBank' },
]

export const INITIAL_VALUES = {
  clientBank: {
    "header": PRODUCTS.find(item => item.key === 'clientBank')?.name,
    "provideLogin": false,
    "sendToEmail": false,
    "email": "",
    "phone": "",
    "useSimpleESign": false,
    "person": { ...PERSON },
    "personAttributes": { ...PERSON_ATTRIBUTES },
  },
  mtsBusiness: {
    "header": PRODUCTS.find(item => item.key === 'mtsBusiness')?.name,
    "phone": "",
    "person": { ...PERSON },
    "personAttributes": { ...PERSON_ATTRIBUTES },
  },
  corpCard: {
    "header": PRODUCTS.find(item => item.key === 'corpCard')?.name,
    "phone": "",
    "barcode": "",
    "codeword": "",
    "sendToPhone": false,
    "plugCashback": false,
    "person": { ...PERSON },
    "personAttributes": { ...PERSON_ATTRIBUTES },
  },
  percentAccrual: {
    "header": PRODUCTS.find(item => item.key === 'percentAccrual')?.name,
    "percentAccrual": false,
  },
  specialAccountProcurementParticipant: {
       "header": PRODUCTS.find(item => item.key === 'specialAccountProcurementParticipant')?.name,
       "specialAccountProcurementParticipant": false,
  },
  taxPiggyBank: {
       "header": PRODUCTS.find(item => item.key === 'taxPiggyBank')?.name,
       "taxPiggyBankEnabled": false,
       "taxPiggyBank": false,
       "percent": "",
  },
  cardToAccount: {
    "header": PRODUCTS.find(item => item.key === 'cardToAccount')?.name,
    "phone": "",
    "barcode": "",
    "codeword": "",
    "sendToPhone": false,
    "plugCashback": false,
    "person": { ...PERSON },
    "personAttributes": { ...PERSON_ATTRIBUTES },
    },
}

export const ATTRIBUTE_FIELDS = [
  'personAttributes',
  'relationAttributes',
  'relationTopAttributes',
]