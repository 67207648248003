import modalTmpl from '../cGuarantorsEditTab/modal/template.html';
import modalCtrl from '../cGuarantorsEditTab/modal/controller';

const ctrl = ['$scope', 'fzModal', function ($scope, fzModal) {
    const my = this;

    my.onButtonClickHandler = function (item) {
        fzModal.open({
            windowClass: 'fz-c-guarantors__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                item: item,
                ref: $scope.model.ref,
                config: $scope.model.config,
                readonly: true
            }
        });
    };
}];

export default ctrl;