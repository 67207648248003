
const ctrl = ['$scope', function ($scope) {
    const my = this;





  }];
  
  export default ctrl;