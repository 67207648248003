const ctrl = function ($scope) {
    const my = this;
    $scope.uniqueId = new Date().getTime() + (Math.random() * 100000000000000000).toString(36);

    my.removeValue = function (values, index) {
        values.splice(index, 1);
    };

    my.checkCurrDate = function (checkDate){
      const now = new Date();
      if (!checkDate) {
        return true; // не проверяем пустую дату
      }
      let y = now.getFullYear();
      let m = now.getMonth() + 1;
      let d = now.getDate();
      let today = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}`;
      return checkDate >= today;
    };
};
ctrl.$inject = ['$scope'];
export default ctrl;