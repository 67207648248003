
import angular from 'angular';
import fzlCManualParams from './fzlCVerification';

const moduleName = 'com.farzoom.pear.cVerification';
const comopnentName = 'fzlCVerification';

angular.module(moduleName, [])
    .directive(comopnentName, fzlCManualParams)

export default moduleName;
