
const ctrl = ['$scope', function ($scope) {
    const my = this;

    my.state = {
      isDeposit : null,
      isOfficeSign : null,
      validate : null,
      checkBoxes : {
        ebgCheckbox : false,
        bankOfficeCheckbox : false,
        provisionCheckBox : false,
        depositCheckBox : false
      } 
    };

  
    $scope.$watch('model.provisionParam', function (provisionParam) {
        switch (provisionParam[provisionParam.length - 1]) {
            case "d":
                my.state.isDeposit = true;
                break;
            case "z":
                my.state.isDeposit = false;
                break;
        }
    });

    $scope.$watch('validate', function (validate) {
        my.state.validate = validate
    });

    $scope.$watch('my.state.checkBoxes.ebgCheckbox', function (ebgCheckbox) {
        if (ebgCheckbox) {
            my.state.checkBoxes.bankOfficeCheckbox = false;
            $scope.model.sigDocumentOffice = null;
        }
    });
    $scope.$watch('my.state.checkBoxes.bankOfficeCheckbox', function (bankOfficeCheckbox) {
        if (bankOfficeCheckbox) {
            my.state.checkBoxes.ebgCheckbox = false;
        }
    });

    $scope.$watch('my.state.checkBoxes.depositCheckBox', function (depositCheckBox) {
        if (depositCheckBox) {
            my.state.checkBoxes.provisionCheckBox = false;
            $scope.model.provisionResult = "deposit";
        }
    });
    $scope.$watch('my.state.checkBoxes.provisionCheckBox', function (provisionCheckBox) {
        if (provisionCheckBox) {
            my.state.checkBoxes.depositCheckBox = false;
            $scope.model.provisionResult = "collateral";
        }
    });
  }];
  
  export default ctrl;