import jQuery from 'jquery';

export default ['$http', '$q', '$timeout', 'fzSvcAutocomplete', function ($http, $q, $timeout, fzSvcAutocomplete) {
    return {
        restrict: 'A',
        require: '?ngModel',
        scope: {
            itemName: '=?',
            itemCode: '=?',
            ref: '@',
        },
        link: function ($scope, $element, attrs, ngModel) {
            function updateValueFromModel() {
                const value = $scope.itemName;
                const ac = $element.fzAutocomplete();
                ac.currentValue = value || '';
                $element.val(value || '');
                refreshNgModel(value);
            }

            function refreshNgModel(value) {
                if (ngModel) {
                    ngModel.$setViewValue(value);
                    ngModel.$render();
                }
            }

            jQuery($element).blur(function () {
                if (!$element.val()) {
                    $scope.$apply(function () {
                        $scope.itemName = undefined;
                        $scope.itemCode = undefined;
                        refreshNgModel(undefined);
                    });
                } else {
                    $scope.$apply(function () {
                        updateValueFromModel();
                    });
                }
            });

            $scope.$watch('itemName', function () {
                updateValueFromModel();
            });

            // debounce prevents from calling fnc several times in a row
            let timeoutHandler;

            function debounce(fnc, debounceTimeout) {
                if (timeoutHandler) $timeout.cancel(timeoutHandler);
                timeoutHandler = $timeout(fnc, debounceTimeout);
            };

            let lookupCanceler = null;
            function lookup(query, done) {
                debounce(() => {
                    if (lookupCanceler) lookupCanceler.resolve(); // cancel request if it is already in progress
                    lookupCanceler = $q.defer();

                    const url = `/es/ref/${$scope.ref}/_search?sort=pos:asc&q=name.name_autocomplete:${encodeURIComponent(query)}`;
                    $http.get(url, {
                        timeout: lookupCanceler.promise
                    }).then(function (res) {
                        const suggestions = (((res.data || {}).hits || []).hits || []).map(hit => ({
                            value: (hit._source || {}).name,
                            data: {
                                code: hit._id,
                                name: (hit._source || {}).name,
                            }
                        }));
                        done({
                            suggestions: suggestions
                        });
                    });
                }, 300);
            }

            fzSvcAutocomplete.bootstrap($element, {
                lookup: (query, done) => lookup(query, done),
                minChars: 1,
                maxHeight: 300,
                tabDisabled: true,
                orientation: 'auto',
                noSuggestionNotice: 'Значение не найдено',
                showNoSuggestionNotice: true,
                triggerSelectOnValidInput: false,
                formatResult: function (suggestion, currentValue) {
                    var div = $('<div class="address">');
                    $('<h5>').text(suggestion.value).appendTo(div);
                    return div[0].outerHTML;
                },
                beforeRender: function (container, suggestions) {
                    const words = this.value.split(' ');
                    fzSvcAutocomplete.highlight(container, words);
                },
                onSelect: function (item) {
                    $scope.$apply(function () {
                        $scope.itemName = (item.data || {}).name;
                        $scope.itemCode = (item.data || {}).code;
                    });
                }
            });

            const instance = jQuery($element).fzAutocomplete();
            $scope.$on('$destroy', function cleanup() {
                instance.dispose();
            });
        }
    }
}];
