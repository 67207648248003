import angular from 'angular';
import fzlCOrderKik from './fzlCOrderKik';

const moduleName = 'com.farzoom.lime.cOrder.kik';

angular.module(moduleName, [
        'com.farzoom.common.fzAddress',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzlCOrderKik', fzlCOrderKik)

export default moduleName;
