import angular from 'angular';
import cAvailableOffersEditTab from './cAvailableOffersEditTab';

const moduleName = 'com.farzoom.common.cAvailableOffers';

angular.module(moduleName, [
    'ui.mask',
    'com.farzoom.common.cAttributes',
    'com.farzoom.common.fzModal',
    'com.farzoom.common.fzTaskFormParts'
])
    .directive('fzlCAvailableOffersEditTab', cAvailableOffersEditTab)

export default moduleName;