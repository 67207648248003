import angular from 'angular';
import cBgOfferRecViewTab from './cBgOfferRecViewTab';

const moduleName = 'com.farzoom.lime.cOfferRec';

angular.module(moduleName, [
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzSvcNumber',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzlCBgOfferRecViewTab', cBgOfferRecViewTab)

export default moduleName;
