import angular from 'angular';
import cKkCommissionViewTab from './cKkCommissionViewTab';
import svcKkCommission from './svcKkCommission';

const moduleName = 'com.farzoom.lime.cKkCommission';

angular.module(moduleName, [
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzSvcNumber',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzlCKkCommissionViewTab', cKkCommissionViewTab)
    .service('svcKkCommission', svcKkCommission);

export default moduleName;
