export default [{
        code: '12300',
        name: 'ООО'
    },
    {
        code: '12200',
        name: 'АО'
    },
    {
        code: '50102',
        name: 'ИП'
    }
];