import './styles.css'

const EMPTY_LICENSE = {
  activities: null,
  number: null,
  issuingAuthority: null,
  issuedDate: null,
  expiredDate: null,
  edited: false,
  termless: false,
}

const isEdited = (license, selectedLicense) =>
  license.activities !== selectedLicense.activities ||
  license.number !== selectedLicense.number ||
  license.issuingAuthority !== selectedLicense.issuingAuthority ||
  license.issuedDate !== selectedLicense.issuedDate ||
  license.expiredDate !== selectedLicense.expiredDate

const checkIsEdited = (license, selectedLicense) => {
  license.edited = isEdited(license, selectedLicense)
}

const checkUpdateTime = (license) => {
  if (license.edited) {
    license.updatedDateTime = new Date()
  } else {
    delete license.updatedDateTime
  }
}

const fillAttributes = (destAttrs, srcAttrs) => {
  if (destAttrs?.items?.length >0 && srcAttrs?.items?.length > 0) {
    const destMap = new Map()
    destAttrs.items.forEach(attr => destMap.set(attr.attributeId, attr))
    srcAttrs.items.forEach(attr => {
      if (destMap.has(attr.attributeId)) {
        const destAttr = destMap.get(attr.attributeId)
        destAttr.value = attr.value
        destAttr.values = attr.values
      }
    })
  }
}

export default ['$scope', '$modalInstanse', '$modalParams', function ($scope, $modalInstanse, $modalParams) {
  const my = this
  my.model = angular.copy($modalParams.model)
  my.data = my.model.data
  my.readonly = $modalParams.readonly
  my.checkValid = $modalParams.checkValid
  my.config = $modalParams.model.config
  my.licensesList = $modalParams.model.licensesList
  my.licensesRef = $modalParams.model.licensesRef.map(item => ({
    activities: item?.name,
    issuedDate: item?.startDate,
    expiredDate: item?.endDate,
    refId: item?.code,
  }))
  my.isActivityFilled = $modalParams.isActivityFilled
  my.checkDate = $modalParams.checkDate

  my.initialData = angular.copy($modalParams.model.data)
  my.selectedActivities = ''
  my.filterLicenses = (item) => !!item.number || !!item.activities
  if (!my.data) {
    my.data = {}
  }
  if (!my.data.contacts) {
    my.data.contacts = []
  }
  if (!my.data.contacts.length) {
    my.data.contacts.push({})
  }

  my.closeOk = () => $modalInstanse.close(my.data)
  my.closeCancel = () => $modalInstanse.close()

  my.removeContact = (item) => {
    const index = my.data.contacts.indexOf(item)
    if (index > -1) {
      my.data.contacts.splice(index, 1)
    }
  }

  my.addContact = () => my.data.contacts.push({})

  my.onSelect = (item) => {
    if (item) {
      const refData = JSON.parse(JSON.stringify(item.data))
      for (let key of Object.keys(refData)) {
        if (key !== 'personAttributes' && key !== 'relationAttributes') {
          my.data[key] = refData[key]
        }
      }

      // Always show at least one line of contacts
      if (my.config.phone.show || my.config.email.show) {
        if (!my.data.contacts) {
          my.data.contacts = []
        }
        if (!my.data.contacts.length) {
          my.data.contacts.push({})
        }
      }

      fillAttributes(my.data.personAttributes, refData.personAttributes)
      fillAttributes(my.data.relationAttributes, refData.relationAttributes)
    }
  }

  my.checkINN12 = () =>
    my?.readonly ||
    !my?.config?.inn?.show ||
    !my?.data?.inn ||
    my?.data?.inn?.length == 12

  my.checkCurrDate = (checkDate) => {
    const now = new Date()
    if (!checkDate) {
      return true // не проверяем пустую дату
    } 
    let y = now.getFullYear()
    let m = now.getMonth() + 1
    let d = now.getDate()
    let today = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}` 
    return checkDate <= today
  }

  my.checkMinAge = () => {
    const minAge = ((my.config || {}).age || {}).minimalAge || 0
    if (minAge <= 0) {
      return true
    }
    if (!my.data.birthDate) {
      return true // не проверяем пустую дату
    }
    const now = new Date()
    const today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()))
    const bDate = new Date(my.data.birthDate + 'T00:00:00Z')
    const age = new Date((today - bDate)).getFullYear() - 1970
    return age < 0 || age >= minAge //   age<0 проверит checkCurrDate
  }

  $scope.$watch('my.data.reputationAttributes.items[2].value.booleanValue',function(){
    my.clearValuesReputation()
  });

  my.clearValuesReputation = function () {
    const reputation3 = my?.data?.reputationAttributes?.items[2]?.value?.booleanValue
    if(!reputation3){
      my.data.reputationAttributes.items[3].value.stringValue="-"
      my.data.reputationAttributes.items[5].value.stringValue="-"
      my.data.reputationAttributes.items[12].value.booleanValue = false
      my.data.reputationAttributes.items[13].value.booleanValue = false
      my.data.reputationAttributes.items[14].value.booleanValue = false
      my.data.reputationAttributes.items[15].value.booleanValue = false
    }
  }

  my.checkReputation1 = () => {
    const value = my?.data?.reputationAttributes?.items[0]?.value?.stringValue || ""
    return !!value && value.length <= 500
  }

  my.checkReputation4 = () => {
    const value = my?.data?.reputationAttributes?.items[3]?.value?.stringValue || ""
    return !value || value.length <= 1000
  }

  my.checkReputation6 = () => {
    const value = my?.data?.reputationAttributes?.items[5]?.value?.stringValue || ""
    return !value  ||  value.length <= 250
  }

  my.checkReputation8 = () => {
    const value = String(my?.data?.reputationAttributes?.items[6]?.value?.longValue || "")
    return !value || value.length <= 12
  }

  my.checkReputation10 = () => {
    const value = my?.data?.reputationAttributes?.items[8]?.value?.stringValue || ""
    return !value || value.length <= 250
  }

  my.checkReputation11 = () => {
    const value = my?.data?.reputationAttributes?.items[9]?.value?.stringValue || ""
    return !value || value.length <= 250
  }

  my.checkReputation12 = () => {
    const value = my?.data?.reputationAttributes?.items[10]?.value?.stringValue || ""
    return !value || value.length <= 250
  }

  my.onAddLicense = (i) => {
    my.data.activityTypes[i].licenses = my.data.activityTypes[i].licenses || []
    my.data.activityTypes[i].licenses.push({...EMPTY_LICENSE})
  }

  my.onAddActivity = () => {
    my.data.activityTypes = my.data.activityTypes || []
    my.data.activityTypes.push({
      activity: '',
      isLicensed: false,
      licenses: [{...EMPTY_LICENSE}],
    })
  }

  my.onRemoveActivity = (i) => {
    my.data.activityTypes.splice(i, 1)
  }

  my.onRemoveLicense = (i, n) => {
    my.data.activityTypes[i].licenses.splice(n, 1)
  }

  my.onChangeTermless = (i, n) => {
    my.data.activityTypes[i].licenses[n].expiredDate = null
  }

  my.onChangeField = (license, fieldName, i, n) => {
    const value = license[fieldName]
    const isActivities = fieldName === 'activities'

    let selectedLicense = license.isShowLicensesRef
      ? my.licensesRef.find(item => isActivities
          ? item.activities === license.activities
          : item.activities === my.selectedActivities
        )
      : my.licensesList.find(item => isActivities
          ? item.number === license.number
          : item.activities === my.selectedActivities
        )

    if (selectedLicense) {
      selectedLicense.isShowLicensesRef = license.isShowLicensesRef
      if (isActivities) {
        my.selectedActivities = value
        my.data.activityTypes[i].licenses[n] = { ...selectedLicense }
      } else {
        my.data.activityTypes[i].licenses[n] = {
          ...my.data.activityTypes[i].licenses[n],
          [fieldName]: value,
        }
        checkIsEdited(my.data.activityTypes[i].licenses[n], selectedLicense)
        checkUpdateTime(my.data.activityTypes[i].licenses[n])
      }
    }
  }

  my.onShowLicensesRef = (isShowLicensesRef, i, n) => {
    if (isShowLicensesRef) {
      my.data.activityTypes[i].licenses[n].isShowLicensesRef = isShowLicensesRef
    } else {
      delete my.data.activityTypes[i].licenses[n].isShowLicensesRef
    }
  }

  my.onChangeActivity = (activity, i) => {
    my.data.activityTypes[i].activity = activity
  }

  my.onChangeIsLicensed = (isLicensed, i) => {
    my.data.activityTypes[i].isLicensed = isLicensed
  }
}]