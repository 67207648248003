import './styles.css'

export default ['$scope', '$modalInstanse', '$modalParams', function ($scope, $modalInstanse, $modalParams) {
  const my = this

  my.header = $modalParams.header
  my.model = angular.copy($modalParams.model)
  my.code = $modalParams?.code
  my.ref = $modalParams?.ref
  my.readonly = $modalParams.readonly

  my.state = {
    product: my.code,
  }

  my.PRODUCTS = [
    { name: '«Торговый Эквайринг»', type: 'tradeAcquiring'},
  ]

  my.closeOk = () => my.code
    ? $modalInstanse.close({code: my.code, value: my.fixModelOnSave(my.model)})
    : $modalInstanse.close()

  my.fixModelOnSave = (value) => {
    if (!value.enabled) {
      delete value.rkoRefundPeriodForTransactions
      delete value.equipment
      delete value.omrConnection
      delete value.electronicTerminal
      delete value.acquiringTariff
    }
    if (value.electronicTerminal !== 'tradeMagOneAddress') {
      delete value.oneTerminal
    }
    if (value.electronicTerminal !== 'tradeAnyMagAnyAddress') {
      delete value.severalTerminals
    }
    if (value.acquiringTariff !== 'industryTariff') {
      delete value.acquiringActivity
    }
    return value
  }

  my.closeCancel = () => $modalInstanse.close()

  my.setProduct = (value) => {
    my.code = value
    my.header = my.PRODUCTS.find(item => item?.type === value).name
    my.model = {
      equipment: 'electronicTerminalsOfTheBank',
      omrConnection: false,
      electronicTerminal: 'tradeMagOneAddress',
    }
  }

  my.isAvailableProduct = (item) => !my.readonly

  my.addressHouseIsInvalid = function (address) {
    const addressCountry = (address || {}).country
    if (!addressCountry) {
      return false
    }
    const addressHouse = (address || {}).house
    if (!addressHouse) {
      return true
    }
    return false
  }

  const getValue = (
    items,
    attributeId,
    key = 'keyValue'
  ) => items.find(item => item.attributeId === attributeId)?.value[key] || null

  my.onAddTradeAcquiringTerminal = () => {
    my.model.severalTerminals = my.model.severalTerminals || []
    my.model.severalTerminals.push({})
  }

  $scope.$watch('my.model.electronicTerminal', function (newElectronicTerminal) {
    if (newElectronicTerminal === 'tradeAnyMagAnyAddress') {
      const severalTerminals = my.model.severalTerminals || []
      if (severalTerminals.length <= 0) {
        my.onAddTradeAcquiringTerminal()
        my.onAddTradeAcquiringTerminal()
      }
    }
  })

}]