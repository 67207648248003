var tabAgentCtrl = ['$scope', '$q', '$http', 'fzSvcEs', function ($scope, $q, $http, fzSvcEs) {
    var my = this;

    my.agentLogins = [];
    my.agentCurator = null;
    my.billingId = null;
    my.agentContracts = [];
    my.bankSigners = [];
    my.agentSigners = [];
    my.actYear = new Date().getFullYear();
    my.actMonth = String(new Date().getMonth() + 1).padStart(2, '0');

    function loadRefs() {
        return $q.all([
            fzSvcEs.search('crm', 'bankSignersBilling', '', 1000).then(function (res) { my.bankSigners = res.hits; }),
            fzSvcEs.search('crm', 'relation', `relationTypeRefId:"signer" AND rightId:"${my.companyId}"`, 1000).then(function (res) {
                my.agentSigners = res.hits.map(x => ({ relationId: x._id, personId: x._source.leftId, name: x._id }));
                for(let s of my.agentSigners) {
                    fzSvcEs.load('crm', 'person', s.personId).then(function (person) {
                        s.name = person.displayName;
                    });
                }
            })
        ]);
    };

    function reloadContracts() {
        return fzSvcEs.search('crm', 'contract', `companyId:${my.companyId || 'NONE'}`, 10000).then(function (res) {
            my.agentContracts = res.hits;
            if(my.billingId) {
                var ds = [];
                for(let c of my.agentContracts) {
                    for(let s of c._source.billingSchemas) {
                        ds.push($http.get(`/api/bg-agent-billing/agent/${my.billingId}/schema/${s.schemaId}`).then(function (res) {
                            s.data = res.data;
                        }));
                    }
                }
                return $q.all(ds);
            }
        });
    };

    $scope.$watch('companyId', function () {
        if (!$scope.companyId) return;
        my.companyId = $scope.companyId;

        loadRefs().then(function () {
            fzSvcEs.load('crm', 'company', my.companyId).then(function (company) {
                my.agentLogins = company.agentLogins || [];
                my.billingId = company.billingId;
                my.agentCurator = {
                    login: company.agentManagerLogin
                };

                if(my.agentCurator.login) {
                    $http.get('/api/user-profile/user/' + my.agentCurator.login.toLowerCase()).then(function (profile) {
                        my.agentCurator.lastName = profile.data.lastName;
                        my.agentCurator.firstName = profile.data.firstName;
                    });
                }
            });

            reloadContracts();
        });
    });

    //=============================================================================================
    // Логины

    my.linkLogin = function () {
        my.editLogin = {
            login: null,
            displayName: null
        };
    };

    my.cancelLogin = function () {
        my.editLogin = null;
    };

    my.acceptLogin = function () {
        if (my.agentLogins.indexOf(my.editLogin.login) >= 0) {
            alert('Этот логин уже привязан к данному клиенту');
            return;
        }

        var data = {
            agentLogins: my.agentLogins.slice(0) // clone array
        };
        data.agentLogins.push(my.editLogin.login);

        fzSvcEs.update('crm', 'company', my.companyId, data).then(function () {
            my.agentLogins.push(my.editLogin.login);
            my.editLogin = null;
        }).catch(function () {
            alert('Не удалось сохранить данные');
        });
    };

    my.unlinkLogin = function (login) {
        my.loginToUnlink = login;
    };

    my.cancelUnlinkLogin = function () {
        my.loginToUnlink = null;
    };

    my.acceptUnlinkLogin = function () {
        var data = {
            agentLogins: my.agentLogins.slice(0) // clone array
        };
        var i = data.agentLogins.indexOf(my.loginToUnlink);
        data.agentLogins.splice(i, 1);

        fzSvcEs.update('crm', 'company', my.companyId, data).then(function () {
            my.agentLogins = data.agentLogins;
            my.loginToUnlink = null;
        }).catch(function () {
            alert('Не удалось сохранить данные');
        });
    };

    // Регистрация агента в биллинге

    my.billingReg = function () {
        $http.post('/api/bg-agent-billing/agent', {}).then(function (resp) {
            if (!resp.data.id) {
                alert('Не удалось зарегистрировать агента в биллинговой системе');
                return;
            }
            return fzSvcEs.update('crm', 'company', my.companyId, { billingId: resp.data.id }).then(function () {
                my.billingId = resp.data.id;
            });
        });
    };

    //=============================================================================================
    // Куратор

    my.setCurator = function () {
        my.editCuratorLogin = {
            login: my.agentCurator ? my.agentCurator.login : ''
        };
    };

    my.cancelSetCurator = function () {
        my.editCuratorLogin = null;
    };

    my.acceptSetCurator = function () {
        var data = {
            agentManagerLogin: my.editCuratorLogin.login
        };

        fzSvcEs.update('crm', 'company', my.companyId, data).then(function () {
            my.agentCurator = my.editCuratorLogin;
            my.editCuratorLogin = null;
            if(my.agentCurator.login) {
                $http.get('/api/user-profile/user/' + my.agentCurator.login.toLowerCase()).then(function (profile) {
                    my.agentCurator.lastName = profile.data.lastName;
                    my.agentCurator.firstName = profile.data.firstName;
                });
            }
        }).catch(function () {
            alert('Не удалось сохранить данные');
        });
    };

    //=============================================================================================
    // Договора

    // Договор - Править
    my.modifyAgentContract = function (x) {
        x.editAgentContract = JSON.parse(JSON.stringify(x._source)); // clone object
    };

    my.cancelModifyAgentContract = function (x) {
        x.editAgentContract = null;
    };

    my.confirmModifyAgentContract = function (x) {
        var data = JSON.parse(JSON.stringify(x.editAgentContract)); // clone object
        data.billingSchemas = data.billingSchemas.map(b => ({productTypeRefId: b.productTypeRefId, schemaId: b.schemaId})); // remove view-only data

        fzSvcEs.update('crm', 'contract', x._id, data).then(function (res) {
            x._source = x.editAgentContract;
            x.editAgentContract = null;
        });
    };

    // Договор - Удалить
    my.deleteAgentContract = function (x) {
        x.showConfirmDeleteAgentContract = true;
    };

    my.cancelDeleteAgentContract = function (x) {
        x.showConfirmDeleteAgentContract = false;
    };

    my.confirmDeleteAgentContract = function (x) {
        fzSvcEs.remove('crm', 'contract', x._id).then(function () {
            var i = my.agentContracts.indexOf(x);
            my.agentContracts.splice(i, 1);
            x.showConfirmDeleteAgentContract = false;
        });
    };

    // Договор - Добавить
    my.createAgentContract = function () {
        my.newAgentContract = {
            companyId: my.companyId,
            number: null,
            date: null,
            signerRelationId: null,
            bankSignerId: null,
            billingSchemas: []
        };
    };

    my.cancelCreateAgentContract = function () {
        my.newAgentContract = null;
    };

    my.confirmCreateAgentContract = function () {
        fzSvcEs.create('crm', 'contract', my.newAgentContract).then(function (res) {
            my.agentContracts.push({ _id: res._id, _source: my.newAgentContract });
            my.newAgentContract = null;
        });
    };

    // Схема расчёта

    my.createBillingScheme = function (contract) {
        contract.newScheme = {
            productType: 'BG',
            validFrom: null,
            validTo: null,
            schema: {
                includeVAT: false,
                standardRates: [],
                totalRates: [],
                excessRate: null,
                decreaseRate: null
            }
        };
    };

    my.cancelCreateBillingScheme = function (contract) {
        contract.newScheme = null;
    };

    my.confirmCreateBillingScheme = function (contract) {
        $http.post(`/api/bg-agent-billing/agent/${my.billingId}/schema`, contract.newScheme).then(function (res) {
            var s = {
                schemaId: res.data.id,
                productTypeRefId: 'BG',
            };

            var data = {
                billingSchemas: contract._source.billingSchemas.map(b => ({productTypeRefId: b.productTypeRefId, schemaId: b.schemaId}))
            };
            data.billingSchemas.push(s);

            return fzSvcEs.update('crm', 'contract', contract._id, data).then(function () {
                s.data = contract.newScheme;
                contract.newScheme = null;
                contract._source.billingSchemas.push(s);
            });
        });
    };

    my.addStandardRate = function (s) {
        if (!s.schema.standardRates) s.schema.standardRates = [];
        s.schema.standardRates.push({ fromAmount: null, rate: null });
    };

    my.deleteStandardRate = function (s, i) {
        s.schema.standardRates.splice(i, 1);
    };

    my.addTotalRate = function (s) {
        if (!s.schema.totalRates) s.schema.totalRates = [];
        s.schema.totalRates.push({ fromCount: null, rate: null });
    };

    my.deleteTotalRate = function (s, i) {
        s.schema.totalRates.splice(i, 1);
    };

    my.addTotalRateByAmount = function (s) {
        if (!s.schema.totalRatesByAmount) s.schema.totalRatesByAmount = [];
        s.schema.totalRatesByAmount.push({ fromAmount: null, rate: null });
    };

    my.deleteTotalRateByAmount = function (s, i) {
        s.schema.totalRatesByAmount.splice(i, 1);
    };

    // Схема - Править

    my.modifyScheme = function (s) {
        s.editItem = JSON.parse(JSON.stringify(s.data)); // clone object
    };

    my.cancelModifyScheme = function (s) {
        s.editItem = null;
    };

    my.confirmModifyScheme = function (s) {
        $http.post(`/api/bg-agent-billing/agent/${my.billingId}/schema/${s.schemaId}`, s.editItem).then(function (res) {
            s.data = res.data;
            s.editItem = null;
        }).catch(function () {
            alert('Не удалось сохранить данные');
        });
    };

    // Схема - Удалить
    my.deleteScheme = function (s) {
        s.showConfirmDeleteItem = true;
    };

    my.cancelDeleteScheme = function (s) {
        s.showConfirmDeleteItem = undefined;
    };

    my.confirmDeleteScheme = function (x, s) {
        $http.delete(`/api/bg-agent-billing/agent/${my.billingId}/schema/${s.schemaId}`).then(function (res) {
            var data = {
                billingSchemas: x._source.billingSchemas.map(b => ({productTypeRefId: b.productTypeRefId, schemaId: b.schemaId})).filter(b => b.schemaId !== s.schemaId)
            };

            fzSvcEs.update('crm', 'contract', x._id, data).then(function (res) {
                var i = x._source.billingSchemas.indexOf(s);
                x._source.billingSchemas.splice(i, 1);
                s.showConfirmDeleteItem = undefined;
            });
            
        });
    };
}];

export default tabAgentCtrl;
