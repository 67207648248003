import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

const ctrl = ['$scope', 'fzModal', 'fzSvcShareholder', function ($scope, fzModal, fzSvcShareholder) {
    const my = this;

    my.state = {
        itemsInvalid: [],
        sharesInvalid: false
    };

    my.isInvalid = function (x) {
        const index = $scope.model.data.indexOf(x);
        return my.state.itemsInvalid[index];
    };

    my.isInvalidShares = function () {
        return my.state.sharesInvalid;
    };

    my.onButtonAddClickHandler = function () {
        fzModal.open({
            windowClass: 'fz-c-founders__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                item: null,
                personItemTemplate: angular.copy($scope.model.personItemTemplate),
                config: $scope.model.config
            }
        }).closed.then(result => {
            if (!result) return;
            if (!$scope.model.data) $scope.model.data = [];
            $scope.model.data.push(result);
        });
    };

    my.onButtonEditClickHandler = function (item) {
        fzModal.open({
            windowClass: 'fz-c-founders__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                item: item,
                personsRef: $scope.ref,
                ref: $scope.model.ref || {},
                config: $scope.model.config
            }
        }).closed.then(result => {
            if (!result) return;

            const index = $scope.model.data.indexOf(item);
            if (index > -1) $scope.model.data[index] = result;
        });
    };

    my.onButtonDeleteClickHandler = function (item) {
        const index = $scope.model.data.indexOf(item);
        $scope.model.data.splice(index, 1);
    };

    my.validate = function () {
        const x = $scope.model;
        if (!x || !x.data) return true;
        if ($scope.model.config.required && !x.data.length) return false;
        my.state.itemsInvalid = x.data.map((_, idx) => !fzSvcShareholder.validateShareholder(x, idx));
        my.state.sharesInvalid = !fzSvcShareholder.validateShares(x);
        return !my.state.itemsInvalid.some(x => x) && !my.state.sharesInvalid;
    };

    function updateRef(result) {
        const person = result.person;
        if (!person) return;
        if (!$scope.ref) $scope.ref = []; //return;
        if (!person.lastName || !person.firstName || !person.secondName || !person.number) return;

        const newRefItem = {
            displayName: `${person.lastName} ${person.firstName} ${person.secondName} (паспорт ${person.series} ${person.number})`,
            key: `${person.lastName}#${person.firstName}#${person.secondName}#${person.number}`,
            data: angular.copy(person)
        }
        newRefItem.data.personAttributes = angular.copy(result.personAttributes);
        newRefItem.data.relationAttributes = angular.copy(result.relationAttributes);

        const refItem = $scope.ref.find(x => x.key === newRefItem.key);
        if (!refItem) {
            $scope.ref.push(newRefItem);
        } else {
            for (let key of Object.keys(newRefItem.data)) {
                if (key !== 'personAttributes' && key !== 'relationAttributes') {
                    refItem.data[key] = newRefItem.data[key];
                }
            }
            mergeAttributes('personAttributes', refItem.data, newRefItem.data);
            mergeAttributes('relationAttributes', refItem.data, newRefItem.data);
        }
    };

    function mergeAttributes(name, destData, srcData) {
        if (srcData[name] && srcData[name].items && srcData[name].items.length) {
            if (!destData[name]) destData[name] = {};
            if (!destData[name].items) destData[name].items = [];

            const destMap = new Map();
            destData[name].items.forEach(attr => destMap.set(attr.attributeId, attr));
            srcData[name].items.forEach(attr => {
                if (destMap.has(attr.attributeId)) {
                    const destAttr = destMap.get(attr.attributeId);
                    destAttr.value = attr.value;
                    destAttr.values = attr.values;
                } else {
                    destData[name].items.push(attr);
                }
            });
        }
    };

    $scope.$watchCollection('model.data', function (newValues, oldValues) {
        if (!newValues || !newValues.length) return;
       // newValues.forEach(x => updateRef(x));
    });
}];

export default ctrl;
