import  { plural } from '../../../utils'
const ctrl = ['$scope', function ($scope) {
  const my = this;

  my.state = {
    initCommission: null,
    creditDuranceDays: "дн",
    productType: null
    };

  $scope.$watch('model', function (model) {
        if (my.state.initCommission || !model) {
            return;
        }
        if (model.creditCommission) {
            my.state.initCommission = model.creditCommission;
        }
        if (model.creditDurance) {
          my.state.creditDuranceDays = plural(model.creditDurance, "день", "дня", "дней")
        }
            switch (model.creditType) {
                case "SME_CRE_KIK_INH_RKO":
                    my.state.productType = "Кредит аннуитет";
                    break;

                case "SME_CRE_KIK_INH_RKO_IND":
                    my.state.productType = "Кредит индивидуальный график";
                    break;

                default:
                    my.state.productType = "???";
                    break;
            }

  });

  $scope.$watch('model.agentCommission', function (agentCommission) {
      if (agentCommission < 0) {
          $scope.model.agentCommission = 0;
      } else if ($scope.model != null && $scope.model.creditCommission !== null) {
          $scope.model.creditCommission = my.state.initCommission + agentCommission;
      }
    });
}];

export default ctrl;