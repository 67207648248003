import angular from "angular";
import cAddressesEditTab from "./cAddressesEditTab";
import cAddressesViewTab from "./cAddressesViewTab";

const moduleName = "com.farzoom.lime.cAddresses";

angular
  .module(moduleName, [
    "com.farzoom.common.cAttributes",
    "com.farzoom.common.fzAddress",
    "com.farzoom.common.fzModal",
    "com.farzoom.common.fzTaskFormParts",
    "ui.mask",
  ])
  .directive("fzCAddressesEditTab", cAddressesEditTab)
  .directive("fzCAddressesViewTab", cAddressesViewTab);

export default moduleName;
