import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

const ctrl = ['$scope', 'fzSvcAttribute', 'fzModal', function ($scope, fzSvcAttribute, fzModal) {
    const my = this;

    my.attrHelper = null;

    my.onButtonClickHandler = function () {
        fzModal.open({
            windowClass: 'fz-c-attributes__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            scope: {
                modalTitle: $scope.model.attributes ? $scope.model.attributes.header : $scope.model.header || $scope.header
            },
            params: {
                model: $scope.model
            }
        }).closed.then(result => {
            if (!result) {
                validateModel(); // Validate even if cancelled
                return;
            }
            $scope.model = result;
        });
    };

    let isValid = true;

    function validateModel() {
        isValid = fzSvcAttribute.validateGroup($scope.model);
    }

    $scope.$watch('model', validateModel);

    my.validate = function () {
        return isValid;
    };
}];

export default ctrl;