import "./styles.css";
import i18n from "../../i18n";

export default [
  "$scope",
  "$modalInstanse",
  "$modalParams",
  function ($scope, $modalInstanse, $modalParams) {
    const my = this;
    $scope.i18n = i18n;

    my.data = angular.copy($modalParams.item);
    my.config = angular.copy($modalParams.config);
    my.readonly = $modalParams.readonly;

    my.closeOk = function () {
      $modalInstanse.close(my.data);
    };

    my.closeCancel = function () {
      $modalInstanse.close();
    };

    my.state = {
      addressTypeRefId: null,
    };

    $scope.$watch(
      "my.data.address.addressTypeRefId",
      function (addressTypeRefId) {
        if (!addressTypeRefId) return;
        my.state.addressTypeRefId = addressTypeRefId;
      }
    );

    $scope.$watch("my.data.address", function (address) {
      if (!address) return;
      if (!address.addressTypeRefId)
        address.addressTypeRefId = my.state.addressTypeRefId;
    });

    my.showAddressOwnType = function (addressTypeRefId) {
      const config = (my.config || {})[`${addressTypeRefId}OwnType`];
      return !config || !!config.show;
    };
  },
];
