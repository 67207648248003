import tmpl from './template.html';
import './styles.css';

const cmp = function () {
    return {
        restrict: 'E',
        transclude: true,
        require: '^fzlCTabs',
        scope: {
            header: '@',
            headerBadge: '@?',
            disabled: '@?',
            active: '@?',
            class: '@?',
            order: '@?'
        },
        link: function($scope, $element, $attrs, tabs){
            const index = $element.closest('fzl-c-tabs').find('fzl-c-tab').index($element);
            tabs.addTab($scope, index);
        },
        template: tmpl,
        controllerAs: 'my'
    };
};

export default cmp;
