import modalTmpl from '../cAddressesEditTab/modal/template.html';
import modalCtrl from '../cAddressesEditTab/modal/controller';
import i18n from '../i18n';

const ctrl = ['$scope', 'fzModal', function ($scope, fzModal) {
    const my = this;
    $scope.i18n = i18n;

    my.onButtonViewClickHandler = function (item) {
        fzModal.open({
            windowClass: 'fz-c-addresses__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                item: item,
                config: ($scope.model || {}).config || {},
                readonly: true
            }
        });
    };

    my.showAddressOwnType = function (addressTypeRefId) {
        const config = (($scope.model || {}).config || {})[`${addressTypeRefId}OwnType`];
        return !config || !!config.show;
    }
}];

export default ctrl;
