var ctrl = ['$scope', 'camunda', function ($scope, camunda) {
    var my = this;
    my.companyId = $scope.companyId;

    function getPermissions(userGroups) {
        var userGroupsDict = {};
        for (var i = 0; i < userGroups.length; ++i) {
            userGroupsDict[userGroups[i].id] = true;
        }

        return {
            showBgAgent: !!userGroupsDict.MOOS_BG_AGENT
        };
    }

    function initTab(permissions) {
        my.permissions = permissions;
    }

    camunda.getCurrentUserGroups().then(getPermissions).then(initTab);
}];

export default ctrl;
