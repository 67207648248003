import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

const ctrl = ['$scope', 'fzModal', 'fzSvcAttribute', function ($scope, fzModal, fzSvcAttribute) {
  const my = this;

  my.state = {
    itemsInvalid: []
  };

  my.isInvalid = function (x) {
    const index = $scope.model.data.indexOf(x);
    return my.state.itemsInvalid[index];
  };

  my.onButtonAddClickHandler = function () {
    fzModal.open({
      windowClass: 'fz-c-founders__modal',
      template: modalTmpl,
      controller: modalCtrl,
      controllerAs: 'my',
      params: {
        item: null,
        personsRef: $scope.ref,
        ref: $scope.model.ref || {},
        personItemTemplate: angular.copy($scope.model.personItemTemplate),
        config: $scope.model.config,
        group: $scope.group || ''
      }
    }).closed.then(result => {
      if (!result) return;
      if (!$scope.model.data) $scope.model.data = [];
      $scope.model.data.push(result);
    });
  };

  my.onButtonEditClickHandler = function (item) {
    fzModal.open({
      windowClass: 'fz-c-founders__modal',
      template: modalTmpl,
      controller: modalCtrl,
      controllerAs: 'my',
      params: {
        item: item,
        personsRef: $scope.ref,
        ref: $scope.model.ref || {},
        config: $scope.model.config,
        group: $scope.group || ''
      }
    }).closed.then(result => {
      if (!result) return;

      const index = $scope.model.data.indexOf(item);
      if (index > -1) $scope.model.data[index] = result;
    });
  };

  my.onButtonDeleteClickHandler = function (item) {
    const index = $scope.model.data.indexOf(item);
    $scope.model.data.splice(index, 1);
  };

  my.validate = function () {
    const x = $scope.model;
    if (!x || !x.data || !x.data.length) return false;
    my.state.itemsInvalid = x.data.map(d => !validateItem(d));
    const validShares = my.validateShares(x.data);
    return !my.state.itemsInvalid.some(x => x) && validShares;
  };

  const getValue = (items, attributeId, key = 'keyValue') => {
    return items.find(item => item.attributeId === attributeId)?.value[key] || null
  }

  // ДУЛ, если выбрано "гражданство РФ"
  my.getPersonIdentityDocRf = (p) => {
    const items = p?.personAttributes?.items || p?.person?.personAttributes?.items || []
    return getValue(items, 'person.identityDocRf')
  }

  function validateItem(x) {
    if (x.person) return validatePerson(x);
    else return validateCompany(x);
  };

  function validateCompany(item) {
    if (!$scope.model) return true;
    const cfg = $scope.model.config || {};
    const required = field => !!cfg[field] && !!cfg[field].required;

    return !!item && !!item.company && (!!item.company.id || (!!item.company.INN && !!item.company.OGRN)) &&
      (!required('sharePercent') || !!item.sharePercent) &&
      (!required('shareSum') || !!item.shareSum);
  };

  function validatePerson(item) {
    if (!item || !item.person) return false;
    const p = item.person;
    const cfg = $scope.model.config || {};
    const required = field => !!cfg[field] && !!cfg[field].required;
    const show = field => !!cfg[field] && !!cfg[field].show;

    // TO DO: требует дебага, похоже на некорректную логику
    const isRf = !cfg.citizenship || !cfg.citizenship.show || p.citizenship === (cfg.citizenship.rfCode || 'rf');

    const validIssuedDate = (p) => {
      if (!cfg.identityDocument.issueDateHide && !p.issuedDate) {
        return false
      }
      if (my.getPersonIdentityDocRf(item) !== 'PersDoc_Passport') {
        return true
      }
      const getAge = (date) => {
        const today = new Date()
        const birthDate = new Date(date)
        let age = today.getFullYear() - birthDate.getFullYear()
        const m = today.getMonth() - birthDate.getMonth()
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--
        }
        return age
      }
      const getTimeStamp = (date) => new Date(date).getTime()
      const resetTime = (date) => {
        let result = new Date(date)
        result.setHours(0)
        result.setMinutes(0)
        result.setSeconds(0)
        result.setMilliseconds(0)
        return result
      }
      const getDiffDays = (date1, date2) => date1 && date2
        ? (new Date(date1).getTime() - new Date(date2).getTime()) / (1000 * 3600 * 24)
        : 0
      const birthDate = resetTime(new Date(p?.birthDate || p?.person?.birthDate))
      const issuedDate = resetTime(new Date(p?.issuedDate || p?.person?.issuedDate))
      const birthFullYear = new Date(birthDate).getFullYear()
      const age = getAge(birthDate)
    
      const getBirthDateByGivenAge = (givenAge) => new Date(new Date(birthDate).setFullYear(birthFullYear + givenAge))
      const birthDate14 = getBirthDateByGivenAge(14)
      const birthDate20 = getBirthDateByGivenAge(20)
      const birthDate45 = getBirthDateByGivenAge(45)
    
      const getDaysAfterGivenAge = (givenAge) => getDiffDays(new Date(), getBirthDateByGivenAge(givenAge))
      const daysAfter20 = getDaysAfterGivenAge(20)
      const daysAfter45 = getDaysAfterGivenAge(45)
    
      const issuedDateTS = getTimeStamp(issuedDate)
      const birthDateTS14 = getTimeStamp(birthDate14)
      const birthDateTS20 = getTimeStamp(birthDate20)
      const birthDateTS45 = getTimeStamp(birthDate45)
    
      if (age >= 14 && age < 20) { return issuedDateTS >= birthDateTS14 }
      if (age === 20 && daysAfter20 <= 90) { return issuedDateTS >= birthDateTS14 }
      if (age === 20 && daysAfter20 > 90) { return issuedDateTS >= birthDateTS20 }
      if (age > 20 && age < 45) { return issuedDateTS >= birthDateTS20 }
      if (age === 45 && daysAfter45 <= 90) { return issuedDateTS >= birthDateTS20 }
      if (age === 45 && daysAfter45 > 90) { return issuedDateTS >= birthDateTS45 }
      if (age > 45) { return issuedDateTS >= birthDateTS45 }
    
      return false
    }

    return !!p.lastName && !!p.firstName && !!p.secondName &&
      (!required('inn') || !!p.inn) &&
      (!required('birthDate') || !!p.birthDate) &&
      (!required('birthPlace') || !!p.birthPlace) &&
      (!required('registrationAddress') || !!p.registrationAddress) &&
      (!required('actualAddress') || !!p.actualAddress) &&
      (!required('phone') || (p.contacts || []).some(x => !!x.phone)) &&
      (!required('email') || (p.contacts || []).some(x => !!x.email)) &&
      (!required('position') || !!p.position) &&
      (!required('authorizingDoc') || !!p.authorizingDoc) &&
      (!required('citizenship') || !!p.citizenship) &&
      (!required('otherCitizenship') || p.citizenship !== 'other' || !!p.otherCitizenship) &&
      (!required('identityDocument') || (!!p.number && (!isRf || (!!p.series && !!p.issuedDate && !!p.issuingAuthorityCode && !!p.issuingAuthority)))) &&
      (!show('inn') || !p.inn || p.inn.length == 12) &&
      (!p.issuedDate || checkCurrDate(p.issuedDate)) &&
      (!p.birthDate || (checkCurrDate(p.birthDate) && checkMinAge(p.birthDate, item.personAttributes))) &&
      (!required('sharePercent') || !!item.sharePercent || item.sharePercent === 0) &&
      (!show('sharePercent') || !item.sharePercent || (item.sharePercent >= 0 && item.sharePercent <= 100)) &&
      (!required('shareSum') || (!!item.shareSum !== 0)) &&
      fzSvcAttribute.validateGroup(item.personAttributes) &&
      validIssuedDate(p)
    ;
  };

  function checkMinAge(birthDate, personAttributes) {
    const items = personAttributes?.items || $scope?.model?.data[0]?.personAttributes?.items || []
    const isRF = items.find(item => item.attributeId === 'person.citizenship')?.value?.keyValue === 'rf'
    if (isRF) return true;
    let minAge = (($scope.model.config || {}).age || {}).minimalAge || 0;
    if (minAge <= 0) return true;
    const now = new Date();
    const today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
    const bDate = new Date(birthDate + 'T00:00:00Z');
    const age = new Date((today - bDate)).getFullYear() - 1970;

    return age >= minAge;
  }

  function checkCurrDate(checkDate) {
    const now = new Date();
    let y = now.getFullYear();
    let m = now.getMonth() + 1;
    let d = now.getDate();
    let today = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}` ;
    return checkDate <= today;
  }

  my.validateShares = function (persons) {
    if (!persons || !persons.length) return true;
    const totalShares = persons
      .map(p => p.sharePercent)
      .reduce((sum, share) => (sum || 0) + (share || 0));
    return (totalShares >= 0 && totalShares <= 100.00);
  }

  function updateRef(result) {
    const person = result.person;
    if (!person) return;
    if (!$scope.ref) $scope.ref = []; //return;
    if (!person.lastName || !person.firstName || !person.secondName || !person.number) return;

    const newRefItem = {
      displayName: `${person.lastName} ${person.firstName} ${person.secondName} (паспорт ${person.series} ${person.number})`,
      key: `${person.lastName}#${person.firstName}#${person.secondName}#${person.number}`,
      data: angular.copy(person)
    }
    newRefItem.data.personAttributes = angular.copy(result.personAttributes);
    newRefItem.data.relationAttributes = angular.copy(result.relationAttributes);

    const refItem = $scope.ref.find(x => x.key === newRefItem.key);
    if (!refItem) {
      $scope.ref.push(newRefItem);
    } else {
      for (let key of Object.keys(newRefItem.data)) {
        if (key !== 'personAttributes' && key !== 'relationAttributes') {
          refItem.data[key] = newRefItem.data[key];
        }
      }
      mergeAttributes('personAttributes', refItem.data, newRefItem.data);
      mergeAttributes('relationAttributes', refItem.data, newRefItem.data);
    }
  };

  function mergeAttributes(name, destData, srcData) {
    if (srcData[name] && srcData[name].items && srcData[name].items.length) {
      if (!destData[name]) destData[name] = {};
      if (!destData[name].items) destData[name].items = [];

      const destMap = new Map();
      destData[name].items.forEach(attr => destMap.set(attr.attributeId, attr));
      srcData[name].items.forEach(attr => {
        if (destMap.has(attr.attributeId)) {
          const destAttr = destMap.get(attr.attributeId);
          destAttr.value = attr.value;
          destAttr.values = attr.values;
        } else {
          destData[name].items.push(attr);
        }
      });
    }
  };

  $scope.$watchCollection('model.data', function (newValues, oldValues) {
    if (!newValues || !newValues.length) return;
    newValues.forEach(x => updateRef(x));
  });
}];

export default ctrl;
