import angular from 'angular';
import fzlCOrder from './fzlCOrder';

const moduleName = 'com.farzoom.lime.cOrder';

angular.module(moduleName, [
        'com.farzoom.common.fzAddress',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzlCOrder', fzlCOrder)

export default moduleName;
