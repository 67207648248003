import angular from 'angular';
import fzlCProductsToConnect from './fzlCProductsToConnect';

const moduleName = 'com.farzoom.lime.cProduct';

angular.module(moduleName, [
    'ui.mask',
    'com.farzoom.common.cAttributes',
    'com.farzoom.common.fzModal',
    'com.farzoom.common.fzSelectAddress',
    'com.farzoom.common.fzTaskFormParts',
    'com.farzoom.common.fzToDate',
    'com.farzoom.common.fzToMoney',
    'com.farzoom.common.fzToNumber',
    'com.farzoom.common.fzTaskFormParts'
  ])
  .directive('fzlCProductsToConnect', fzlCProductsToConnect)
export default moduleName;
