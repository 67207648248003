import angular from 'angular';
import cKkParamsViewTab from './cKkParamsViewTab';
import cKkParamsEditTab from './cKkParamsEditTab';

const moduleName = 'com.farzoom.lime.cKkParams';

angular.module(moduleName, [
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzSvcNumber',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzlCKkParamViewTab', cKkParamsViewTab)
    .directive('fzlCKkParamEditTab', cKkParamsEditTab);

export default moduleName;
