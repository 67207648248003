import angular from 'angular';
import fzlCRejectReasons from './fzlCRejectReasons';

const moduleName = 'com.farzoom.common.cRejectReasons';

angular.module(moduleName, [
    'com.farzoom.common.fzModal',
    'com.farzoom.common.fzToMoney',
    'com.farzoom.common.fzToNumber',
    'com.farzoom.common.fzTaskFormParts'
])
    .directive('fzlCRejectReasons', fzlCRejectReasons)

export default moduleName;