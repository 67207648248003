import angular from 'angular';
import fzlCPreApproveKikOffersEditTab from './fzlCPreApproveKikOffersEditTab';

const moduleName = 'com.farzoom.common.fzlCPreApproveKikOffersEditTab';

angular.module(moduleName, [
    'ui.mask',
    'com.farzoom.common.cAttributes',
    'com.farzoom.common.fzModal',
    'com.farzoom.common.fzTaskFormParts'
])
    .directive('fzlCPreApproveKikOffersEditTab', fzlCPreApproveKikOffersEditTab)

export default moduleName;