import modalTmpl from './modal/template.html'
import modalCtrl from './modal/controller'

const ctrl = ['$scope',  'fzModal', 'fzSvcAttribute', function ($scope, fzModal, fzSvcAttribute) {
  const my = this

  my.onButtonAddClickHandler = () => {
    fzModal.open({
      windowClass: 'fz-c-employee__modal',
      template: modalTmpl,
      controller: modalCtrl,
      controllerAs: 'my',
      params: {
        model: angular.copy({}),
        readonly: false,
        ref: $scope?.model?.ref || {},
      }
    }).closed.then(result => {
      if (!result || !result.code || !result.value) {
        return
      }
      $scope.model[result.code] = result.value
    })
  }

  my.onButtonEditTradeAcquiringClickHandler = (item, index) => {
    fzModal.open({
      windowClass: 'fz-c-employee__modal',
      template: modalTmpl,
      controller: modalCtrl,
      controllerAs: 'my',
      params: {
        header: '«Торговый Эквайринг»',
        model: angular.copy($scope.model.tradeAcquiring || {}),
        code: 'tradeAcquiring',
        ref: $scope?.model?.ref || {},
        readonly: !my.isAvailableTradeAcquiring(),
      }
    }).closed.then(result => {
      if (!result || !result.code || !result.value) {
        return
      }
      $scope.model[result.code] = result.value
    })
  }

  my.onButtonDeleteTradeAcquiringClickHandler = () => {
    $scope.model.tradeAcquiring = null
  }

  my.hasAnyProduct = () => {
    return my.hasTradeAcquiring()
  }

  my.validate = () => {
    if ($scope.readonly) {
      return true
    }
    return my.isValidTradeAcquiring()
  }

  my.isValidTradeAcquiring = () => {
    if (!my.hasTradeAcquiring()) {
      return true
    }

    const acquiring = $scope.model.tradeAcquiring
    if (!acquiring.enabled) {
      return false
    }

    if (!acquiring.rkoRefundPeriodForTransactions) {
      return false
    }

    if (!acquiring.equipment) {
      return false
    }

    if (!acquiring.electronicTerminal) {
      return false
    }

    if (acquiring.electronicTerminal==='tradeMagOneAddress') {
      if (!acquiring.oneTerminal.terminalName) {
        return false
      }

      if (!acquiring.oneTerminal.activityType) {
        return false
      }

      if (!acquiring.oneTerminal.actualAddress) {
        return false
      }

      if (!acquiring.oneTerminal.okato) {
        return false
      }

      if (!acquiring.oneTerminal.workPeriod) {
        return false
      }

      if (!acquiring.oneTerminal.count) {
        return false
      }
    }

    if (acquiring.electronicTerminal==='tradeAnyMagAnyAddress') {
      for (const severalTerminal of acquiring.severalTerminals) {
        if (!severalTerminal.terminalName) {
          return false
        }

        if (!severalTerminal.activityType) {
          return false
        }

        if (!severalTerminal.actualAddress) {
          return false
        }

        if (!severalTerminal.contactPersonFullName) {
          return false
        }

        if (!severalTerminal.contactPersonPhone) {
          return false
        }

        if (!severalTerminal.contactPersonEmail) {
          return false
        }

        if (!severalTerminal.count) {
          return false
        }
      }
    }

    if (!acquiring.acquiringTariff) {
        return false
    }

    if (acquiring.acquiringTariff === 'industryTariff' && !acquiring.acquiringActivity ) {
        return false
    }

    return true
  }

  my.hasTradeAcquiring = () => {
    return !!$scope.model?.tradeAcquiring
  }

  my.isAvailableTradeAcquiring = () => {
    return !$scope.readonly
  }

}]

export default ctrl
