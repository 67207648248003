import './styles.css';

import personValidator from '../services/personValidator';

const svc = function ($scope, $modalInstanse, $modalParams, fzSvcAttribute, fzpSvcPersonEdit) {
    const my = this;

    my.closeOk = function () {
        $modalInstanse.close(my.data);
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    my.removeSupervisoryBoardMember = function (x) {
        const index = my.data.supervisoryBoardMembers.indexOf(x);
        my.data.supervisoryBoardMembers.splice(index, 1);
    };

    my.addSupervisoryBoardMember = function () {
        fzpSvcPersonEdit.open(
                angular.copy(($modalParams.itemTemplate || {}).supervisoryBoard || {}),
                (my.config || {}).supervisoryBoard,
                my.ref,
                my.suggestPersons,
                'Член наблюдательного совета',
                my.readonly
            )
            .then(result => {
                if (!result) return;
                if (!my.data) my.data = {};
                if (!my.data.supervisoryBoardMembers) my.data.supervisoryBoardMembers = [];
                my.data.supervisoryBoardMembers.push(result);
            });
    };

    my.editSupervisoryBoardMember = function (item) {
        fzpSvcPersonEdit.open(
                item,
                (my.config || {}).supervisoryBoard,
                my.ref,
                my.suggestPersons,
                'Член наблюдательного совета',
                my.readonly
            )
            .then(result => {
                if (!result) return;
                const index = my.data.supervisoryBoardMembers.indexOf(item);
                my.data.supervisoryBoardMembers[index] = result;
            });
    };

    my.removeCollegialExecutiveBodyMember = function (x) {
        const index = my.data.collegialExecutiveBodyMembers.indexOf(x);
        my.data.collegialExecutiveBodyMembers.splice(index, 1);
    };

    my.addCollegialExecutiveBodyMember = function () {
        fzpSvcPersonEdit.open(
                angular.copy(($modalParams.itemTemplate || {}).collegialExecutiveBody || {}),
                (my.config || {}).collegialExecutiveBody,
                my.ref,
                my.suggestPersons,
                'Член коллегиального исполнительного органа',
                my.readonly
            )
            .then(result => {
                if (!result) return;
                if (!my.data) my.data = {};
                if (!my.data.collegialExecutiveBodyMembers) my.data.collegialExecutiveBodyMembers = [];
                my.data.collegialExecutiveBodyMembers.push(result);
            });
    };

    my.editCollegialExecutiveBodyMember = function (item) {
        fzpSvcPersonEdit.open(
                item,
                (my.config || {}).supervisoryBoard,
                my.ref,
                my.suggestPersons,
                'Член коллегиального исполнительного органа',
                my.readonly
            )
            .then(result => {
                if (!result) return;
                const index = my.data.collegialExecutiveBodyMembers.indexOf(item);
                my.data.collegialExecutiveBodyMembers[index] = result;
            });
    };

    my.isInvalidSupervisoryBoardMember = function (person) {
        return !personValidator.validate(person, (my.config || {}).supervisoryBoard, fzSvcAttribute);
    };

    my.isInvalidCollegialExecutiveBodyMember = function (person) {
        return !personValidator.validate(person, (my.config || {}).collegialExecutiveBody, fzSvcAttribute);
    };

    my.header = $modalParams.header;
    my.config = $modalParams.config;
    my.data = angular.copy($modalParams.data);
    my.ref = $modalParams.ref;
    my.suggestPersons = $modalParams.suggestPersons;
};

svc.$inject = ['$scope', '$modalInstanse', '$modalParams', 'fzSvcAttribute', 'fzpSvcPersonEdit'];
export default svc;
