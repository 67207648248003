import tmpl from './c-switch.html';
import './styles.css';

const cmp = function () {
    return {
        restrict: 'E',
        scope: {
            ngModel: '=',
            ngDisabled: '='
        },
        template: tmpl
    }
};

export default cmp;
