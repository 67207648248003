import angular from 'angular';
import сAdditionalRequirementEditTab from './cAdditionalRequirementEditTab';

const moduleName = 'com.farzoom.common.cAdditionalRequirement';

angular.module(moduleName, [
    'ui.mask',
    'com.farzoom.common.cAttributes',
    'com.farzoom.common.fzModal',
    'com.farzoom.common.fzTaskFormParts'
])
    .directive('fzlCAdditionalRequirementEditTab', сAdditionalRequirementEditTab)

export default moduleName;