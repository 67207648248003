const ctrl = ['$scope', function ($scope) {
    const my = this;

    my.calcExecContracts = function () {
        $scope.model.execContracts =    $scope.model.execContracts44 +
                                        $scope.model.execContracts223 +
                                        $scope.model.execContracts615 +
                                        $scope.model.execContractsCommerce;
    }

    my.calcExecContractsAmount = function () {
        $scope.model.execContractsAmount =  $scope.model.execContractsAmount44 +
                                            $scope.model.execContractsAmount223 +
                                            $scope.model.execContractsAmount615 +
                                            $scope.model.execContractsAmountCommerce;
    }

    my.calcContractsCountForBeneficiaries = function () {
        $scope.model.contractsCountForBeneficiaries =   $scope.model.contractsCountForBeneficiaries44 +
                                                        $scope.model.contractsCountForBeneficiaries223 +
                                                        $scope.model.contractsCountForBeneficiaries615 +
                                                        $scope.model.contractsCountForBeneficiariesCommerce;
    }

    my.calcContractsCountForBeneficiariesSum = function () {
        $scope.model.contractsCountForBeneficiariesSum =   $scope.model.contractsCountForBeneficiariesAmount44 +
                                                        $scope.model.contractsCountForBeneficiariesAmount223 +
                                                        $scope.model.contractsCountForBeneficiariesAmount615 +
                                                        $scope.model.contractsCountForBeneficiariesAmountCommerce;
    }

    my.calcContractsOkpd = function () {
        $scope.model.contractsOkpd =   $scope.model.contractsOkpd44 +
                                                        $scope.model.contractsOkpd223 +
                                                        $scope.model.contractsOkpd615 +
                                                        $scope.model.contractsOkpdCommerce;
    }

    my.calcContractsOkpdSum = function () {
        $scope.model.contractsOkpdSum =   $scope.model.contractsOkpdAmount44 +
                                                        $scope.model.contractsOkpdAmount223 +
                                                        $scope.model.contractsOkpdAmount615 +
                                                        $scope.model.contractsOkpdAmountCommerce;
    }

    my.calcMaxContractSum = function () {
        $scope.model.maxContractSum =   $scope.model.execContractsAmountMax44 +
                                                        $scope.model.execContractsAmountMax223 +
                                                        $scope.model.execContractsAmountMax615 +
                                                        $scope.model.execContractsAmountMaxCommerce;
    }

    my.calcPotentialExecContracts = function () {
        $scope.model.potentialExecContracts =   $scope.model.potentialExecContracts44 +
                                                        $scope.model.potentialExecContracts223 +
                                                        $scope.model.potentialExecContracts615 +
                                                        $scope.model.potentialExecContractsCommerce;
    }

    my.calcExecContractsAmountPotential = function () {
        $scope.model.execContractsAmountPotential =   $scope.model.execContractsAmountPotential44 +
                                                        $scope.model.execContractsAmountPotential223 +
                                                        $scope.model.execContractsAmountPotential615 +
                                                        $scope.model.execContractsAmountPotentialCommerce;
    }

    my.calcContractsCountForBeneficiariesPotential = function () {
        $scope.model.contractsCountForBeneficiariesPotential =   $scope.model.contractsCountForBeneficiariesPotential44 +
                                                        $scope.model.contractsCountForBeneficiariesPotential223 +
                                                        $scope.model.contractsCountForBeneficiariesPotential615 +
                                                        $scope.model.contractsCountForBeneficiariesPotentialCommerce;
    }

    my.calcContractsCountForBeneficiariesPotentialSum = function () {
        $scope.model.contractsCountForBeneficiariesPotentialSum =   $scope.model.contractsCountForBeneficiariesPotentialAmount44 +
                                                        $scope.model.contractsCountForBeneficiariesPotentialAmount223 +
                                                        $scope.model.contractsCountForBeneficiariesPotentialAmount615 +
                                                        $scope.model.contractsCountForBeneficiariesPotentialAmountCommerce;
    }

    my.calcContractsOkpdPotentialSum = function () {
        $scope.model.contractsOkpdPotential =   $scope.model.contractsOkpdPotential44 +
                                                        $scope.model.contractsOkpdPotential223 +
                                                        $scope.model.contractsOkpdPotential615 +
                                                        $scope.model.contractsOkpdPotentialCommerce;
    }

    my.calcContractsOkpdPotentialAmountSum = function () {
        $scope.model.contractsOkpdPotentialSum =   $scope.model.contractsOkpdPotentialAmount44 +
                                                        $scope.model.contractsOkpdPotentialAmount223 +
                                                        $scope.model.contractsOkpdPotentialAmount615 +
                                                        $scope.model.contractsOkpdPotentialAmountCommerce;
    }

    my.calcMaxContractPotentialSum = function () {
        $scope.model.maxContractPotentialSum =   $scope.model.execContractsAmountPotentialMax44 +
                                                        $scope.model.execContractsAmountPotentialMax223 +
                                                        $scope.model.execContractsAmountPotentialMax615 +
                                                        $scope.model.execContractsAmountPotentialMaxCommerce;
    }

    my.calcContractsToExec18Sum = function () {
        $scope.model.contractsToExec18Sum =   $scope.model.contractsToExec18 + $scope.model.contractsToExec18Commerce;
    }

    my.calcActivContractsSum = function () {
        $scope.model.activContractsSum =   $scope.model.activContracts + $scope.model.activContractsCommerce;
    }

    my.calcUpcomingPaymentsSum12 = function () {
        $scope.model.upcomingPaymentsSum12 =   $scope.model.upcomingPayments12 + $scope.model.upcomingPayments12Commerce;
    }

    my.calcUpcomingPaymentsSum = function () {
        $scope.model.upcomingPaymentsSum =   $scope.model.upcomingPayments + $scope.model.upcomingPaymentsCommerce;
    }

    my.calcActualPaymentsSum = function () {
        $scope.model.actualPaymentsSum =   $scope.model.actualPayments + $scope.model.actualPaymentsCommerce;
    }

    my.calcUpcomingPayments36Sum = function () {
        $scope.model.upcomingPayments36Sum =   $scope.model.upcomingPayments36 + $scope.model.upcomingPayments36Commerce;
    }

    my.calcCourtCasesCountSum = function () {
        $scope.model.courtCasesCountSum =   $scope.model.courtCasesCount + $scope.model.courtCasesCountCommerce;
    }

    my.calcCourtCasesActiveSum = function () {
        $scope.model.courtCasesActiveSum =   $scope.model.courtCasesActive + $scope.model.courtCasesActiveCommerce;
    }

    my.calcCourtCasesClosedSum = function () {
        $scope.model.courtCasesClosedSum =   $scope.model.courtCasesClosed + $scope.model.courtCasesClosedCommerce;
    }

    
    




    my.isInvalid = function () {
        return
            $scope.model.execContracts44 === null ||
            $scope.model.execContracts223 === null ||
            $scope.model.execContracts615 === null ||
            $scope.model.execContractsCommerce === null ||
            $scope.model.execContracts === null ||

            $scope.model.execContractsAmount44 === null ||
            $scope.model.execContractsAmount223 === null ||
            $scope.model.execContractsAmount615 === null ||
            $scope.model.execContractsAmountCommerce === null ||
            $scope.model.execContractsAmount === null ||

            $scope.model.contractsCountForBeneficiaries44 === null ||
            $scope.model.contractsCountForBeneficiaries223 === null ||
            $scope.model.contractsCountForBeneficiaries615 === null ||
            $scope.model.contractsCountForBeneficiariesCommerce === null ||
            $scope.model.contractsCountForBeneficiaries === null ||

            $scope.model.contractsCountForBeneficiariesAmount44 === null ||
            $scope.model.contractsCountForBeneficiariesAmount223 === null ||
            $scope.model.contractsCountForBeneficiariesAmount615 === null ||
            $scope.model.contractsCountForBeneficiariesAmountCommerce=== null ||
            $scope.model.contractsCountForBeneficiariesSum === null ||

            $scope.model.contractsOkpd44 === null ||
            $scope.model.contractsOkpd223 === null ||
            $scope.model.contractsOkpd615 === null ||
            $scope.model.contractsOkpdCommerce === null ||
            $scope.model.contractsOkpd === null ||

            $scope.model.contractsOkpdAmount44 === null ||
            $scope.model.contractsOkpdAmount223 === null ||
            $scope.model.contractsOkpdAmount615 === null ||
            $scope.model.contractsOkpdAmountCommerce === null ||
            $scope.model.contractsOkpdSum === null ||

            $scope.model.execContractsAmountMax44 === null ||
            $scope.model.execContractsAmountMax223 === null ||
            $scope.model.execContractsAmountMax615 === null ||
            $scope.model.execContractsAmountMaxCommerce === null ||
            $scope.model.maxContractSum === null ||

            $scope.model.potentialExecContracts44 === null ||
            $scope.model.potentialExecContracts223 === null ||
            $scope.model.potentialExecContracts615 === null ||
            $scope.model.potentialExecContractsCommerce === null ||
            $scope.model.potentialExecContracts === null ||

            $scope.model.execContractsAmountPotential44 === null ||
            $scope.model.execContractsAmountPotential223 === null ||
            $scope.model.execContractsAmountPotential615 === null ||
            $scope.model.execContractsAmountPotentialCommerce === null ||
            $scope.model.execContractsAmountPotential === null ||

            $scope.model.contractsCountForBeneficiariesPotential44 === null ||
            $scope.model.contractsCountForBeneficiariesPotential223 === null ||
            $scope.model.contractsCountForBeneficiariesPotential615 === null ||
            $scope.model.contractsCountForBeneficiariesPotentialCommerce === null ||
            $scope.model.contractsCountForBeneficiariesPotential === null ||

            $scope.model.contractsCountForBeneficiariesPotentialAmount44 === null ||
            $scope.model.contractsCountForBeneficiariesPotentialAmount223 === null ||
            $scope.model.contractsCountForBeneficiariesPotentialAmount615 === null ||
            $scope.model.contractsCountForBeneficiariesPotentialAmountCommerce === null ||
            $scope.model.contractsCountForBeneficiariesPotentialSum === null ||

            $scope.model.contractsOkpdPotential44 === null ||
            $scope.model.contractsOkpdPotential223 === null ||
            $scope.model.contractsOkpdPotential615 === null ||
            $scope.model.contractsOkpdPotentialCommerce === null ||
            $scope.model.contractsOkpdPotential === null ||

            $scope.model.contractsOkpdPotentialAmount44 === null ||
            $scope.model.contractsOkpdPotentialAmount223 === null ||
            $scope.model.contractsOkpdPotentialAmount615 === null ||
            $scope.model.contractsOkpdPotentialAmountCommerce === null ||
            $scope.model.contractsOkpdPotentialSum === null ||

            $scope.model.execContractsAmountPotentialMax44 === null ||
            $scope.model.execContractsAmountPotentialMax223 === null ||
            $scope.model.execContractsAmountPotentialMax615 === null ||
            $scope.model.execContractsAmountPotentialMaxCommerce === null ||
            $scope.model.maxContractPotentialSum === null ||

            $scope.model.contractsToExec18 === null ||
            $scope.model.contractsToExec18Commerce === null ||
            $scope.model.contractsToExec18Sum === null ||

            $scope.model.activContracts === null ||
            $scope.model.activContractsCommerce === null ||
            $scope.model.activContractsSum === null ||

            $scope.model.actualPayments === null ||
            $scope.model.actualPaymentsCommerce === null ||
            $scope.model.actualPaymentsSum === null ||

            $scope.model.upcomingPayments === null ||
            $scope.model.upcomingPaymentsCommerce === null ||
            $scope.model.upcomingPaymentsSum === null ||

            $scope.model.upcomingPayments12 === null ||
            $scope.model.upcomingPayments12Commerce === null ||
            $scope.model.upcomingPaymentsSum12 === null ||

            $scope.model.upcomingPayments36 === null ||
            $scope.model.upcomingPayments36Commerce === null ||
            $scope.model.upcomingPayments36Sum === null ||

            $scope.model.courtCasesCount === null ||
            $scope.model.courtCasesCountCommerce === null ||
            $scope.model.courtCasesCountSum === null ||

            $scope.model.courtCasesActive === null ||
            $scope.model.courtCasesActiveCommerce === null ||
            $scope.model.courtCasesActiveSum === null ||

            $scope.model.courtCasesClosed === null ||
            $scope.model.courtCasesClosedCommerce === null ||
            $scope.model.courtCasesClosedSum === null;
    };
}];

export default ctrl;