import './styles.css';
import refOkopf from './ref/okopf';

export default ['$scope', '$q', '$http', '$modalInstanse', '$modalParams', 'fzFilesConfig', '$filter',
    function ($scope, $q, $http, $modalInstanse, $modalParams, fzFilesConfig, $filter) {

    const my = this;

    my.closeOk = function () {
        $modalInstanse.close(my.data);
    };

    my.validate = function () {
        return !!my.state.guarantorType;
    }

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    my.refs = {
        okopf: refOkopf
    };

    my.setGuarantorType = function (type) {
        my.state = {
            selectedCompany: null,
            guarantorType: null
        };

        my.state.guarantorType = type;
        initData();
    };


    function initData() {
        if (!my.data) {
            my.data = {};
            if (my.state.guarantorType === 'person') {
                my.data.personAttributes = !$modalParams.personItemTemplate ? null : $modalParams.personItemTemplate.personAttributes;
            }
        }

        if (my.data.person || my.data.company) {
            my.state.guarantorType = !!my.data.person ? 'person' : 'company';
        }

        if (my.state.guarantorType === 'company') {
            if (!my.data.company) my.data.company = {};
            my.state.selectedCompany = {
                id: my.data.company.id, // Deprecated. For backwards compatibility only
                INN: my.data.company.INN,
                OGRN: my.data.company.OGRN,
                displayName: my.data.company.displayName,
                fullName: my.data.company.fullName
            };
        } else if (my.state.guarantorType === 'person') {
            if (!my.data.person) my.data.person = {};
            if (!my.data.person.contacts) my.data.person.contacts = [{}];
            if (!my.data.person.contacts.length) my.data.person.contacts.push({});
        }
    };

    $scope.$watch('my.state.selectedCompany', function (newValue) {
        if (!newValue) {
            if (my.data && my.data.company) my.data.company = {};
        } else {
            const okopf = my.data.company && my.data.company.OKOPF;
            my.data.company = {
                id: newValue.id, // Deprecated. For backwards compatibility only
                INN: newValue.INN,
                OGRN: newValue.OGRN,
                displayName: newValue.displayName,
                fullName: newValue.fullName,
                OKOPF: okopf
            };
        }
    });

    $scope.$watch('my.data.person.secondName', secondName => my.noSecondName = secondName === '-');

    // Passport OCR
    const passportParsingErrorCodes = {
        'FILE_TOO_LARGE': 'Размер файла превышает максимально разрешённое значение',
        'PARSE_FAILED': 'Не удалось импортировать данные',
        'UNEXPECTED': 'Произошла непредвиденная ошибка',
    };

    my.onPassportFileDrop = function (files) {
        if (!files || !files.length) return;
        const file = [...files][0];
        processPassportFile(file);
    };

    my.onPassportFileInputChange = function (input) {
        if (!input.files.length) return;
        const file = [...input.files][0];
        clearFileInput(input);
        processPassportFile(file);
    };

    function clearFileInput(el) {
        const $el = $(el);
        $el.wrap('<form>').closest('form').get(0).reset();
        $el.unwrap();
    }

    function processPassportFile(file) {
        const maxSizeInBytes = fzFilesConfig.getMaxSizeInBytes();
        my.isPassportParsing = true;
        my.isPassportParsingError = false;
        my.passportParsingMessage = '';
        return checkFileSize(file, maxSizeInBytes) // Проверить размер файла
            .then(() => parsePassportFile(file)) // Распарсить файл
            .then(data => fillPassportData(data)) // Заполнить данные на форме
            .finally(() => my.isPassportParsing = false)
            .catch(e => {
                my.isPassportParsingError = true;
                const msg = passportParsingErrorCodes[e.errorCode || 'UNEXPECTED'];
                my.passportParsingMessage = msg;
            });
    }

    function checkFileSize(file, maxSizeInBytes) {
        if (file.size > maxSizeInBytes) {
            const err = new Error("File size too large");
            err.errorCode = 'FILE_TOO_LARGE';
            return $q.reject(err);
        }
        return $q.all([]);
    }

    function parsePassportFile(file) {
        const formData = new FormData();
        formData.append('file', file);
        return $http.post('/api/ocr-passport/process_ocr', formData, {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        })
            .then(resp => resp.data || {})
            .catch(e => {
                e.errorCode = 'PARSE_FAILED';
                return $q.reject(e);
            });
    }

    function fillPassportData(data) {
        const mainPage = ((data || {}).pages_list || []).find(page =>
            page.is_passport === true &&
            (page.passport_data || {}).is_main_page == true
        );
        const mainPageData = ((mainPage || {}).passport_data || {}).main_page_data || {};

        let serial_number = (mainPageData.serial_number || '').replace(/\s/g, ''); // Remove spaces
        const series = serial_number.substr(0, 4);
        const number = serial_number.substr(4, 6);

        my.data.person.lastName = mainPageData.surname || '';
        my.data.person.firstName = mainPageData.name || '';
        my.data.person.secondName = mainPageData.patronymic || '';
        my.data.person.birthDate = parseDate(mainPageData.bithday);
        my.data.person.birthPlace = null; // TODO
        my.data.person.series = series;
        my.data.person.number = number;
        my.data.person.issuedDate = parseDate(mainPageData.when_given);
        my.data.person.issuingAuthorityCode = mainPageData.code_given || '';
        my.data.person.issuingAuthority = mainPageData.where_given || '';
    }

    function parseDate(value) {
        if (!value) return null;
        const parts = value.split('.');
        if (parts.length != 3) return null;
        return `${parts[2]}-${parts[1]}-${parts[0]}`
    }
    // end passport OCR

    my.onSelectPerson = function (item) {
        const show = fld => !!((my.config || {})[fld] || {}).show;
        if (item) {
            const d = angular.copy(item.data);
            my.data = my.data || {};
            my.data.person = {
                lastName: d.lastName,
                firstName: d.firstName,
                secondName: d.secondName,
                series: d.series,
                number: d.number,
                issuingAuthority: d.issuingAuthority,
                issuedDate: d.issuedDate,
                issuingAuthorityCode: d.issuingAuthorityCode,
                contacts: d.contacts || [{}]
            };
            if (show('inn')) my.data.person.inn = d.inn;
            if (show('birthDate')) my.data.person.birthDate = d.birthDate;
            if (show('birthPlace')) my.data.person.birthPlace = d.birthPlace;
            if (show('registrationAddress')) my.data.person.registrationAddress = d.registrationAddress;
            if (show('actualAddress')) my.data.person.actualAddress = d.actualAddress;
            if (show('citizenship')) my.data.person.citizenship = d.citizenship;
            if (show('otherCitizenship')) my.data.person.otherCitizenship = d.otherCitizenship;
            if ((show('phone') || show('email')) && !my.data.person.contacts.length) my.data.person.contacts.push({});
            fillAttributes(my.data.personAttributes, d.personAttributes);
        } else {
            my.data = my.data || {};
            my.data.person = {};
        }
    };

    function fillAttributes(destAttrs, srcAttrs) {
        if (destAttrs && destAttrs.items && destAttrs.items.length && srcAttrs && srcAttrs.items && srcAttrs.items.length) {
            const destMap = new Map();
            destAttrs.items.forEach(attr => destMap.set(attr.attributeId, attr));
            srcAttrs.items.forEach(attr => {
                if (destMap.has(attr.attributeId)) {
                    const destAttr = destMap.get(attr.attributeId);
                    destAttr.value = attr.value;
                    destAttr.values = attr.values;
                }
            });
        }
    };

    my.removeContactByIndex = function (index) {
        my.data.person.contacts.splice(index, 1);
    };

    my.addContact = function () {
        my.data.person.contacts.push({});
    };

    my.isRf = function () {
        const isShown = my.config && my.config.citizenship && my.config.citizenship.show;
        return !isShown || my.data.person.citizenship === (my.config.citizenship.rfCode || 'rf');
    };

    my.checkCurrDate = function(checkDate) {
        const now = new Date();
        if (!checkDate) return true;
        let y = now.getFullYear();
        let m = now.getMonth() + 1;
        let d = now.getDate();
        let today = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}` ;
        return checkDate <= today;
    }

    my.checkMinAge = function () {
        const minAge = ((my.config || {}).age || {}).minimalAge || 0;
        if (minAge <= 0) return true;
        if (!my.data.person.birthDate) return true;
        const now = new Date();
        const today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
        const bDate = new Date(my.data.person.birthDate + 'T00:00:00Z');
        const age = new Date((today - bDate)).getFullYear() - 1970;

        return age < 0 || age >= minAge; //   age<0 проверит checkCurrDate
    }

    my.checkINN12 = function () {
        return my.readonly || !my.data.person.inn || my.data.person.inn.length == 12;
    }

    my.validateSharedPercent = function () {
        if (!my.data.sharePercent) return false
        return my.readonly ||
            !((my.config || {}).sharePercent || {}).show ||
            !my.data.sharePercent ||
            (my.data.sharePercent >= 50 && my.data.sharePercent <= 100);
    };

    my.state = {
        selectedCompany: null,
        guarantorType: null
    };


    my.readonly = $modalParams.readonly;
    my.personsRef = $modalParams.personsRef;
    my.ocrEnabled = $modalParams.ocrEnabled;
    my.ref = $modalParams.ref;
    my.config = $modalParams.config;
    my.data = !$modalParams.item ? null : angular.copy($modalParams.item);
    my.setGuarantorType('person');
    if (my.data) initData();

}];