import angular from 'angular';
import component from './fzlCSignDocs';

const moduleName = 'com.farzoom.lime.cSignDocs';
const componentName = 'fzlCSignDocs';


angular.module(moduleName, [
        'com.farzoom.common.fzFiles'
    ])
    .directive(componentName, component)

export default moduleName;
