import modalTmpl from '../cClientQuestEditTab/modal/template.html';
import modalCtrl from '../cClientQuestEditTab/modal/controller';

const ctrl = ['$scope', 'fzModal', function ($scope, fzModal) {
    const my = this;

    my.onButtonClickHandler = function () {
        fzModal.open({
            windowClass: 'fz-c-cilent__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                header: $scope.model.header,
                model: JSON.parse(JSON.stringify($scope.model)),
                readonly: true
            }
        });
    };
}];

export default ctrl;



