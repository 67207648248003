import tmpl from './template.html';
import ctrl from './controller';
import nodeTmpl from './node.html';
import './styles.css';

const nodeTmplName = 'c-scoring-result__node-tmpl';

const cmp = ['$templateCache', function ($templateCache) {
    return {
        restrict: 'E',
        scope: {
            model: '=',
            header: '@'
        },
        template: tmpl,
        controllerAs: 'my',
        controller: ctrl,
        compile: function () {
            if (!$templateCache.get(nodeTmplName)) {
                $templateCache.put(nodeTmplName, nodeTmpl);
            }

            return {
                pre: function (scope) {
                    scope.nodeTmplName = nodeTmplName;
                }
            };
        }
    };
}];

export default cmp;
