import _ from 'lodash'

const ctrl = ['$scope', '$http', function ($scope, $http) {
  const my = this
 
  $scope.maxDuration = angular.isDefined($scope.maxDuration)
    ? $scope.maxDuration
    : 2200

  const cloneDeep = (obj) => JSON.parse(JSON.stringify(obj))

  const mapModel = (model) => {
    if (model && model.data && model.data.purchase && model.data.purchase.lots) {
      model.data.purchase.lots.map((lot) => {
        if (!lot.lotSubject) {
          lot.lotSubject = null
        }
        return lot
      })
      return model
    } else {
      return model
    }
  }

  my.model = cloneDeep($scope.model)
  my.hasChangeStep1Product = false
  my.disabled = $scope.disabled || false
  my.companyChangedEvent = 'companyChangedEvent'

  my.onChange = () => {
    const model = angular.copy(mapModel(cloneDeep($scope.model)))
    const stateModel = angular.copy(mapModel(cloneDeep(my.model)))
    const hasChange = !_.isEqual(model, stateModel)
    if (my.hasChangeStep1Product !== hasChange) {
      my.hasChangeStep1Product = hasChange
    }
  }

  my.isCurrencyAmount = () => my?.model?.data?.currencyRefId !== 'RUB'

  my.onChangeCurrencyAmount = (lot, x) => {
    const i = lot.beneficiaries.indexOf(x)
    var curSum = x.bgCurrencyAmount;
    if (my.isCurrencyAmount()) {
        curSum = Math.ceil(curSum/100)*100
        x.bgCurrencyAmount = curSum
    }
    lot.beneficiaries[i] = {
      ...lot.beneficiaries[i],
      bgAmount: Math.round(curSum * $scope.model.data.currentRate / 100),
    }
  }

  my.clearCustomer = (lot, x) => {
    const i = lot.beneficiaries.indexOf(x)
    lot.beneficiaries[i] = {
      bgAmount: x.bgAmount,
    }
  }

  $scope.$watch(() => my.onChange())

  $scope.$watch('my.hasChangeStep1Product', (updatedValue) => {
    if ($scope.hasChange !== updatedValue) {
      $scope.hasChange = updatedValue
    }
  })

  $scope.$on(my.companyChangedEvent, (event, newCompany) => {
    // do nothing
  })

  // Aванс
  my.showPrepaymentExists = () => $scope.model.data.bankGuaranteeTypeRefId === 'execution'

  my.isPayment = () => $scope.model.data.bankGuaranteeTypeRefId === 'payment'

}]

export default ctrl