export default ['$http', function ($http) {
    this.calc = function (order, orderParams, company, serviceLocation) {
        return $http.post(serviceLocation, {
                order: order,
                orderParams: orderParams,
                company: company
            })
            .then(function (resp) {
                return (resp.data || {}).commissionAmount;
            });
    };
}];