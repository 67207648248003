const ctrl = ['$scope', function ($scope) {
    const my = this;

    $scope.model = $scope.model || {};
    $scope.model.temp = $scope.model.temp || {};
    $scope.model.needShowError = false;
    $scope.model.refs = $scope.model.refs || [];
    $scope.model.data = $scope.model.data || {};
    $scope.model.data.items = $scope.model.data.items || [];

    my.isThemeVisible = function () {
        return !my.calculateParam($scope.hideTheme, false);
    }

    my.isReasonVisible = function () {
        if (my.calculateParam($scope.hideReason, false)) {
            return false;
        }
        return $scope.model.config.showReasonField;
    }

    my.isCommentVisible = function () {
        if (my.calculateParam($scope.hideComment, false)) {
            return false;
        }
        return $scope.model.config.showCommentField;
    }

    my.getUniqueThemes = function () {
        return $scope.model.refs.reduce((result, item) => {
            if (!result.find((i => i.theme === item.theme))) {
                result.push(item);
            }
            return result;
        }, []);
    }

    my.getReasonsForTheme = function (theme) {
        return $scope.model.refs.filter(item => item.theme === theme);
    }

    my.isThemeFieldMandatory = function () {
        if (!my.isThemeVisible()) {
            return false;
        }
        if (my.calculateParam($scope.themeRequired, true)) {
            return true;
        }
        return $scope.model.config.isThemeMandatory;
    }

    my.isReasonFieldMandatory = function () {
        if (!my.isReasonVisible()) {
            return false;
        }
        if (my.calculateParam($scope.reasonRequired, true) && !my.isReasonFieldDisabled()) {
            return true;
        }
        return $scope.model.config.isReasonMandatory && !my.isReasonFieldDisabled();
    }

    my.isReasonFieldDisabled = function () {
        const theme = $scope.model.temp.theme;
        if (!theme) {
            return true;
        }

        const availableReasons = my.getReasonsForTheme(theme);
        if (!availableReasons || availableReasons.length <= 0) {
            return true;
        }

        return availableReasons.length == 1 && !availableReasons[0].reason;
    }

    my.isCommentFieldMandatory = function () {
        if (!my.isCommentVisible()) {
            return false;
        }

        if (my.calculateParam($scope.reasonRequired, true)) {
            return true;
        }

        if ($scope.model.config.isCommentMandatory) {
            return true;
        }

        const theme = $scope.model.temp.theme;
        const reason = $scope.model.temp.reason;
        if (!theme) {
            return false;
        }

        if (reason) {
            const selectedItem = $scope.model.refs.filter(item => item.theme === theme && item.reason === reason);
            if (selectedItem) {
                return selectedItem.isCommentRequired;
            }
        }

        const availableReasons = my.getReasonsForTheme(theme);
        if (availableReasons && availableReasons.length == 1 && !availableReasons[0].reason) {
            return availableReasons[0].isCommentRequired;
        }

        return false;
    }

    my.hasErrorInData = function () {
        return my.hasErrorInTheme() || my.hasErrorInReason() || my.hasErrorInComment();
    }

    my.needShowErrorInTheme = function () {
        return $scope.model.needShowError && my.hasErrorInTheme();
    }

    my.hasErrorInTheme = function () {
        if (my.isThemeFieldMandatory()) {
            if (!$scope.model.temp.theme) {
                return true;
            }
        }
        return false;
    }

    my.needShowErrorInReason = function () {
        return $scope.model.needShowError && my.hasErrorInReason();
    }

    my.hasErrorInReason = function () {
        if (my.isReasonFieldMandatory()) {
            if (!$scope.model.temp.reason) {
                return true;
            }
        }
        return false;
    }

    my.needShowErrorInComment = function () {
        return $scope.model.needShowError && my.hasErrorInComment();
    }

    my.hasErrorInComment = function () {
        if (my.isCommentFieldMandatory()) {
            if (!$scope.model.temp.comment) {
                return true;
            }
        }
        return false;
    }

    my.onButtonAddClickHandler = function () {
        if (my.hasErrorInData()) {
            $scope.model.needShowError = true;
        } else {
            $scope.model.data.items.push($scope.model.temp);
            $scope.model.temp = {};
            $scope.model.needShowError = false;
        }
    }

    my.onButtonDeleteClickHandler = function (item) {
        const index = $scope.model.data.items.indexOf(item);
        $scope.model.data.items.splice(index, 1);
    }

    my.onChangeField = function () {
        $scope.model.needShowError = false;
    }


    // private utils functions
    my.calculateParam = function (param, defaultValue) {
        if (param) {
            if (typeof param === 'function') {
                return param();
            } else {
                return param;
            }
        }
        return defaultValue;
    }
}];

export default ctrl;