const ctrl = ['$scope', function ($scope) {
    const my = this;

    my.isInvalid = function () {
        return $scope.model.kikExecContracts44 === null ||
            $scope.model.kikExecContracts223 === null ||
            $scope.model.kikExecContracts615 === null ||
            $scope.model.kikExecContracts === null ||
            $scope.model.kikExecContractsAmount44 === null ||
            $scope.model.kikExecContractsAmount223 === null ||
            $scope.model.kikExecContractsAmount615 === null ||
            $scope.model.kikExecContractsAmount === null ||
            $scope.model.kikContractsCountForBeneficiaries44 === null ||
            $scope.model.kikContractsCountForBeneficiaries223 === null ||
            $scope.model.kikContractsCountForBeneficiaries615 === null ||
            $scope.model.kikContractsCountForBeneficiaries === null ||
            $scope.model.kikContractsCountForBeneficiariesAmount44 === null ||
            $scope.model.kikContractsCountForBeneficiariesAmount223 === null ||
            $scope.model.kikContractsCountForBeneficiariesAmount615 === null ||
            $scope.model.kikContractsCountForBeneficiariesSum === null ||
            $scope.model.kikContractsOkpd44 === null ||
            $scope.model.kikContractsOkpd223 === null ||
            $scope.model.kikContractsOkpd615 === null ||
            $scope.model.kikContractsOkpd === null ||
            $scope.model.kikContractsOkpdAmount44 === null ||
            $scope.model.kikContractsOkpdAmount223 === null ||
            $scope.model.kikContractsOkpdAmount615 === null ||
            $scope.model.kikContractsOkpdSum === null ||
            $scope.model.kikExecContractsAmountMax44 === null ||
            $scope.model.kikExecContractsAmountMax223 === null ||
            $scope.model.kikExecContractsAmountMax615 === null ||
            $scope.model.kikMaxContractSum === null ||
            $scope.model.kikPotentialExecContracts44 === null ||
            $scope.model.kikPotentialExecContracts223 === null ||
            $scope.model.kikPotentialExecContracts615 === null ||
            $scope.model.kikPotentialExecContracts === null ||
            $scope.model.kikExecContractsAmountPotential44 === null ||
            $scope.model.kikExecContractsAmountPotential223 === null ||
            $scope.model.kikExecContractsAmountPotential615 === null ||
            $scope.model.kikExecContractsAmountPotential === null ||
            $scope.model.kikContractsCountForBeneficiariesPotential44 === null ||
            $scope.model.kikContractsCountForBeneficiariesPotential223 === null ||
            $scope.model.kikContractsCountForBeneficiariesPotential615 === null ||
            $scope.model.kikContractsCountForBeneficiariesPotential === null ||
            $scope.model.kikContractsCountForBeneficiariesPotentialAmount44 === null ||
            $scope.model.kikContractsCountForBeneficiariesPotentialAmount223 === null ||
            $scope.model.kikContractsCountForBeneficiariesPotentialAmount615 === null ||
            $scope.model.kikContractsCountForBeneficiariesPotentialSum === null ||
            $scope.model.kikContractsOkpdPotential44 === null ||
            $scope.model.kikContractsOkpdPotential223 === null ||
            $scope.model.kikContractsOkpdPotential615 === null ||
            $scope.model.kikContractsOkpdPotential615 === null ||
            $scope.model.kikContractsOkpdPotentialAmount44 === null ||
            $scope.model.kikContractsOkpdPotentialAmount223 === null ||
            $scope.model.kikContractsOkpdPotentialAmount615 === null ||
            $scope.model.kikContractsOkpdPotentialSum === null ||
            $scope.model.kikExecContractsAmountPotentialMax44 === null ||
            $scope.model.kikExecContractsAmountPotentialMax223 === null ||
            $scope.model.kikExecContractsAmountPotentialMax615 === null ||
            $scope.model.kikMaxContractPotentialSum === null ||
            $scope.model.kikContractsToExec18 === null ||
            $scope.model.kikActivContracts === null ||
            $scope.model.actualPaymentsSum === null ||
            $scope.model.upcomingPaymentsSum === null ||
            $scope.model.upcomingPaymentsSum12 === null ||
            $scope.model.actualPaymentsSum36 === null ||
            $scope.model.courtCasesCount === null;
    };
}];

export default ctrl;