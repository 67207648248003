import tmpl from './template.html';
import ctrl from './controller';

const cmp = function () {
    return {
        restrict: 'E',
        scope: {
            docs: '=',
            header: '@',
            timeAfterWhichFilesShouldBeMarked: '=?',
            showZipIconOnSignedDocs: '=?',
            open: '=?',
        },
        template: tmpl,
        controller: ctrl,
        controllerAs: 'my'
    };
};

export default cmp;
