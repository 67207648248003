import angular from 'angular';

import fzlCTabs from './fzlCTabs';
import fzlCTab from './fzlCTab';

const moduleName = 'com.farzoom.lime.cTabs';

angular.module(moduleName, [
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzlCTabs', fzlCTabs)
    .directive('fzlCTab', fzlCTab)

export default moduleName;
