import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

const ctrl = ['$scope', 'fzModal', 'fzpSvcAttribute', 'fzSvcShareholder', function ($scope, fzModal, fzSvcAttribute, fzSvcShareholder) {
  const my = this;

  my.state = {
    itemsInvalid: [],
  }

  my.onButtonEditClickHandler = () => fzModal.open({
    windowClass: 'fz-c-cilent__modal',
    template: modalTmpl,
    controller: modalCtrl,
    controllerAs: 'my',
    params: {
      header: $scope.model.header,
      model: JSON.parse(JSON.stringify($scope.model)),
      checkValid: !!$scope.checkValid,
      isActivityFilled: my.isActivityFilled,
      checkDate: my.checkDate,
    }
  }).closed.then(result => {
    if (!result) return;
    $scope.model.data = result;
  });

  my.validate = () => {
    const x = $scope.model;
    if (!x || !x.data) return false;
    const d = x.data;
    return fzSvcAttribute.validateGroup(d.commonAttributes) &&
      fzSvcAttribute.validateGroup(d.purposeRefGroup) &&
      fzSvcAttribute.validateGroup(d.capitalAttributes) &&
      fzSvcAttribute.validateGroup(d.fatcaAttributes) &&
      fzSvcAttribute.validateGroup(d.structAttributes) &&
      fzSvcAttribute.validateGroup(d.activityAttributes) &&
      fzSvcAttribute.validateGroup(d.moneySourceRefGroup) &&
      fzSvcAttribute.validateGroup(d.riskAttributes) &&
      my.validateReputationAttributes(d.reputationAttributes) &&
      fzSvcAttribute.validateGroup(d.fatcaDetailsAttributes) &&
      fzSvcAttribute.validateGroup(d.ipdlAttributes) &&
      fzSvcAttribute.validateGroup(d.operationAttributes) &&
      fzSvcShareholder.validateAll(d.shareholders) &&
      my.validateLicense(d.activityTypes);
  };

  my.validateReputationAttributes = (reputationAttributes) => {
    return my.checkReputation1(reputationAttributes) &&
      my.checkReputation4(reputationAttributes) &&
      my.checkReputation6(reputationAttributes) &&
      my.checkReputation8(reputationAttributes) &&
      my.checkReputation10(reputationAttributes) &&
      my.checkReputation11(reputationAttributes) &&
      my.checkReputation12(reputationAttributes);
  }

  my.checkReputation1 = (reputationAttributes) => {
    const value = reputationAttributes?.items[0]?.value?.stringValue || ""
    return !!value && value.length <= 500
  }

  my.checkReputation4 = (reputationAttributes) => {
    const value = reputationAttributes?.items[3]?.value?.stringValue || ""
    return !value || value.length <= 1000
  }

  my.checkReputation6 = (reputationAttributes) => {
    const value = reputationAttributes?.items[5]?.value?.stringValue || ""
    return !value || value.length <= 250
  }

  my.checkReputation8 = (reputationAttributes) => {
    const value = String(reputationAttributes?.items[6]?.value?.longValue || "")
    return !value || value.length <= 12
  }

  my.checkReputation10 = (reputationAttributes) => {
    const value = reputationAttributes?.items[8]?.value?.stringValue || ""
    return !value || value.length <= 250
  }

  my.checkReputation11 = (reputationAttributes) => {
    const value = reputationAttributes?.items[9]?.value?.stringValue || ""
    return !value || value.length <= 250
  }

  my.checkReputation12 = (reputationAttributes) => {
    const value = reputationAttributes?.items[10]?.value?.stringValue || ""
    return !value || value.length <= 250
  }


  $scope.$watch('model.data', function (d) {
    if (!d) return;
    if (!$scope.ref) $scope.ref = [];
  }, true);

  my.isActivityFilled = (license) => !!license?.isShowLicensesRef
    ? !!$scope.model.licensesRef.find(item => item?.name === license?.activities)
    : !!$scope.model.licensesList.find(item => item?.number === license?.number)

  my.validateLicense = (activityTypes) =>
    activityTypes.filter(a => !a.isLicensed).every(a => !a.isLicensed && !!a.activity) &&
    activityTypes.filter(a => a.isLicensed).every(a =>
      !!a.activity && a.licenses.every(l =>
        !!l.number &&
        !!l.issuingAuthority &&
        !!l.issuedDate &&
        (l.termless || !!l.expiredDate && my.checkDate(l.expiredDate)) &&
        my.isActivityFilled(l)
      )
    )

  my.checkDate = (sDate) => {
    if (!sDate) {
      return false;
    } else {
      const now = new Date();
      let y = now.getFullYear();
      let m = now.getMonth() + 1;
      let d = now.getDate();
      let today = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}` ;
      return sDate >= today;
    }
  }
}];

export default ctrl;