import angular from 'angular';
import cGuarantorsEditTab from './cGuarantorsEditTab';
import cGuarantorsViewTab from './cGuarantorsViewTab';
import cGuarantorSingleEditTab from './cGuarantorSingleEditTab';

const moduleName = 'com.farzoom.common.cGuarantors';

angular.module(moduleName, [
    'ui.mask',
    'com.farzoom.common.cAttributes',
    'com.farzoom.common.fzModal',
    'com.farzoom.common.fzSelectAddress',
    'com.farzoom.common.fzSelectCompany',
    'com.farzoom.common.fzToDate',
    'com.farzoom.common.fzToMoney',
    'com.farzoom.common.fzToNumber',
    'com.farzoom.common.fzTaskFormParts'
])
    .directive('fzlCGuarantorsEditTab', cGuarantorsEditTab)
    .directive('fzlCGuarantorsViewTab', cGuarantorsViewTab)
    .directive('fzlCGuarantorSingleEditTab', cGuarantorSingleEditTab)

export default moduleName;