import './styles.css';

export default ['$scope', '$modalInstanse', '$modalParams', function ($scope, $modalInstanse, $modalParams) {
    const my = this;

    my.closeOk = function () {
        $modalInstanse.close();
    };

    my.closeCancel = function () {
        $modalInstanse.close();
    };

    function getDraftVarName(doc) {
        return doc.entityId ? `${doc.docType}@${doc.entityId}` : doc.docType;
    };

    my.docDraft = function (doc) {
        const draftVarName = getDraftVarName(doc);
        if (!$scope.drafts) $scope.drafts = {};
        if (!$scope.drafts[draftVarName]) $scope.drafts[draftVarName] = [];
        return $scope.drafts[draftVarName];
    };

    my.onChangeHandler = function (doc, drafts) {
        if ($scope.drafts) {
            const draftVarName = getDraftVarName(doc);
            if (drafts && drafts.length) $scope.drafts[draftVarName] = drafts;
            else $scope.drafts[draftVarName] = [];
        }
    };

    $scope.camForm = { taskId: $modalParams.taskId }; // HACK for fz-upload-cam
    my.docs = Object.values($modalParams.docs || {}).filter(x => x.canUpload);
    $scope.drafts = $modalParams.drafts;
}];