import angular from 'angular';
import fzlClientQuestEditTab from './cClientQuestEditTab';
import fzlClientQuestViewTab from './cClientQuestViewTab';

const moduleName = 'com.farzoom.lime.cClientQuest';

angular.module(moduleName, [
        'ui.mask',
        'com.farzoom.common.cAttributes',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzSelectAddress',
        'com.farzoom.common.fzTaskFormParts',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzlCClientQuestEditTab', fzlClientQuestEditTab)
    .directive('fzlCClientQuestViewTab', fzlClientQuestViewTab)

export default moduleName;