import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

const ctrl = ['$scope', 'fzModal', function ($scope, fzModal) {
    const my = this;

    my.onButtonClickHandler = function () {
        fzModal.open({
            windowClass: 'fz-c-recalculation__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                item: $scope.model || {},
            }
        })
        .closed.then(result => {
            if (!result) return;
            $scope.model = result;
        });
    };

    my.checkIsIncludedForfeit = function(isIncludedForfeit) {
        return isIncludedForfeit == false || isIncludedForfeit == null ? 'нет' : 'да';
    };

    my.checkIsRequiredIndisputableDebiting = function(isRequiredIndisputableDebiting) {
        return isRequiredIndisputableDebiting == false || isRequiredIndisputableDebiting == null ? 'нет' : 'да';
    };

    my.checkValid = function(securityForGuaranteePeriodDays, prepaymentAmount) {
        let regex = /\D/;
        if (!regex.test(securityForGuaranteePeriodDays) || !regex.test(prepaymentAmount)) {
            return true;
        } else if (securityForGuaranteePeriodDays == null && prepaymentAmount == null) {
            return true;
        } else
            return false;
    };

}];

export default ctrl;