import angular from 'angular';

const ctrl = ['$scope', function ($scope) {
    const my = this;
    my.docs = null;

    $scope.$watch('docs', function (docs) {
        if (!docs) return;
        my.docs = Object.values(docs).filter(x => (x.files && x.files.length) || x.showEmpty);
    });
}];

export default ctrl;