import angular from 'angular';

import fzlCCrossProducts from './fzlCCrossProducts';

const moduleName = 'com.farzoom.rko.common.cCrossProduct';

angular.module(moduleName, [
    'ui.mask',
    'com.farzoom.common.cAttributes',
    'com.farzoom.common.fzModal',
    'com.farzoom.common.fzSelectAddress',
    'com.farzoom.common.fzTaskFormParts',
    'com.farzoom.common.fzToDate',
    'com.farzoom.common.fzToMoney',
    'com.farzoom.common.fzToNumber',
    'com.farzoom.common.fzTaskFormParts'
  ])
  .directive('fzlCCrossProducts', fzlCCrossProducts)
export default moduleName;
