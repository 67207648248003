import angular from 'angular';
import fzlCReturnReasons from './fzlCReturnReasons';

const moduleName = 'com.farzoom.common.cReturnReasons';

angular.module(moduleName, [
    'com.farzoom.common.fzModal',
    'com.farzoom.common.fzToMoney',
    'com.farzoom.common.fzToNumber',
    'com.farzoom.common.fzTaskFormParts'
])
    .directive('fzlCReturnReasons', fzlCReturnReasons)

export default moduleName;