import angular from 'angular';
import cBgCommissionEditTab from './cBgCommissionEditTab';
import cBgCommissionViewTab from './cBgCommissionViewTab';
import svcBgCommission from './svcBgCommission';

const moduleName = 'com.farzoom.lime.cCommission';

angular.module(moduleName, [
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzSvcNumber',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzlCBgCommissionEditTab', cBgCommissionEditTab)
    .directive('fzlCBgCommissionViewTab', cBgCommissionViewTab)
    .service('svcBgCommission', svcBgCommission);

export default moduleName;
