import './styles.css';
import refOkopf from './ref/okopf';

export default ['$scope', '$modalInstanse', '$modalParams', '$filter', function ($scope, $modalInstanse, $modalParams, $filter) {
  const my = this;

  my.closeOk = function () {
    $modalInstanse.close(my.data);
  };

  my.validate = function () {
    return !!my.state.founderType;
  }

  my.closeCancel = function () {
    $modalInstanse.close();
  };

  my.refs = {
    okopf: refOkopf
  };

  my.setFounderType = function (type) {
    my.state = {
      selectedCompany: null,
      founderType: null
    };

    my.state.founderType = type;
    initData();
  };

  function initData() {
    if (!my.data) {
      my.data = {};
      if (my.state.founderType === 'person') {
        my.data.personAttributes = !$modalParams.personItemTemplate ? null : $modalParams.personItemTemplate.personAttributes;
      }
    }

    if (my.data.person || my.data.company) {
      my.state.founderType = !!my.data.person ? 'person' : 'company';
    }

    if (my.state.founderType === 'company') {
      if (!my.data.company) my.data.company = {};
      my.state.selectedCompany = {
        id: my.data.company.id, // Deprecated. For backwards compatibility only
        INN: my.data.company.INN,
        OGRN: my.data.company.OGRN,
        displayName: my.data.company.displayName,
        fullName: my.data.company.fullName
      };
    } else if (my.state.founderType === 'person') {
      if (!my.data.person) my.data.person = {};
      if (!my.data.person.contacts) my.data.person.contacts = [{}];
      if (!my.data.person.contacts.length) my.data.person.contacts.push({});
    }
  };

  $scope.$watch('my.state.selectedCompany', function (newValue) {
    if (!newValue) {
      if (my.data && my.data.company) my.data.company = {};
    } else {
      const okopf = my.data.company && my.data.company.OKOPF;
      my.data.company = {
        id: newValue.id, // Deprecated. For backwards compatibility only
        INN: newValue.INN,
        OGRN: newValue.OGRN,
        displayName: newValue.displayName,
        fullName: newValue.fullName,
        OKOPF: okopf
      };
    }
  });

  $scope.$watch('my.data.person.secondName', secondName => my.noSecondName = secondName === '-');

  my.onSelectPerson = function (item) {
    const show = fld => !!((my.config || {})[fld] || {}).show;
    if (item) {
      const d = angular.copy(item.data);
      my.data = my.data || {};
      my.data.person = {
        lastName: d.lastName,
        firstName: d.firstName,
        secondName: d.secondName,
        series: d.series,
        number: d.number,
        issuingAuthority: d.issuingAuthority,
        issuedDate: d.issuedDate,
        issuingAuthorityCode: d.issuingAuthorityCode,
        contacts: d.contacts || [{}],
        otherDocuments: d.otherDocuments || [],
      };
      if (show('inn')) my.data.person.inn = d.inn;
      if (show('birthDate')) my.data.person.birthDate = d.birthDate;
      if (show('birthPlace')) my.data.person.birthPlace = d.birthPlace;
      if (show('registrationAddress')) my.data.person.registrationAddress = d.registrationAddress;
      if (show('actualAddress')) my.data.person.actualAddress = d.actualAddress;
      if (show('citizenship')) my.data.person.citizenship = d.citizenship;
      if (show('otherCitizenship')) my.data.person.otherCitizenship = d.otherCitizenship;
      fillAttributes(my.data.personAttributes, d.personAttributes);
    } else {
      my.data = my.data || {};
      my.data.person = {};
    }
  };

  function fillAttributes(destAttrs, srcAttrs) {
    if (destAttrs && destAttrs.items && destAttrs.items.length && srcAttrs && srcAttrs.items && srcAttrs.items.length) {
      const destMap = new Map();
      destAttrs.items.forEach(attr => destMap.set(attr.attributeId, attr));
      srcAttrs.items.forEach(attr => {
        if (destMap.has(attr.attributeId)) {
          const destAttr = destMap.get(attr.attributeId);
          destAttr.value = attr.value;
          destAttr.values = attr.values;
        }
      });
    }
  };

  my.removeContactByIndex = function (index) {
    my.data.person.contacts.splice(index, 1);
  };

  my.addContact = function () {
    my.data.person.contacts.push({});
  };

  my.checkCurrDate = function(checkDate) {
    const now = new Date();
    if (!checkDate) return true;
    let y = now.getFullYear();
    let m = now.getMonth() + 1;
    let d = now.getDate();
    let today = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}` ;
    return checkDate <= today;
  }

  my.checkMinAge = function () {
    const minAge = ((my.config || {}).age || {}).minimalAge || 0;
    if (minAge <= 0) return true;
    if (!my.data.person.birthDate) return true;
    const now = new Date();
    const today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
    const bDate = new Date(my.data.person.birthDate + 'T00:00:00Z');
    const age = new Date((today - bDate)).getFullYear() - 1970;

    return age < 0 || age >= minAge; //   age<0 проверит checkCurrDate
  }

  my.checkINN12 = () =>
    my?.readonly ||
    !my?.data?.person?.inn ||
    my?.data?.person?.inn?.length == 12

  my.validateSharedPercent = function () {
    return my.readonly ||
      !((my.config || {}).sharePercent || {}).show ||
      !my.data.sharePercent ||
      (my.data.sharePercent >= 0 && my.data.sharePercent <= 100);
  };

  my.checkLen = (string, length) => string
    ? string?.length <= length
    : true

  my.state = {
    selectedCompany: null,
    founderType: null
  };

  my.readonly = $modalParams.readonly;
  my.personsRef = $modalParams.personsRef;
  my.ref = $modalParams.ref;
  my.config = $modalParams.config;
  my.data = !$modalParams.item ? null : angular.copy($modalParams.item);
  if (my.data) initData();
}];
