export const isEquals = (obj1, obj2) => !!obj1 && !!obj2 && angular.equals(obj1, obj2)

export const plural = (number, one, two, five) => {
        var result = "";
        if ((number - number % 10) % 100 != 10) {
            if (number % 10 == 1) {
                result = one;
            } else if (number % 10 >= 2 && number % 10 <= 4) {
                result = two;
            } else {
                result = five;
            }
        } else {
            result = five;
        }
        return result;
    };