import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

const ctrl = ['$scope', '$element', 'fzModal', function ($scope, $element, fzModal) {
    const my = this;

    my.state = {};

    // HACK to get parent Form`s scope
    const $form = $element.closest('form');
    angular.element($form).scope().$watch('camForm', function (taskCamForm) {
        if (!taskCamForm) return;
        my.state.taskId = taskCamForm.taskId;
    });

    function getDraftVarName(doc) {
        return doc.entityId ? `${doc.docType}@${doc.entityId}` : doc.docType;
    };

    function isDocInvalid(doc) {
        const draftVarName = getDraftVarName(doc);
        return doc.canUpload &&
            doc.isRequired &&
            (!doc.files || !doc.files.filter(f => !f.toBeDeleted).length) &&
            (!$scope.drafts || !$scope.drafts[draftVarName] || !$scope.drafts[draftVarName].length);
    };

    my.getInvalidDocs = function () {
        if (!$scope.docs) return [];
        return Object.values($scope.docs).filter(x => isDocInvalid(x));
    };

    my.onButtonClickHandler = function () {
        fzModal.open({
            windowClass: 'fz-c-docs__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                taskId: my.state.taskId,
                docs: $scope.docs || {},
                drafts: $scope.drafts || {},
            }
        });
    };
}];

export default ctrl;