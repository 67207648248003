import angular from 'angular';
import cDocsEditTab from './fzlCDocsInitiatorEditTab';
import cDocsViewTab from './fzlCDocsInitiatorViewTab';

const moduleName = 'com.farzoom.lime.cDocs.initiator';
angular.module(moduleName, [
    'com.farzoom.common.fzFiles',
    'com.farzoom.common.fzModal',
    'com.farzoom.common.fzTaskFormParts'
])
    .directive('fzlCDocsInitiatorEditTab', cDocsEditTab)
    .directive('fzlCDocsInitiatorViewTab', cDocsViewTab)

export default moduleName;