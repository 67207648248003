import angular from 'angular';
import fzlCRecalculation from './fzlCRecalculation';

const moduleName = 'com.farzoom.common.cRecalculation';

angular.module(moduleName, [
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzToMoney'
])
    .directive('fzlCRecalculation', fzlCRecalculation)

export default moduleName;