let i18next = null;
try {
    i18next = require('i18next');
} catch (e) {
    /* i18next is optional dependency */
}

const namespace = 'com.farzoom.common.cAddresses';

const defaults = {
    "legalAddress": "Юридический адрес",
    "legalAddressShort": "Ю",
    "actualAddress": "Фактический адрес",
    "actualAddressShort": "Ф",
    "postalAddress": "Почтовый адрес",
    "postalAddressShort": "П",
    "equalsLegalAddress": "Совпадает с Юридическим адресом",
    "property": "Собственность",
    "rent": "Аренда",
    "details": "Детали",
    "edit": "Изменить",
    "add": "Добавить",
    "noData": "Нет сведений",
    "emptyValue": "-нет данных-",
    "addressOwnType": "Аренда или собственность",
    "invalid": "Проверьте правильность заполнения:",
    "closeCancel": "Отмена",
    "closeOk": "OK",
    "emptyOption": "Выберите из списка",
    "addressType": "Тип Адреса",
    "addressValue": "Адрес",
    "defaultHeaderDetail": "Адрес",
    "defaultHeaderList": "Адреса"
};

export default function i18n(key) {
    if (i18next && i18next.exists(`${namespace}:${key}`)) {
        return i18next.t(`${namespace}:${key}`);
    } else {
        return defaults[key] || key;
    }
}
