const ctrl = ['$scope', function ($scope) {
    const my = this;

    my.isInvalid = function () {
            return $scope.model.сontractsCountForBeneficiaries === null ||
            $scope.model.сontractsCountForBeneficiaries < 0 ||
            $scope.model.сontractsOkpd === null ||
            $scope.model.сontractsOkpd < 0 ||
            $scope.model.allExecContracts === null ||
            $scope.model.allExecContracts < 0 ||
            $scope.model.maxContractSumToCk === null ||
            $scope.model.maxContractSumToCk < 0 ||
            $scope.model.execContractsAmountToCk === null ||
            $scope.model.execContractsAmountToCk < 0 ||
            $scope.model.terminationCustomerToContracts === null ||
            $scope.model.terminationCustomerToContracts < 0 ||
            $scope.model.courtCasesToRevenue === null ||
            $scope.model.courtCasesToRevenue < 0
    };
}];

export default ctrl;