import modalTmpl from './modal/template.html';
import modalCtrl from './modal/controller';

const ctrl = ['$scope', 'fzModal', 'fzSvcAttribute', function ($scope, fzModal, fzSvcAttribute) {
  const my = this;

  my.state = {
    itemsInvalid: []
  }

  my.onButtonAddClickHandler = function () {
    fzModal.open({
      windowClass: 'fz-c-employee__modal',
      template: modalTmpl,
      controller: modalCtrl,
      controllerAs: 'my',
      params: {
        data: angular.copy($scope.model.itemTemplate),
        config: $scope.model.config,
        readonly: false,
        ref: $scope.model.ref || {},
        personsRef: $scope.ref,
        group: $scope.group || '',
        needShare: $scope.needShare || 'yes'
      }
    }).closed.then(result => {
      if (!result) return;
      if (!$scope.model.data) $scope.model.data = [];
      $scope.model.data.push(result);
    });
  };

  my.onButtonEditClickHandler = function (item) {


    fzModal.open({
      windowClass: 'fz-c-employee__modal',
      template: modalTmpl,
      controller: modalCtrl,
      controllerAs: 'my',
      params: {
        data: item,
        config: $scope.model.config,
        readonly: false,
        ref: $scope.model.ref || {},
        personsRef: $scope.ref,
        group: $scope.group || '',
        needShare: $scope.needShare || 'yes'
      }
    }).closed.then(result => {
      if (!result) return;

      const index = $scope.model.data.indexOf(item);
      if (index > -1) $scope.model.data[index] = result;
    });
  };

  my.onButtonDeleteClickHandler = function (item) {
    const index = $scope.model.data.indexOf(item);
    $scope.model.data.splice(index, 1);
  };

  my.validate = function () {
    const x = $scope.model;
    const required = $scope.required;
    const checkBg= x.itemTemplate.personAttributes.header
    const itemFind = "БГ."
    if (!x || !x.data || !x.data.length) {
      return checkBg.indexOf(itemFind) == -1
    }

    my.state.itemsInvalid = x.data.map(d => !validatePerson(d));
    const validShares = my.validateShares(x.data);
    return !my.state.itemsInvalid.some(x => x) && validShares;
  };

  my.isOtherIdentityDocumentType = (item) => {
    return item?.docType === 'PersDoc_Non-residentIdentityCard_2'
  }

  const getValue = (
    items,
    attributeId,
    key = 'keyValue'
  ) => items.find(item => item.attributeId === attributeId)?.value[key] || null

  // гражданство
  my.getPersonCitizenship = (item) => { 
    const items = item?.personAttributes?.items || item?.person?.personAttributes?.items || []
    return getValue(items, 'person.citizenship')
  }

  // ДУЛ, если выбрано "гражданство РФ"
  my.getPersonIdentityDocRf = (item) => {
    const items = item?.personAttributes?.items || item?.person?.personAttributes?.items || []
    return getValue(items, 'person.identityDocRf')
  }

  function validatePerson(d) {
    if (!d) return false;
    const cfg = $scope.model.config || {};
    const required = field => !!cfg[field] && !!cfg[field].required;
    const show = field => !!cfg[field] && !!cfg[field].show;

    const validOtherDocuments = (item) => {
      // иное гражданство
      const otherArray = [
        'PersDoc_Non-residentIdentityCard_2', // Иной документ, подтверждающего личность ИГ и ЛБГ и право на проживание
        'PersDoc_StatelessPersonTemporaryResidencePermi', // Разреш. на врем. прожив. лица без гр. на т. РФ
        'PersDoc_RussiaResidencePermit', // вид на жительство в РФ
      ]
  
      // без гражданства
      const isNoArray = [
        'PersDoc_Non-residentIdentityCard_2', // Иной документ, подтверждающего личность ИГ и ЛБГ и право на проживание
        'PersDoc_StatelessPersonTemporaryResidencePermi', // Разреш. на врем. прожив. лица без гр. на т. РФ
      ]
  
      const requiredOtherDocsForOther = ($scope.model.ref?.docType || []).filter(({ code }) => otherArray.indexOf(code) > -1)
      const requiredOtherDocsForIsNo = ($scope.model.ref?.docType || []).filter(({ code }) => isNoArray.indexOf(code) > -1)
  
      // иное гражданство
      const isOther = () => my.getPersonCitizenship(item) === 'other'
      
      // без гражданства
      const isNoCitizenship = () => my.getPersonCitizenship(item) === 'no-citizenship'
  
      const getValue = (items, attributeId, key = 'keyValue') => {
        return items.find(item => item.attributeId === attributeId)?.value[key] || null
      }
  
      // ДУЛ, если выбрано "иное гражданство"
      const getPersonIdentityDocOther = () => {
        const items = item?.personAttributes?.items || []
        return getValue(items, 'person.identityDocOther')
      }
  
      // ДУЛ, если выбрано "без гражданства"
      const getPersonIdentityDocNo = () => {
        const items = item?.personAttributes?.items || []
        return getValue(items, 'person.identityDocNo')
      }
    
      const personIdentityDocOther = getPersonIdentityDocOther()
      const personIdentityDocNo = getPersonIdentityDocNo()
    
      const mainIdentityDocuments = {
        // иное гражданство
        isOther: [
        'PersDoc_ForeignCitizenPassport', // Паспорт иностранного гражданина
        ],
    
        // без гражданства
        isNoCitizenship: [
        'PersDoc_StatelessIdentityCard', // Удостоверение лица без гражданства
        'PersDoc_RefugeeConsiderationPetitionCertificate', // свидетельство о рассмотрении ходатайства о признании беженцем на территории РФ по существу
        'PersDoc_RefugeeIdentityCard', // удостоверение беженца РФ
        'PersDoc_Pre-CitizenshipTempDoc', // Временный документ до признания лица гражданином РФ или о приеме в гражданство РФ
        ],
      }

      // Название прочего документа
      const getPersonOtherDocName = () => { 
        const items = item?.personAttributes?.items || item?.person?.personAttributes?.items || []
        return getValue(items, 'person.otherDocName', 'stringValue')
      }

      const otherDocuments = item?.person?.otherDocuments || item?.otherDocuments || []

      const hasOtherDocument = !!otherDocuments.find(({ docType }) => docType === 'PersDoc_Non-residentIdentityCard_2')

      if (hasOtherDocument && !getPersonOtherDocName()) {
        return false
      }

      if (!my.getPersonCitizenship(item)) {
        return false
      }
      
      // иное гражданство + паспорт иностранного гражданина
      if (isOther() && personIdentityDocOther && mainIdentityDocuments.isOther.indexOf(personIdentityDocOther) > -1) {
        const findItem = otherDocuments.find(({ docType }) =>
          requiredOtherDocsForOther.filter(({ code }) => docType && docType === code).length > 0
        )
        return my.isOtherIdentityDocumentType(findItem)
          ? !!(findItem && (findItem.docName || getPersonOtherDocName()))
          : !!findItem
      }
      
      // без гражданства + (
      //   Удостоверение лица без гражданства ИЛИ
      //   свидетельство о рассмотрении ходатайства о признании беженцем на территории РФ по существу ИЛИ
      //   удостоверение беженца РФ ИЛИ
      //   Временный документ до признания лица гражданином РФ или о приеме в гражданство РФ
      // )
      if (isNoCitizenship() && personIdentityDocNo && mainIdentityDocuments.isNoCitizenship.indexOf(personIdentityDocNo) > -1) {
        const findItem = otherDocuments.find(({ docType }) =>
          requiredOtherDocsForIsNo.filter(({ code }) => docType && docType === code).length > 0
        )
        return my.isOtherIdentityDocumentType(findItem)
          ? !!(findItem && (findItem.docName || getPersonOtherDocName()))
          : !!findItem
      }
    
      return true
    }

    const validIssuedDate = (p) => {
      if (!cfg.identityDocument.issueDateHide && !p.issuedDate) {
        return false
      }
      if (my.getPersonCitizenship(p) !== 'rf' || my.getPersonIdentityDocRf(p) !== 'PersDoc_Passport') {
        return true
      }

      const idDocCfg = cfg.identityDocument || {};
      if (!!idDocCfg.issueDateHide) {
        return true
      }

      const getAge = (date) => {
        const today = new Date()
        const birthDate = new Date(date)
        let age = today.getFullYear() - birthDate.getFullYear()
        const m = today.getMonth() - birthDate.getMonth()
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--
        }
        return age
      }
      const getTimeStamp = (date) => new Date(date).getTime()
      const resetTime = (date) => {
        let result = new Date(date)
        result.setHours(0)
        result.setMinutes(0)
        result.setSeconds(0)
        result.setMilliseconds(0)
        return result
      }
      const getDiffDays = (date1, date2) => date1 && date2
        ? (new Date(date1).getTime() - new Date(date2).getTime()) / (1000 * 3600 * 24)
        : 0
      const birthDate = resetTime(new Date(p?.birthDate || p?.person?.birthDate))
      const issuedDate = resetTime(new Date(p?.issuedDate || p?.person?.issuedDate))
      const birthFullYear = new Date(birthDate).getFullYear()
      const age = getAge(birthDate)
    
      const getBirthDateByGivenAge = (givenAge) => new Date(new Date(birthDate).setFullYear(birthFullYear + givenAge))
      const birthDate14 = getBirthDateByGivenAge(14)
      const birthDate20 = getBirthDateByGivenAge(20)
      const birthDate45 = getBirthDateByGivenAge(45)
    
      const getDaysAfterGivenAge = (givenAge) => getDiffDays(new Date(), getBirthDateByGivenAge(givenAge))
      const daysAfter20 = getDaysAfterGivenAge(20)
      const daysAfter45 = getDaysAfterGivenAge(45)
    
      const issuedDateTS = getTimeStamp(issuedDate)
      const birthDateTS14 = getTimeStamp(birthDate14)
      const birthDateTS20 = getTimeStamp(birthDate20)
      const birthDateTS45 = getTimeStamp(birthDate45)
    
      if (age >= 14 && age < 20) { return issuedDateTS >= birthDateTS14 }
      if (age === 20 && daysAfter20 <= 90) { return issuedDateTS >= birthDateTS14 }
      if (age === 20 && daysAfter20 > 90) { return issuedDateTS >= birthDateTS20 }
      if (age > 20 && age < 45) { return issuedDateTS >= birthDateTS20 }
      if (age === 45 && daysAfter45 <= 90) { return issuedDateTS >= birthDateTS20 }
      if (age === 45 && daysAfter45 > 90) { return issuedDateTS >= birthDateTS45 }
      if (age > 45) { return issuedDateTS >= birthDateTS45 }
    
      return false
    }

    const validIdentityDocument = (p) => {
      if (!required('identityDocument')) {
        return true;
      }

      if (!p.number) {
        return false;
      }

      const idDocCfg = cfg.identityDocument || {}

      // гражданство РФ
      const isRF = my.getPersonCitizenship(p) === 'rf'

      const isRFAndPassportRF = isRF && my.getPersonIdentityDocRf(p) === 'PersDoc_Passport'

      const isValidIssuedDate = !!idDocCfg.issueDateHide || !!p.issuedDate
      
      const isValidIssuingAuthorityCode = isRFAndPassportRF
        ? !!idDocCfg.issuingAuthorityCodeHide || !!p.issuingAuthorityCode
        : true

      const isValidIssuingAuthority = !!idDocCfg.issuingAuthorityHide || !!p.issuingAuthority

      const flags = isValidIssuedDate && isValidIssuingAuthorityCode && isValidIssuingAuthority

      return isRFAndPassportRF
        ? flags && !!p.series
        : flags
    }

    return !!d.lastName && !!d.firstName && !!d.secondName &&
      (!required('inn') || !!d.inn) &&
      (!required('birthDate') || !!d.birthDate) &&
      (!required('birthPlace') || !!d.birthPlace) &&
      (!required('registrationAddress') || !!d.registrationAddress) &&
      (!required('actualAddress') || !!d.actualAddress) &&
      (!required('phone') || (d.contacts || []).some(x => !!x.phone)) &&
      (!required('email') || (d.contacts || []).some(x => !!x.email)) &&
      (!required('position') || !!d.position) &&
      (!required('authorizingDoc') || !!d.authorizingDoc) &&
      (!required('citizenship') || !!d.citizenship) &&
      (!required('otherCitizenship') || d.citizenship !== 'other' || !!d.otherCitizenship) &&
      validIdentityDocument(d) &&
      (!required('sharePercent') || !!d.sharePercent || d.sharePercent === 0) &&
      (!show('sharePercent') || !d.sharePercent || (d.sharePercent >= 0 && d.sharePercent <= 100)) &&
      (!required('shareSum') || !!d.shareSum || d.shareSum === 0) &&
      (!show('inn') || !d.inn || d.inn.length == 12) &&
      (!d.issuedDate || checkCurrDate(d.issuedDate)) &&
      (!d.birthDate || (checkCurrDate(d.birthDate) && checkMinAge(d.birthDate, d.personAttributes))) &&
      fzSvcAttribute.validateGroup(d.personAttributes) &&
      fzSvcAttribute.validateGroup(d.relationAttributes) &&
      fzSvcAttribute.validateGroup(d.relationTopAttributes) &&
      (!show('otherDocuments') || validOtherDocuments(d)) &&
      validIssuedDate(d);
  };

  my.validateShares = function (persons) {

    if ($scope.needShare === 'no')
        return true;
    const cfg = $scope.model.config || {};
    const show = field => !!cfg[field] && !!cfg[field].show;
    if (!show('sharePercent')) return true;
    if (!persons || !persons.length) return true;
    const totalShares = persons
      .map(p => p.sharePercent)
      .reduce((sum, share) => (sum || 0) + (share || 0));
    return (totalShares >= 0 && totalShares <= 100.00);
  }

  my.isInvalid = function (x) {
    const index = $scope.model.data.indexOf(x);
    return my.state.itemsInvalid[index];
  }

  function checkMinAge(birthDate, personAttributes) {
    const items = personAttributes?.items || $scope?.model?.data[0]?.personAttributes?.items || []
    const isRF = items.find(item => item.attributeId === 'person.citizenship')?.value?.keyValue === 'rf'
    if (isRF) return true;
    let minAge = (($scope.model.config || {}).age || {}).minimalAge || 0;
    if (minAge <= 0) return true;
    const now = new Date();
    const today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
    const bDate = new Date(birthDate + 'T00:00:00Z');
    const age = new Date((today - bDate)).getFullYear() - 1970;

    return age >= minAge;
  }

  function checkCurrDate(checkDate) {
    const now = new Date();
    let y = now.getFullYear();
    let m = now.getMonth() + 1;
    let d = now.getDate();
    let today = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}` ;
    return checkDate <= today;
  }
 
  function updateRef(result) {
    if (!$scope.ref) $scope.ref = []; //return;
    if (!result.lastName || !result.firstName || !result.secondName || !result.number) return;

    const newRefItem = {
      displayName: `${result.lastName} ${result.firstName} ${result.secondName} (паспорт ${result.series} ${result.number})`,
      key: `${result.lastName}#${result.firstName}#${result.secondName}#${result.number}`,
      data: JSON.parse(JSON.stringify(result))
    }

    const refItem = $scope.ref.find(x => x.key === newRefItem.key);
    if (!refItem) {
      $scope.ref.push(newRefItem);
    } else {
      for (let key of Object.keys(newRefItem.data)) {
        if (key !== 'personAttributes' && key !== 'relationAttributes' && key !== 'relationTopAttributes') {
          refItem.data[key] = newRefItem.data[key];
        }
      }
      mergeAttributes('personAttributes', refItem.data, newRefItem.data);
      mergeAttributes('relationAttributes', refItem.data, newRefItem.data);
      mergeAttributes('relationTopAttributes', refItem.data, newRefItem.data);
    }
  };

  function mergeAttributes(name, destData, srcData) {
    if (srcData[name] && srcData[name].items && srcData[name].items.length) {
      if (!destData[name]) destData[name] = {};
      if (!destData[name].items) destData[name].items = [];

      const destMap = new Map();
      destData[name].items.forEach(attr => destMap.set(attr.attributeId, attr));
      srcData[name].items.forEach(attr => {
        if (destMap.has(attr.attributeId)) {
          const destAttr = destMap.get(attr.attributeId);
          destAttr.value = attr.value;
          destAttr.values = attr.values;
        } else {
          destData[name].items.push(attr);
        }
      });
    }
  };

  $scope.$watchCollection('model.data', function (newValues, oldValues) {
    if (!newValues || !newValues.length) return;
    newValues.forEach(x => updateRef(x));
  });
}];

export default ctrl;