import angular from 'angular';
import component from './fzlCManagement';

const moduleName = 'com.farzoom.lime.fzlCManagement';

angular.module(moduleName, [
        'com.farzoom.common.fzAddress',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzlCManagement', component)

export default moduleName;
