import angular from 'angular';
import cAttribute from './cAttribute';
import cAttributeGroup from './cAttributeGroup';
import cAttributes from './cAttributes';
import fzSvcAttribute from './fzSvcAttribute';
import fzCAttributeBank from './cAttributeBank';
import cAttributeGroupDetails from './cAttributeGroupDetails';
import dPatternValidation from './dPatternValidator';
import cAttributesEditTab from './cAttributesEditTab';
import cAttributesViewTab from './cAttributesViewTab';
import cAccountEditTab from './cAccountEditTab';

const moduleName = 'com.farzoom.lime.cAttributes';

angular.module(moduleName, [
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzSelectBank',
        'com.farzoom.common.fzAddress',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts',
        'ui.mask'
    ])
    .directive('fzpCAttribute', cAttribute)
    .directive('fzpCAttributeBank', fzCAttributeBank)
    .directive('fzpCAttributes', cAttributes)
    .directive('fzpCAttributeGroupDetails', cAttributeGroupDetails)
    .directive('fzlCAttributeGroup', cAttributeGroup)
    .directive('fzlCAttributesEditTab', cAttributesEditTab)
    .directive('fzlCAccountEditTab', cAccountEditTab)
    .directive('fzlCAttributesViewTab', cAttributesViewTab)
    .directive('fzpPattern', dPatternValidation)
    .service('fzpSvcAttribute', fzSvcAttribute)
    
export default moduleName;
