const ctrl = ['$scope', function ($scope) {
    const my = this;
    my.state = {
        isDataConfirmed: false
    };

    my.confirmData = function () {
        my.state.isDataConfirmed = true;
    }

    my.calcExecContracts = function () {
        $scope.model.execContracts =    $scope.model.execContractsMt +
                                        $scope.model.execContractsCommerce;
    }

    my.calcExecContractsAmount = function () {
        $scope.model.execContractsAmount =  $scope.model.contractsSumNmckMt +
                                            $scope.model.execContractsAmountCommerce;
    }

    my.calcContractsCountForBeneficiaries = function () {
        $scope.model.contractsCountForBeneficiaries =   $scope.model.contractsCountForBeneficiariesMt +
                                                        $scope.model.contractsCountForBeneficiariesCommerce;
    }

    my.calcContractsCountForBeneficiariesSum = function () {
        $scope.model.contractsCountForBeneficiariesSum =   $scope.model.contractsSumForBeneficiariesMt +
                                                        $scope.model.contractsCountForBeneficiariesAmountCommerce;
    }

    my.calcContractsOkpd = function () {
        $scope.model.contractsOkpd =   $scope.model.contractsOkpdMt +
                                       $scope.model.contractsOkpdCommerce;
    }

    my.calcContractsOkpdSum = function () {
        $scope.model.contractsOkpdSum =   $scope.model.gosContractsOkpdAmount +
                                            $scope.model.contractsOkpdAmountCommerce;
    }

    my.calcMaxContractSum = function () {
        $scope.model.maxContractSum =   $scope.model.maxContractSumNmckMt +
                                        $scope.model.execContractsAmountMaxCommerce;
    }

    my.calcPotentialExecContracts = function () {
        $scope.model.potentialExecContracts =   $scope.model.potentialExecContractsMt +
                                                $scope.model.potentialExecContractsCommerce;
    }

    my.calcExecContractsAmountPotential = function () {
        $scope.model.execContractsAmountPotential =   $scope.model.contractsSumNmckPotentialMt +
                                                       $scope.model.execContractsAmountPotentialCommerce;
    }

    my.calcContractsCountForBeneficiariesPotential = function () {
        $scope.model.contractsCountForBeneficiariesPotential =   $scope.model.contractsCountForBeneficiariesPotentialMt +
                                                        $scope.model.contractsCountForBeneficiariesPotentialCommerce;
    }

    my.calcContractsCountForBeneficiariesPotentialSum = function () {
        $scope.model.contractsCountForBeneficiariesPotentialSum =   $scope.model.contractsSumForBeneficiariesPotentialMt +
                                                        $scope.model.contractsCountForBeneficiariesPotentialAmountCommerce;
    }

    my.calcContractsOkpdPotentialSum = function () {
        $scope.model.contractsOkpdPotential =   $scope.model.contractsOkpdPotentialMt +
                                                        $scope.model.contractsOkpdPotentialCommerce;
    }

    my.calcContractsOkpdPotentialAmountSum = function () {
        $scope.model.contractsOkpdPotentialSum =   $scope.model.gosContractsOkpdPotentialAmount +
                                                        $scope.model.contractsOkpdPotentialAmountCommerce;
    }

    my.calcMaxContractPotentialSum = function () {
        $scope.model.maxContractPotentialSum =   $scope.model.maxContractSumNmckPotentialMt +
                                                        $scope.model.execContractsAmountPotentialMaxCommerce;
    }

    my.calcContractsToExec18Sum = function () {
        $scope.model.contractsToExec18Sum =  $scope.model.contractsToExec18Mt + $scope.model.contractsToExec18Commerce;
    }

    my.calcActivContractsSum = function () {
        $scope.model.activContractsSum =   $scope.model.activContractsMt + $scope.model.activContractsCommerce;
    }

    my.calcUpcomingPaymentsSum12 = function () {
        $scope.model.upcomingPaymentsSum12 =   $scope.model.upcomingPayments12 + $scope.model.upcomingPayments12Commerce;
    }

    my.calcUpcomingPaymentsSum = function () {
        $scope.model.upcomingPaymentsSum =   $scope.model.upcomingPayments + $scope.model.upcomingPaymentsCommerce;
    }

    my.calcActualPaymentsSum = function () {
        $scope.model.actualPaymentsSum =   $scope.model.actualPayments + $scope.model.actualPaymentsCommerce;
    }

    my.calcUpcomingPayments36Sum = function () {
        $scope.model.upcomingPayments36Sum =   $scope.model.upcomingPayments36 + $scope.model.upcomingPayments36Commerce;
    }

    my.calcCourtCasesCountSum = function () {
        $scope.model.courtCasesCountSum =   $scope.model.courtCasesCount + $scope.model.courtCasesCountCommerce;
    }

    my.calcCourtCasesClosedSum = function () {
        $scope.model.courtCasesClosedSum =   $scope.model.courtCasesClosed + $scope.model.courtCasesClosedCommerce;
    }

    

    my.isInvalid = function () {
        return $scope.model.execContractsMt === null ||
            $scope.model.execContractsCommerce === null ||
            $scope.model.execContracts === null ||

            $scope.model.contractsSumNmckMt === null ||
            $scope.model.execContractsAmountCommerce === null ||
            $scope.model.execContractsAmount === null ||

            $scope.model.contractsCountForBeneficiariesMt === null ||
            $scope.model.contractsCountForBeneficiariesCommerce === null ||
            $scope.model.contractsCountForBeneficiaries === null ||

            $scope.model.contractsSumForBeneficiariesMt === null ||
            $scope.model.contractsCountForBeneficiariesAmountCommerce=== null ||
            $scope.model.contractsCountForBeneficiariesSum === null ||

            $scope.model.contractsOkpdMt === null ||
            $scope.model.contractsOkpdCommerce === null ||
            $scope.model.contractsOkpd === null ||

            $scope.model.gosContractsOkpdAmount === null ||
            $scope.model.contractsOkpdAmountCommerce === null ||
            $scope.model.contractsOkpdSum === null ||

            $scope.model.maxContractSumNmckMt === null ||
            $scope.model.execContractsAmountMaxCommerce === null ||
            $scope.model.maxContractSum === null ||

            $scope.model.potentialExecContractsMt === null ||
            $scope.model.potentialExecContractsCommerce === null ||
            $scope.model.potentialExecContracts === null ||

            $scope.model.contractsSumNmckPotentialMt === null ||
            $scope.model.execContractsAmountPotentialCommerce === null ||
            $scope.model.execContractsAmountPotential === null ||

            $scope.model.contractsCountForBeneficiariesPotentialMt === null ||
            $scope.model.contractsCountForBeneficiariesPotentialCommerce === null ||
            $scope.model.contractsCountForBeneficiariesPotential === null ||

            $scope.model.contractsSumForBeneficiariesPotentialMt === null ||
            $scope.model.contractsCountForBeneficiariesPotentialAmountCommerce === null ||
            $scope.model.contractsCountForBeneficiariesPotentialSum === null ||

            $scope.model.contractsOkpdPotentialMt === null ||
            $scope.model.contractsOkpdPotentialCommerce === null ||
            $scope.model.contractsOkpdPotential === null ||

            $scope.model.gosContractsOkpdPotentialAmount === null ||
            $scope.model.contractsOkpdPotentialAmountCommerce === null ||
            $scope.model.contractsOkpdPotentialSum === null ||

            $scope.model.maxContractSumNmckPotentialMt === null ||
            $scope.model.execContractsAmountPotentialMaxCommerce === null ||
            $scope.model.maxContractPotentialSum === null ||

            $scope.model.contractsToExec18Mt === null ||
            $scope.model.contractsToExec18Commerce === null ||
            $scope.model.contractsToExec18Sum === null ||

            $scope.model.activContractsMt === null ||
            $scope.model.activContractsCommerce === null ||
            $scope.model.activContractsSum === null ||

            $scope.model.actualPayments === null ||
            $scope.model.actualPaymentsCommerce === null ||
            $scope.model.actualPaymentsSum === null ||

            $scope.model.upcomingPayments === null ||
            $scope.model.upcomingPaymentsCommerce === null ||
            $scope.model.upcomingPaymentsSum === null ||

            $scope.model.upcomingPayments12 === null ||
            $scope.model.upcomingPayments12Commerce === null ||
            $scope.model.upcomingPaymentsSum12 === null ||

            $scope.model.upcomingPayments36 === null ||
            $scope.model.upcomingPayments36Commerce === null ||
            $scope.model.upcomingPayments36Sum === null ||

            $scope.model.courtCasesCount === null ||
            $scope.model.courtCasesCountCommerce === null ||
            $scope.model.courtCasesCountSum === null ||

            $scope.model.courtCaseTotalSum === null ||

            $scope.model.courtCasesClosed === null ||
            $scope.model.courtCasesClosedCommerce === null ||
            $scope.model.courtCasesClosedSum === null ||
            !my.state.isDataConfirmed;
    };

    async function waitForModel() {
        while (typeof $scope.model === 'undefined') {
            await new Promise(resolve => setTimeout(resolve, 300));
            console.log('Ждём модель');
        }
        console.log('Модель подгружена!');
        my.calcExecContracts();
        my.calcExecContractsAmount();
        my.calcContractsCountForBeneficiaries();
        my.calcContractsCountForBeneficiariesSum();
        my.calcContractsOkpd();
        my.calcContractsOkpdSum();
        my.calcMaxContractSum();
        my.calcPotentialExecContracts();
        my.calcExecContractsAmountPotential();
        my.calcContractsCountForBeneficiariesPotential();
        my.calcContractsCountForBeneficiariesPotentialSum();
        my.calcContractsOkpdPotentialSum();
        my.calcContractsOkpdPotentialAmountSum();
        my.calcMaxContractPotentialSum();
        my.calcContractsToExec18Sum();
        my.calcActivContractsSum();
        my.calcUpcomingPaymentsSum12();
        my.calcUpcomingPaymentsSum();
        my.calcActualPaymentsSum();
        my.calcUpcomingPayments36Sum();
        my.calcCourtCasesCountSum();
        my.calcCourtCasesClosedSum();
    }

    waitForModel();
}];

export default ctrl;