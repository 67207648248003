import modalTmpl from '../fzlCKopManagersEditTab/modal/template.html';
import modalCtrl from '../fzlCKopManagersEditTab/modal/controller';

const ctrl = ['$scope', 'fzModal', function ($scope, fzModal) {
    const my = this;

    my.onButtonClickHandler = function (item) {
        fzModal.open({
            windowClass: 'fz-c-employee__modal',
            template: modalTmpl,
            controller: modalCtrl,
            controllerAs: 'my',
            params: {
                data: item,
                itemTemplate: angular.copy($scope.model.itemTemplate),
                ref: $scope.model.ref,
                config: $scope.model.config,
                readonly: true
            }
        });
    };
}];

export default ctrl;