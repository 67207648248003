const ctrl = ['$scope', function ($scope) {
  const my = this

  my.onChangeField = () => {
    const { comment } = $scope?.model?.refs?.find(item =>
      item?.value === $scope?.model?.rejectReasonParam?.value?.keyValue
    ) || {}
    $scope.model.needShowError = false
    const commentValue = comment ? `Причина отказа: ${comment}` : ''
    $scope.model.comment = commentValue
  }

}]

export default ctrl