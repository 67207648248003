import angular from 'angular';
import component from './fzlCProductBg';

const moduleName = 'com.farzoom.lime.fzlCProductBg';

angular.module(moduleName, [
        'com.farzoom.common.fzAddress',
        'com.farzoom.common.fzModal',
        'com.farzoom.common.fzToDate',
        'com.farzoom.common.fzToMoney',
        'com.farzoom.common.fzToNumber',
        'com.farzoom.common.fzTaskFormParts'
    ])
    .directive('fzlCProductBg', component)

export default moduleName;
