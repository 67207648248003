import tmpl from './template.html';
import ctrl from './controller';
import './styles.css';

const cmp = function () {
  return {
    restrict: 'E',
    scope: {
      model: '=',
      checkValid: '=?',
      required: '=?',
      header: '@',
      ref: '=?',
      group: '@',
      needShare: '@'
    },
    template: tmpl,
    controller: ctrl,
    controllerAs: 'my'
  };
};

export default cmp;