import {
  validate,
  getCode,
  ATTRIBUTE_FIELDS,
  // INITIAL_VALUES,
  // PRODUCTS,
} from '../config'
import './styles.css'

export default ['$scope', '$modalInstanse', '$modalParams', function ($scope, $modalInstanse, $modalParams) {
  const my = this

  my.header = $modalParams.header
  my.model = angular.copy($modalParams.model)
  my.code = $modalParams?.code
  my.personsRef = $modalParams.personsRef
  my.readonly = $modalParams.readonly

  my.state = {
    product: my.code,
  }

  my.editableIndex = my?.model?.data?.findIndex(item => getCode(item) === my?.code)

  my.item = my.editableIndex > -1 ? my.model.data[my.editableIndex][my.code] : {}

  my.PRODUCTS = my?.model?.config?.map(({ type, available}) => ({
    name: my.model.dboTemplate[type].header,
    type,
    available
  }))

  my.onChange = (name, value) => {
    if (name === 'provideLogin') {
      my.item.sendToEmail = !value
    }
    if (name === 'sendToEmail') {
      my.item.provideLogin = !value
    }
  }

  my.addContact = () => my.item.person.contacts.push({})
  my.removeContactByIndex = (index) => my.item.person.contacts.splice(index, 1)

  my.closeOk = () => my.code
    ? $modalInstanse.close(my.item)
    : $modalInstanse.close({ [my.state.product]: my.item })
    
  my.closeCancel = () => $modalInstanse.close()

  my.setDboProduct = (value) => {
    my.state.product = value
    my.header = my.PRODUCTS.find(item => item?.type === value).name
    my.item = {
      ...my?.model?.dboTemplate[value],
    }
    if (my?.item?.person?.contacts?.length === 0) {
      my.item.person.contacts.push({})
    }
  }

  my.isNotRequiredCodeword = () => {
    const productConfig =  my.model.config.find(({ type }) => type === my.state.product)
    return productConfig?.isNotRequiredCodeword
  }

   my.isNotRequiredBarcode = () => {
      const productConfig =  my.model.config.find(({ type }) => type === my.state.product)
      return productConfig?.isNotRequiredBarcode
    }

  my.isValidLength = (value, length) => validate.length(value, length)
  my.isValidCodeword = () => my?.item?.codeword ? validate.codeword(my?.item?.codeword) : my.isNotRequiredCodeword()
  my.isValidBarcode = () => my?.item?.barcode ? validate.barcode(my?.item?.barcode) : my.isNotRequiredBarcode()

  my.onSelect = (item) => {
    if (item) {
      if (my.item.person) {
        for (let key in my.item.person) {
          my.item.person[key] = Array.isArray(my.item.person[key])
            ? [{}]
            : ''
        }
      }
      my.item.phone = item?.data?.contacts?.length > 0
        ? item?.data?.contacts[0]?.phone
        : ''
      const refData = JSON.parse(JSON.stringify(item.data))
      for (let key of Object.keys(refData)) {
        if (ATTRIBUTE_FIELDS.indexOf(key) === -1) {
          if (Object.keys(my.item.person).includes(key)) {
            my.item.person[key] = refData[key]
          }
        }
      }
    }
  }

  my.isAvailableProduct = (item) => !my.model.data.find(product => product[item.type]) && item.available

  $scope.$watch('my.item.secondName', secondName => my.noSecondName = secondName === '-')

  const getValue = (
    items,
    attributeId,
    key = 'keyValue'
  ) => items.find(item => item.attributeId === attributeId)?.value[key] || null

  my.replaceAllWhiteSpaces = (string) => string?.trim()?.replaceAll(' ', '')
  
}]